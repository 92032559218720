import { Context } from 'index'
import { useContext, useMemo } from 'react'

const translateGibdd = {
  fio_fio: {
    text: 'ФИО',
    order: 1,
  },
  year_of_birth: {
    text: 'Дата рождения',
    order: 2,
  },
  model: {
    text: 'Модель авто',
    order: 3,
  },
  year: {
    text: 'Год',
    order: 4,
  },
  power: {
    text: 'Мощность авто',
    order: 5,
  },
  pts: {
    text: 'ПТС',
    order: 6,
  },
  sts: {
    text: 'СТС',
    order: 7,
  },
  vin: {
    text: 'VIN',
    order: 8,
  },
} as { [k: string]: { text: string; order: number } }

export const useGibddInfo = () => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { gibddData: DATA } = profileStore

  const gibddData = useMemo(
    () =>
      DATA
        ? Object.entries(DATA)
            .filter(([key, _]) => key !== 'fio' && key !== 'source')
            .sort(([keyA, _], [keyB, __]) =>
              translateGibdd?.[keyA]?.order < translateGibdd?.[keyB]?.order
                ? -1
                : 0
            )
        : null,
    [DATA]
  )

  return { gibddData, translateGibdd }
}
