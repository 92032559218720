import { Box, Typography, Stack } from '@mui/material'

import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Context } from 'index'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'

import fineIcon from 'assets/fineIcon.png'
import accidentIcon from 'assets/accident.svg'
import { useFinesData } from './useFinesData'

export const FinesAndAccidents = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { accidents } = profileStore

  const { fines, finesData, getFineDay } = useFinesData()

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return Object.keys(finesData)?.length || accidents?.length ? (
    <Box>
      {Object.entries(finesData).map(
        ([year, finesInYear]: any, yearIdx: number) => (
          <Accordion
            expanded={expanded === year}
            onChange={handleChange(year)}
            key={yearIdx}
          >
            <AccordionSummary>
              <Stack direction='row' alignItems='center'>
                <Stack
                  sx={{
                    bgcolor: 'common.white',
                    mr: 1,
                    borderRadius: '50%',
                    alignItems: 'center',
                    height: 24,
                    width: 24,
                    justifyContent: 'center',
                  }}
                >
                  <img src={fineIcon} alt='fineIcon' width={20} height={20} />{' '}
                </Stack>

                <Typography variant='body1' color='common.black'>
                  Штрафы {fines?.regno} в {year} г
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                justifyContent='space-between'
                sx={{
                  mt: 1,
                  width: '100%',
                  maxWidth: '900px',
                }}
              >
                {finesInYear?.length
                  ? finesInYear?.map((fine: any, fineIdx: number) => (
                      <Stack key={fineIdx}>
                        {fine?.vehicle_model_array?.length &&
                        fine?.vehicle_model_array.some((el: any) => !!el) &&
                        fineIdx === 0 ? (
                          <Stack
                            sx={{ mt: 1 }}
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                          >
                            <Box
                              textAlign='left'
                              maxWidth='50%'
                              fontSize={14}
                              lineHeight='24px'
                              color='common.black'
                            >
                              Модели авто:
                            </Box>
                            <Box
                              textAlign='right'
                              maxWidth='50%'
                              fontSize={14}
                              lineHeight='24px'
                              color='text.disabled'
                            >
                              {fine?.vehicle_model_array
                                .filter((el: any) => el !== 'nan')
                                .map(
                                  (
                                    model: string,
                                    modelIdx: number,
                                    arr: any
                                  ) => (
                                    <React.Fragment
                                      key={`${model}_${modelIdx}_${Math.random()}`}
                                    >
                                      {`${model}`}
                                      {modelIdx !== arr.length - 1 ? '; ' : ''}
                                    </React.Fragment>
                                  )
                                )}
                            </Box>
                          </Stack>
                        ) : null}

                        {fine?.violation_desc || fine?.violation_cnt ? (
                          <Stack
                            sx={{ mt: 1 }}
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                          >
                            {fine?.violation_desc === 'Суммарные данные' ? (
                              <>
                                <Box
                                  textAlign='left'
                                  maxWidth='50%'
                                  fontSize={14}
                                  lineHeight='24px'
                                  color='common.black'
                                  fontWeight={700}
                                >
                                  Всего нарушений:
                                </Box>
                                <Box
                                  textAlign='right'
                                  maxWidth='50%'
                                  color='common.black'
                                >
                                  {fine?.violation_cnt ? (
                                    <Typography
                                      variant='body1'
                                      fontWeight={700}
                                      component='span'
                                    >
                                      {fine?.violation_cnt}
                                    </Typography>
                                  ) : null}
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box
                                  textAlign='left'
                                  maxWidth='50%'
                                  color='color.black'
                                >
                                  {fine?.violation_desc ? (
                                    <Typography
                                      variant='body1'
                                      component='span'
                                    >
                                      {fine?.violation_desc}{' '}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant='body1'
                                      component='span'
                                    >
                                      {'Неизвестный тип нарушения'}
                                    </Typography>
                                  )}
                                </Box>

                                {fine?.violation_cnt ? (
                                  <Box
                                    textAlign='right'
                                    maxWidth='50%'
                                    fontSize={14}
                                    lineHeight='24px'
                                    color='text.disabled'
                                  >
                                    {fine?.violation_cnt}
                                  </Box>
                                ) : null}
                              </>
                            )}
                          </Stack>
                        ) : null}
                      </Stack>
                    ))
                  : null}
              </Stack>
            </AccordionDetails>
          </Accordion>
        )
      )}

      {accidents?.map((acc: any, accIdx: number) => (
        <Accordion
          key={accIdx}
          expanded={expanded === acc?.accident_id}
          onChange={handleChange(acc?.accident_id)}
        >
          <AccordionSummary>
            <Stack
              sx={{
                bgcolor: 'common.white',
                mr: 1,
                borderRadius: '50%',
                alignItems: 'center',
                height: 24,
                width: 24,
                justifyContent: 'center',
              }}
            >
              <img src={accidentIcon} alt='fineIcon' width={20} height={20} />{' '}
            </Stack>
            <Typography color='common.black' variant='body1'>
              ДТП {getFineDay(acc?.day)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              justifyContent='space-between'
              sx={{
                mt: 1,
                width: '100%',
                maxWidth: '900px',
              }}
            >
              {acc?.accident_type_nm ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    Тип ДТП:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {acc.accident_type_nm}
                  </Box>
                </Stack>
              ) : null}

              {acc?.accident_location ||
              acc?.accident_latitude ||
              acc?.accident_longitude ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    Локация:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {`${acc?.accident_location} ${
                      acc?.building_num ? `д.${acc.building_num}` : ''
                    }` ||
                      `${acc?.accident_latitude} - ${acc?.accident_longitude}`}
                  </Box>
                </Stack>
              ) : null}

              {acc?.damage_desc ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    Описание повреждений:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {acc?.damage_desc}
                  </Box>
                </Stack>
              ) : null}

              {acc?.damage_disposition_array?.length ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    Список повреждений:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {acc?.damage_disposition_array.map(
                      (dam: string, damIdx: number) => (
                        <Stack key={`${damIdx}_${Math.random()}`}>{dam};</Stack>
                      )
                    )}
                  </Box>
                </Stack>
              ) : null}

              {(acc?.died_cnt && acc?.died_cnt !== '0') ||
              (acc?.died_child_cnt && acc?.died_child_cnt !== '0') ? (
                <>
                  <Stack
                    sx={{ mt: 1 }}
                    direction='row'
                    width='100%'
                    justifyContent='space-between'
                  >
                    <Box
                      textAlign='left'
                      maxWidth='50%'
                      fontSize={14}
                      lineHeight='24px'
                      color='common.black'
                    >
                      Количество погибших:
                    </Box>
                    <Box
                      textAlign='right'
                      maxWidth='50%'
                      fontSize={14}
                      lineHeight='24px'
                      color='text.disabled'
                    >
                      {acc?.died_cnt}
                    </Box>
                  </Stack>
                  <Stack
                    sx={{ mt: 1 }}
                    direction='row'
                    width='100%'
                    justifyContent='space-between'
                  >
                    <Box
                      textAlign='left'
                      maxWidth='50%'
                      fontSize={14}
                      lineHeight='24px'
                      color='common.black'
                    >
                      Количество погибших детей:
                    </Box>
                    <Box
                      textAlign='right'
                      maxWidth='50%'
                      fontSize={14}
                      lineHeight='24px'
                      color='text.disabled'
                    >
                      {acc?.died_child_cnt}
                    </Box>
                  </Stack>
                </>
              ) : null}

              {(acc?.injured_cnt && acc?.injured_cnt !== '0') ||
              (acc?.injured_child_cnt && acc?.injured_child_cnt !== '0') ? (
                <>
                  <Stack
                    sx={{ mt: 1 }}
                    direction='row'
                    width='100%'
                    justifyContent='space-between'
                  >
                    <Box
                      textAlign='left'
                      maxWidth='50%'
                      fontSize={14}
                      lineHeight='24px'
                      color='common.black'
                    >
                      Количество пострадавших:
                    </Box>
                    <Box
                      textAlign='right'
                      maxWidth='50%'
                      fontSize={14}
                      lineHeight='24px'
                      color='text.disabled'
                    >
                      {acc?.injured_cnt}
                    </Box>
                  </Stack>
                  <Stack
                    sx={{ mt: 1 }}
                    direction='row'
                    width='100%'
                    justifyContent='space-between'
                  >
                    <Box
                      textAlign='left'
                      maxWidth='50%'
                      fontSize={14}
                      lineHeight='24px'
                      color='common.black'
                    >
                      Количество пострадавших детей:
                    </Box>
                    <Box
                      textAlign='right'
                      maxWidth='50%'
                      fontSize={14}
                      lineHeight='24px'
                      color='text.disabled'
                    >
                      {acc?.injured_child_cnt}
                    </Box>
                  </Stack>
                </>
              ) : null}

              {acc?.person_escape ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    Скрылся ли с места ДТП:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {acc.person_escape}
                  </Box>
                </Stack>
              ) : null}

              {acc?.phone ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    Телефон:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {acc.phone}
                  </Box>
                </Stack>
              ) : null}

              {acc?.person_residence_address ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    Адрес проживания:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {acc.person_residence_address}
                  </Box>
                </Stack>
              ) : null}

              {acc?.regno ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    ГРЗ:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {acc.regno}
                  </Box>
                </Stack>
              ) : null}

              {acc?.vehicle_mark_nm ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    Модель авто:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {acc.vehicle_mark_nm}
                  </Box>
                </Stack>
              ) : null}

              {acc?.street_nm ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    Улица:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {acc.street_nm}
                  </Box>
                </Stack>
              ) : null}

              {acc?.person_birthday ? (
                <Stack
                  sx={{ mt: 1 }}
                  direction='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box
                    textAlign='left'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='common.black'
                  >
                    День рождения:
                  </Box>
                  <Box
                    textAlign='right'
                    maxWidth='50%'
                    fontSize={14}
                    lineHeight='24px'
                    color='text.disabled'
                  >
                    {getFineDay(acc.person_birthday)}
                  </Box>
                </Stack>
              ) : null}
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  ) : null
})
