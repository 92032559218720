/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useContext, useRef, useState } from 'react'
// import { Map as OsMap, View, Overlay } from 'ol'
import { Map as OsMap, View } from 'ol'
import TileLayer from 'ol/layer/Tile'
import * as olProj from 'ol/proj'
import { BingMaps, Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Circle } from 'ol/geom'
import 'ol/ol.css'
import { Style } from 'ol/style'
import { Feature } from 'ol/index'
import s from './Map.module.scss'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../index'
// import DistrictPopup from './DistrictPopup'
import React from 'react'
import { Box } from '@mui/material'

export const RadarMap = observer(() => {
  olProj.useGeographic()

  const { rootStore } = useContext(Context)
  const { radarStore } = rootStore
  const { clearRadarStore, districts, districtData, fetchHexagonData } =
    radarStore

  // const [popup, setPopup] = useState(new Overlay({}))
  const popupRef = useRef(null)
  const popupCloser = useRef(null)
  const [zoom] = useState(9)
  const [map] = useState(
    new OsMap({
      target: '',
      layers: [],
      view: new View({
        center: [37.61, 55.743],
        zoom,
      }),
    })
  )
  // const [popupData, setPopupData] = useState()

  const clickHandler = (map) => (e) => {
    const pixel = e.pixel
    const feature = map.forEachFeatureAtPixel(pixel, (feature) => feature)
    // if (!feature && popupCloser.current) {
    //   return closePopup(popup, popupCloser)
    // }

    const districtData = feature?.values_?.districtData
    // const coordinate = e.coordinate
    fetchHexagonData(districtData)
    // setPopupData(districtData)
    // popup.setPosition(coordinate)
  }

  // const closePopup = (popup, closerRef) => {
  //   if (closerRef) {
  //     closerRef.current.blur()
  //   }
  //   popup.setPosition(undefined)
  //   setPopupData(null)
  //   return false
  // }

  //add TileLayer + popup
  useEffect(() => {
    map.setTarget('map')
    map.on('pointermove', (event) => {
      if (!event.dragging) {
        map.getTargetElement().style.cursor = map.hasFeatureAtPixel(
          map.getEventPixel(event.originalEvent)
        )
          ? 'pointer'
          : ''
      }
    })

    // const popup = new Overlay({
    //   element: popupRef.current,
    //   autoPan: {
    //     animation: {
    //       duration: 250,
    //     },
    //   },
    // })
    // setPopup(popup)

    // popupCloser.current.onclick = () => closePopup(popup, popupCloser)
    // map.on('singleclick', clickHandler(map, popup))
    map.on('singleclick', clickHandler(map))

    const TL = new TileLayer({
      source: new BingMaps({
        preload: Infinity,
        key: 'Au-hpzWQ9Ry_MF_eLWD57436BjnuUCz5lTFKtpKdwN80xjdsOZscgDUugcjARfb0',
        culture: 'ru',
        imagerySet: 'CanvasGray',
      }),
    })
    map.addLayer(TL)

    return () => {
      if (map) {
        map.removeLayer(TL)
      }
    }
  }, [map])

  // useEffect(() => {
  //   map.addOverlay(popup)
  // }, [popup, map])

  //add VectorLayer (points)
  useEffect(() => {
    let VL

    if (districts) {
      // const features = radarStore.districts.points.map((point) => {
      const features = districts.map(({ geo, ...rest }) => {
        // const { coords, ...rest } = point

        //https://openlayers.org/en/v6.15.1/examples/regularshape.html
        const feature = new Feature({
          // geometry: new Circle(coords.reverse(), 0.007),
          geometry: new Circle(geo.coordinates[0][0], 0.007),
          districtData: { ...rest },
        })

        feature.setStyle(
          new Style({
            renderer(coordinates, state) {
              const [[x, y], [x1, y1]] = coordinates
              const ctx = state.context
              const dx = x1 - x
              const dy = y1 - y
              const radius = Math.sqrt(dx * dx + dy * dy)

              const innerRadius = 0
              const outerRadius = radius * 1.4

              const gradient = ctx.createRadialGradient(
                x,
                y,
                innerRadius,
                x,
                y,
                outerRadius
              )
              gradient.addColorStop(0, 'rgba(255,0,0,0)')
              gradient.addColorStop(0.6, 'rgba(255,0,0,0.2)')
              gradient.addColorStop(1, 'rgba(255,0,0,0.8)')
              ctx.beginPath()
              ctx.arc(x, y, radius, 0, 2 * Math.PI, true)
              ctx.fillStyle = gradient
              ctx.fill()

              ctx.arc(x, y, radius, 0, 2 * Math.PI, true)
              ctx.strokeStyle = 'rgba(255,0,0,1)'
              ctx.stroke()
            },
          })
        )
        return feature
      })

      VL = new VectorLayer({
        className: 'districtsLayer',
        source: new VectorSource({
          features,
        }),
      })
      map.addLayer(VL)
      map
        .getLayers()
        .array_.find((layer) => layer.className_ === 'districtsLayer')
        .once('postrender', () => {
          map.getView().animate({
            zoom: 10.5,
            duration: 1000,
          })
        })
    }

    return () => {
      if (map) {
        map.removeLayer(VL)
      }
      if (districtData) {
        clearRadarStore()
      }
    }
  }, [map, districts, districtData, clearRadarStore])

  return (
    <Box>
      <Box className={s.map_wrapper} id='map'></Box>

      {/* <Box className={s.ol_popup} ref={popupRef}> */}
      {/* <a href='#' ref={popupCloser} className={s.ol_popup_closer}></a> */}
      {/* {popupData && (
          <DistrictPopup
            closePopup={closePopup}
            popup={popup}
            data={popupData}
          />
        )} */}
      {/* </Box> */}
    </Box>
  )
})
