import { Box, Stack, Typography } from '@mui/material'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { LinkedUsers as LinkedUsersI } from 'store/profileStore/profileStore.types'
import { LinkedUser } from './LinkedUser/LinkedUser'

export const LinkedUsers = observer<LinkedUsersI>((props) => {
  const [innerExpanded, setInnerExpanded] = useState<string | false>(false)

  const handleInnerChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setInnerExpanded(newExpanded ? panel : false)
    }

  const sortedProps = useMemo(
    () =>
      Object.entries(props).sort((a: any, b: any) =>
        a[1]?.w > b[1]?.w ? -1 : 0
      ),
    [props]
  )

  return (
    <Stack mt={2}>
      <Typography mb={2} fontWeight={700}>
        Связанные пользователи
      </Typography>

      {sortedProps.map(([id, data]) => (
        <Accordion
          key={Math.random()}
          expanded={innerExpanded === id}
          onChange={handleInnerChange(id)}
        >
          <AccordionSummary>
            <Stack direction='row'>
              <Box
                sx={{
                  mr: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SubheaderIcon name='PeopleAltIcon' />
              </Box>

              <Typography variant='body1' color='common.black'>
                ID Пользователя: {id}
              </Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <LinkedUser {...data} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  )
})
