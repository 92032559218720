import { Box, Stack, Typography } from '@mui/material'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { keysNames, usePersonalInfo } from './usePersonalInfo'

export const SocialProfile = observer(() => {
  const { socialProfileData } = usePersonalInfo()

  if (!socialProfileData?.length) {
    return null
  }

  return (
    <Stack sx={{ px: 2, pt: 2 }}>
      <Stack direction='row'>
        <Box
          sx={{
            mr: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SubheaderIcon name='FamilyRestroomIcon' />
        </Box>

        <Typography color='common.black' variant='h6'>
          Социальный профиль
        </Typography>
      </Stack>

      <Stack sx={{ width: '100%' }}>
        {socialProfileData.map(([key, value]: any, idx: number) => (
          <Stack
            key={`${key}_${idx}`}
            sx={{ width: '100%', p: 1 }}
            direction='row'
            justifyContent='space-between'
          >
            <Typography
              variant='body1'
              width='80%'
              textAlign='start'
              sx={{ wordBreak: 'break-word' }}
            >
              {keysNames[key]}
            </Typography>

            <Typography
              variant='h6'
              color='text.disabled'
              fontWeight={400}
              textAlign='right'
            >
              {value}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
})
