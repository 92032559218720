import { Stack, Typography } from '@mui/material'
import { Context } from 'index'
import React, { useContext } from 'react'
import { getCountText, transformDate } from '../FeaturePopup.utils'

interface HexagonInfo {
  detection_count: number
  last_detect: string
  subscrHexId: string
}

interface SubHexDataProps {
  data: HexagonInfo
  areNotSubscrHexVis?: boolean
}

export const Subhexdata = ({ data, areNotSubscrHexVis }: SubHexDataProps) => {
  const { detection_count, last_detect, subscrHexId } = data

  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { notSubscribedHexagons } = profileStore

  const postsData = notSubscribedHexagons?.find(
    (hex) => hex?.hexagon_id === subscrHexId
  )

  return (
    <Stack alignItems='flex-start'>
      <Stack alignItems='flex-start' mb={1}>
        {postsData?.title && areNotSubscrHexVis ? (
          <Stack alignItems='flex-start' mb={1}>
            <Typography component='b' variant='body2'>
              Название сообщества:
            </Typography>
            <Typography
              component='span'
              variant='body2'
              color='dimgray'
              textAlign='start'
            >
              {postsData.title}
            </Typography>
          </Stack>
        ) : null}

        {postsData?.postsCount && areNotSubscrHexVis ? (
          <Stack alignItems='flex-start' mb={1}>
            <Typography component='b' variant='body2'>
              Сообщений в сообществе:
            </Typography>
            <Typography component='span' variant='body2' color='dimgray'>
              {postsData.postsCount}
            </Typography>
          </Stack>
        ) : null}

        {postsData?.channelUsername && areNotSubscrHexVis ? (
          <Stack alignItems='flex-start' mb={1}>
            <Typography component='b' variant='body2'>
              Cсылка на группу:
            </Typography>
            <a
              target='_blank'
              rel='noreferrer'
              href={`https://t.me/${postsData.channelUsername}`}
            >
              {postsData.channelUsername}
            </a>
          </Stack>
        ) : null}

        <Typography component='b' variant='body2'>
          Появлялся в локации:
        </Typography>
        <Typography component='span' variant='body2' color='dimgray'>
          {getCountText(detection_count)}
        </Typography>
      </Stack>

      <Stack alignItems='flex-start'>
        <Typography component='b' variant='body2'>
          Последнее появление:
        </Typography>
        <Typography component='span' variant='body2' color='dimgray'>
          {transformDate(last_detect)}
        </Typography>
      </Stack>
    </Stack>
  )
}
