import { Box, Stack } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { Context } from 'index'
import 'react-image-gallery/styles/css/image-gallery.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import s from './PersonalGallery.module.scss'
import '../TransportGallery/TransportGalleryStyles.css'
import ImageGallery from 'react-image-gallery'
import { withSize } from 'react-sizeme'
import React from 'react'

const withSizeHOC = withSize({ monitorWidth: true, noPlaceholder: true })

const PersonalGallery = observer(({ isMobile, size }: any) => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { userPhotos } = profileStore

  const getSize = () => {
    const width = size?.width
    if (!isMobile) {
      return 360
    }
    if (width) {
      if (width >= 600 && width < 768) {
        return width - 300
      } else if (width >= 550 && width < 600) {
        return width - 250
      } else if (width >= 500 && width < 550) {
        return width - 200
      }
      return width - 100
    }
    return undefined
  }

  return userPhotos?.length ? (
    <Stack
      className={s.block}
      direction='column'
      maxWidth='900px'
      width='100%'
      sx={{ py: 0 }}
    >
      <Box className={s.gallery}>
        <ImageGallery
          showPlayButton={false}
          items={userPhotos?.map((image: string) => ({
            original: image.startsWith('/9j/')
              ? `data:image/jpeg;base64, ${image}`
              : image,
            originalHeight: getSize(),
            originalClass: 'userPhoto',
            thumbnail:
              userPhotos?.length > 1
                ? image.startsWith('/9j/')
                  ? `data:image/jpeg;base64, ${image}`
                  : image
                : undefined,
            thumbnailHeight: 70,
            thumbnailWidth: 50,
          }))}
        />
      </Box>
    </Stack>
  ) : null
})

export default withSizeHOC(PersonalGallery)
