import { Close } from '@mui/icons-material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'

import {
  BgContainer,
  CloseIcon,
  EforImage,
  ImageContainer,
} from './EforUserPhoto.styled'

export const EforUserPhoto = observer(() => {
  const { rootStore } = useContext(Context)
  const { mapStore } = rootStore
  const { userPhoto, setUserPhoto } = mapStore

  const getWidth = () => {
    if (
      userPhoto?.width &&
      userPhoto.height &&
      userPhoto.width !== userPhoto.height
    ) {
      if (userPhoto.width > userPhoto.height) {
        return '100vw'
      }

      return `${(userPhoto.width / userPhoto.height) * 100}vw`
    }

    return '100vw'
  }

  const getHeight = () => {
    if (
      userPhoto?.width &&
      userPhoto.height &&
      userPhoto.width !== userPhoto.height
    ) {
      if (userPhoto.height > userPhoto.width) {
        return '100vh'
      }

      return `${(userPhoto.height / userPhoto.width) * 100}vh`
    }

    return '100vh'
  }

  return userPhoto ? (
    <BgContainer>
      <ImageContainer
        width={getWidth()}
        height={getHeight()}
        maxheight={userPhoto.height || 'unset'}
        maxwidth={userPhoto.width || 'unset'}
      >
        <CloseIcon onClick={() => setUserPhoto(null)}>
          <Close sx={{ color: 'primary.main' }} />
        </CloseIcon>

        <EforImage src={userPhoto?.url} alt='eforUserPhoto' />
      </ImageContainer>
    </BgContainer>
  ) : null
})
