import { PochtaVisits } from 'components/Map/PochtaVisits'
import React, { useMemo } from 'react'
import { getCountText } from '../FeaturePopup.utils'
import s from '../../Map.module.scss'

interface _PochtaInfo {}

interface PochtaProps {
  data: any
  // data: _PochtaInfo
}

export const Pochta = ({ data }: PochtaProps) => {
  const count = data.count

  const getPochtaVisitData = useMemo(() => {
    const phone = data?.phone

    if (phone && phone.length) {
      const result: any = []

      phone.forEach((_phone: string, idx: number) => {
        result.push({
          ...data,
          delivery_local_dts: data.delivery_local_dts[idx],
          fio_rpo_rcpn_name: data.fio_rpo_rcpn_name[idx],
          fio_rpo_sndr_name: data.fio_rpo_sndr_name[idx],
          phone: data.phone[idx],
        })
      })
      return result
    }
  }, [data])

  return (
    <div className={s.locations}>
      <div className={s.visitedCount}>
        <b>Появлялся в локации:</b> <span>{getCountText(count)}</span>
      </div>

      <PochtaVisits visits={getPochtaVisitData(data)} />
    </div>
  )
}
