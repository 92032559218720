export const getStringFromArr = (arr: string[]) => {
  if (!arr) {
    return ''
  }

  return arr.map(
    (v: any, idx: number, arr: any) =>
      `${v}${idx !== arr.length - 1 ? ', ' : ''}`
  )
}

export const vkKeyTranslate = {
  about: 'О пользователе',
  generalInfo: 'Общие данные',
  name: 'Имя',
  personalInfo: 'Личное инфо',
  profile_link: 'Ссылка на профиль',
  bdate: 'Дата рождения',
  books: 'Книги',
  can_access_closed: 'Закрытый профиль',
  career: 'Карьера',
  company: 'Название компании',
  position: 'Должность',
  country: 'Страна',
  city: 'Город',
  mobile_phone: 'Мобильный телефон',
  home_phone: 'Домашний телефон',
  counters: 'Статистика',
  groups: 'Групп',
  user_photos: 'Фотографий пользователя',
  clips: 'Клипов',
  clips_views: 'Просмотров клипов',
  clips_likes: 'Лайков клипов',
  clips_followers: 'Подписчиков клипов',
  articles: 'Статей',
  albums: 'Альбомов',
  badges: 'Значков',
  audios: 'Аудио',
  followers: 'Подписчиков',
  friends: 'Друзей',
  gifts: 'Подарков',
  online_friends: 'Друзей Онлайн',
  pages: 'Страниц',
  photos: 'Фото',
  subscriptions: 'Подписок',
  videos: 'Видео',
  video_playlists: 'Плейлисты видео',
  mutual_friends: 'Близких друзей',
  first_name: 'Имя',
  games: 'Игры',
  home_town: 'Родной город',
  id: 'ИД',
  interests: 'Интересы',
  id_closed: 'Закрытый ИД',
  last_name: 'Фамилия',
  last_seen: 'Последний визит',
  lastSeenPlatform: 'Платформа',
  military: 'Военная служба',
  unit: 'Воинская часть',
  from: 'с',
  until: 'до',
  movies: 'Фильмы',
  music: 'Музыка',
  nickname: 'Никнейм',
  occupation: 'Занятость',
  personal: 'Личные данные',
  photo_max_orig: 'Фото',
  quotes: 'Цитаты',
  relation: 'Статус отношений',
  relatives: 'Родственники',
  relation_partner: 'Партнер',
  schools: 'Школы',
  speciality: 'Направление подготовки',
  sex: 'Пол',
  skype: 'Скайп',
  status: 'Статус',
  trending: 'В тренде',
  tv: 'ТВ',
  universities: 'Высшие образования',
  verified: 'Профиль подтвержден',
  alcohol: 'Алкоголь',
  inspired_by: 'Вдохновляют',
  langs: 'Языки',
  langs_full: 'Все языки',
  life_main: 'Главное в жизни',
  people_main: 'Важные люди',
  smoking: 'Курит',
  political: 'Политические взгляды',
  religion: 'Религиозные взгляды',
  occupationName: 'Название',
  occupationType: 'Род деятельности',
  graduate_year: 'Год окончания',
  univChair: 'Кафедра',
  univChair_name: 'Название кафедры',
  univEducation_form: 'Форма образования',
  univEducation_status: 'Статус',
  univFaculty: 'Факультет',
  univFaculty_name: 'Название факультета',
  univGraduation: 'Окончание учебы',
  univName: 'Название уч.заведения',
  schoolCity: 'Город',
  schoolClass: 'Класс',
  schoolName: 'Название школы',
  schoolYear_from: 'Год от',
  schoolYear_graduated: 'Год выпуска',
  schoolYear_to: 'Год до',
  relativeType: 'Родство',
  relVkId: 'Vk Id',
}
