import { Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { CoddFines as CoddFinesI } from 'store/mapStore/mapStore'

interface CoddFinesProps {
  data: CoddFinesI
}

const translateKey = {
  regno: 'ГРЗ',
  status: 'Статус',
  pass_dttm: 'Дата',
  violation_desc: 'Описание',
  violation_place: 'Место',
  violation_limit: 'Допустимая скорость',
  violation_speed: 'Скорость',
} as Record<string, string>

const getValue = (key: string, val: string) => {
  if (key === 'violation_limit' || key === 'violation_speed') {
    return `${val} км/ч`
  }

  if (key === 'pass_dttm') {
    // 2023-08-05T18:45
    const [date, time] = val.split('T')
    const [YYYY, MM, DD] = date.split('-')

    return `${DD}.${MM}.${YYYY} - ${time}`
  }

  return val
}

export const CoddFines = observer(({ data }: CoddFinesProps) => {
  const {
    regno,
    status,
    pass_dttm,
    violation_desc,
    violation_place,
    violation_limit,
    violation_speed,
    extraLocs,
  } = data

  const obj = {
    regno,
    status,
    pass_dttm,
    violation_desc,
    violation_place,
    violation_limit,
    violation_speed,
  }

  if (extraLocs?.length) {
    return (
      <Stack alignItems='flex-start' width='100%' pr={2}>
        <Typography
          variant='body2'
          textAlign='center'
          color='gray'
          width='100%'
          mb={2}
        >
          {getValue('pass_dttm', pass_dttm)}
        </Typography>

        {Object.entries(obj)
          .filter(([k, val]) => !!val && k !== 'pass_dttm')
          .map(([key, val]) => (
            <Stack
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              key={key}
              mb={1}
            >
              <Typography
                width='40%'
                textAlign='start'
                component='b'
                variant='body2'
              >
                {translateKey?.[key] ?? key}:
              </Typography>

              <Typography
                width='60%'
                textAlign='end'
                component='span'
                variant='body2'
                color='dimgray'
              >
                {getValue(key, val)}
              </Typography>
            </Stack>
          ))}

        {extraLocs.map((extraLoc) => {
          const {
            regno,
            status,
            pass_dttm,
            violation_desc,
            violation_place,
            violation_limit,
            violation_speed,
          } = extraLoc

          const extraLocObj = {
            regno,
            status,
            pass_dttm,
            violation_desc,
            violation_place,
            violation_limit,
            violation_speed,
          }

          return (
            <React.Fragment key={extraLoc.id}>
              <Typography
                variant='body2'
                textAlign='center'
                color='gray'
                width='100%'
                mb={2}
              >
                {getValue('pass_dttm', pass_dttm)}
              </Typography>

              {Object.entries(extraLocObj)
                .filter(([k, val]) => !!val && k !== 'pass_dttm')
                .map(([key, val]) => (
                  <Stack
                    flexDirection='row'
                    width='100%'
                    justifyContent='space-between'
                    key={key}
                    mb={1}
                  >
                    <Typography
                      width='40%'
                      textAlign='start'
                      component='b'
                      variant='body2'
                    >
                      {translateKey?.[key] ?? key}:
                    </Typography>

                    <Typography
                      width='60%'
                      textAlign='end'
                      component='span'
                      variant='body2'
                      color='dimgray'
                    >
                      {getValue(key, val)}
                    </Typography>
                  </Stack>
                ))}
            </React.Fragment>
          )
        })}
      </Stack>
    )
  }

  return (
    <Stack alignItems='flex-start' width='100%' pr={2}>
      {Object.entries(obj)
        .filter(([_, val]) => !!val)
        .map(([key, val]) => (
          <Stack
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            key={key}
            mb={1}
          >
            <Typography
              width='40%'
              textAlign='start'
              component='b'
              variant='body2'
            >
              {translateKey?.[key] ?? key}:
            </Typography>

            <Typography
              width='60%'
              textAlign='end'
              component='span'
              variant='body2'
              color='dimgray'
            >
              {getValue(key, val)}
            </Typography>
          </Stack>
        ))}
    </Stack>
  )
})
