import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Box, Stack, Typography } from '@mui/material'

import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import { useInsuranceData } from './useInsuranceData'

export const InsuranceData = observer(() => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const { insurance, translateInsurance } = useInsuranceData()

  return insurance ? (
    <Box sx={{ mt: 2, maxWidth: '900px', width: '100%', px: '20px' }}>
      <Accordion
        expanded={expanded === 'gibdd'}
        onChange={handleChange('gibdd')}
      >
        <AccordionSummary>
          <Stack direction='row' alignItems='center'>
            <HealthAndSafetyIcon
              sx={{ color: 'primary.main', mr: 1, height: 24, width: 24 }}
            />

            <Typography color='common.black' variant='body1'>
              Страховой полис
            </Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <Stack
            justifyContent='space-between'
            sx={{
              mt: 1,
              width: '100%',
              maxWidth: '900px',
            }}
          >
            {insurance.map(([key, value]: any, idx: number) =>
              translateInsurance?.[key] ? (
                <Stack
                  key={idx}
                  justifyContent='space-between'
                  flexDirection='row'
                  sx={{ mt: idx ? 1 : 0 }}
                >
                  <Typography
                    color='common.black'
                    width='50%'
                    textAlign='start'
                  >
                    {translateInsurance[key].text}:
                  </Typography>

                  <Typography
                    width='50%'
                    textAlign='end'
                    color='text.disabled'
                    sx={{ overflowWrap: 'break-word' }}
                  >
                    {Array.isArray(value)
                      ? value.map((el, idx, arr) =>
                          idx !== arr.length - 1 ? `${el}; ` : el
                        )
                      : value}
                  </Typography>
                </Stack>
              ) : null
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  ) : null
})
