import { RootStore } from '../rootStore'
import { makeAutoObservable, runInAction } from 'mobx'
import { request } from '../../utils/request'

class TableStore {
  rootStore: RootStore
  isAuth = false
  tableData: any = null
  error = ''
  lastRoute = ''
  rerenderCount = 1

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  async authUser() {
    this.rootStore.loaderStore.setIsLoading(true)
    const res = await request(
      `/login`,
      'POST',
      {},
      { login: 'im', password: 'gfh43gh56g' }
    )
    if (res.success) {
      runInAction(() => {
        localStorage.setItem('accessToken', res.payload.token)
        this.isAuth = true
      })
    }
    if (!res.success) {
      runInAction(() => (this.isAuth = false))
    }
    this.rootStore.loaderStore.setIsLoading(false)
  }

  async fetchTableData(params: string[], preventLoadingStop = false) {
    const dataId = params.find((el) => el.includes('dataId')) as string
    const userId = params.find((el) => el.includes('userId')) as string
    const isAdmin = params.find((el) => el.includes('isAdmin')) as string

    const data = dataId.slice(dataId.indexOf('=') + 1)
    const user = userId.slice(userId.indexOf('=') + 1)
    const admin = isAdmin.slice(isAdmin.indexOf('=') + 1)

    const headers = { dataId: data, userId: user, isAdmin: admin }

    if (!preventLoadingStop) {
      this.rootStore.loaderStore.setIsLoading(true)
    }
    const res = await request(
      `get_data_trip_photos/${data}`,
      'GET',
      {},
      {},
      false,
      true,
      { ...headers }
    )
    const { success, payload } = res

    runInAction(() => {
      if (success) {
        this.tableData = { ...payload, data: payload.data }

        // const grz = payload?.data?.find((obj: any) => obj?.grz).grz

        // if (grz) {
        // this.loadingLastRoute = true

        // this.authInTransportProfile().then((token) => {
        //   console.log({ token })
        //   if (!token) {
        //     return
        //   }

        //   this.getJobIdByGrz(
        //     grz,
        //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjMxMDc1NjksImV4cCI6MTcyMzE5Mzk2OSwianRpIjoiYmM5Y2JjNzItYTkzYi00OTgzLTlkMDktMDNlYjk2M2FkZTQ4IiwiaWQiOjEsInJscyI6InN1cGVydXNlciIsInJmX2V4cCI6MTcyNTY5OTU2OX0.1Q2y7YpiXoXmr5HaWUirI5ZPxMXyvQopcoMjk6Srhzw'
        //   ).then((jobId) => {
        //     if (!jobId) {
        //       return
        //     }

        //     this.fetchDataByJobId(
        //       jobId,
        //       'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjMxMDc1NjksImV4cCI6MTcyMzE5Mzk2OSwianRpIjoiYmM5Y2JjNzItYTkzYi00OTgzLTlkMDktMDNlYjk2M2FkZTQ4IiwiaWQiOjEsInJscyI6InN1cGVydXNlciIsInJmX2V4cCI6MTcyNTY5OTU2OX0.1Q2y7YpiXoXmr5HaWUirI5ZPxMXyvQopcoMjk6Srhzw'
        //     )
        //   })
        // })
        // }
      } else if (res.code === 400) {
        this.error = 'Данные устарели, повторите ваш запрос'
      } else if (res.error) {
        this.error = res.error
      } else {
        this.error = 'Не удалось получить данные пользователя'
      }
    })

    if (!preventLoadingStop) {
      this.rootStore.loaderStore.setIsLoading(false)
    }
  }

  // authInTransportProfile = async () => {
  //   // return await new Promise(res => res('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjMxMDc1NjksImV4cCI6MTcyMzE5Mzk2OSwianRpIjoiYmM5Y2JjNzItYTkzYi00OTgzLTlkMDktMDNlYjk2M2FkZTQ4IiwiaWQiOjEsInJscyI6InN1cGVydXNlciIsInJmX2V4cCI6MTcyNTY5OTU2OX0.1Q2y7YpiXoXmr5HaWUirI5ZPxMXyvQopcoMjk6Srhzw'))

  //   const token: string = await fetch(
  //     'https://10.0.0.108:3000/login?username=root&password=f%23w%23$B1HJ5CFJL',
  //     { method: 'POST' }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => data.access_token)

  //   if (token) {
  //     return token
  //   }

  //   return ''
  // }

  // getJobIdByGrz = async (regno: string, token: string) => {
  //   if (!token || !regno) {
  //     return
  //   }

  //   console.log(token)

  //   const headers = new Headers()
  //   headers.append('Authorization', `Bearer ${token}`)

  //   const requestOptions: RequestInit = {
  //     method: 'POST',
  //     headers,
  //     redirect: 'follow',
  //   }

  //   const jobId: string = await fetch(
  //     `https://10.0.0.108:3000/transport_profile/v2/camera_check?regno=${regno}`,
  //     requestOptions
  //   )
  //     .then((res) => res.json())
  //     .then((data) => data.job_id)

  //   if (jobId) {
  //     return jobId
  //   }

  //   return ''
  // }

  // fetchDataByJobId = async (jobId: string, token: string) => {
  //   const res = await request(
  //     `https://10.0.0.108:3000/transport_profile/v2/camera_check/${jobId}`,
  //     'GET',
  //     {},
  //     {},
  //     false,
  //     false,
  //     { Authorization: `Bearer ${token}` }
  //   )

  //   runInAction(() => {
  //     if (res.success) {
  //       const status = res?.payload?.status

  //       if (status === 'queued' || status === 'started') {
  //         return new Promise((resolve) => {
  //           setTimeout(() => resolve(this.fetchDataByJobId(jobId, token)), 5000)
  //         })
  //       }

  //       if (status === 'finished' || res?.payload?.result?.points) {
  //         this.lastRoute = res.payload.result.points
  //         this.loadingLastRoute = false
  //         this.rerenderCount += 1
  //       }
  //     }

  //     return
  //   })
  // }
}

export default TableStore
