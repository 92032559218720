import { Checkbox, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { CheckboxesContainer, CheckboxLabel } from './CheckboxList.styled'
import { observer } from 'mobx-react-lite'
import { InputFilter } from './InputFilter'
import { Context } from 'index'
import { CheckboxProps } from 'store/radarStore/radar.types'
import { useCallback } from 'react'
import { useMemo } from 'react'

export const CheckboxList: React.FC<CheckboxProps> = observer(({ data }) => {
  const { rootStore } = useContext(Context)
  const { radarStore } = rootStore
  const {
    checkboxState,
    handleAllCheckboxes,
    handleChildCheckboxChange,
    handleParentCheckboxChange,
    selectFilterValue,
  } = radarStore

  const isParentCheckboxChecked = useCallback(
    (parentKey: string) => {
      return checkboxState?.[parentKey]?.length === data?.[parentKey]?.length
    },
    [checkboxState, data]
  )

  const isChildCheckboxChecked = useCallback(
    (parentKey: string, childValue: string) => {
      return checkboxState?.[parentKey]?.includes(childValue)
    },
    [checkboxState]
  )

  const isEveryCheckboxChecked = useMemo(() => {
    return (
      data &&
      Object.keys(data).every(
        (parent: string) =>
          checkboxState?.[parent]?.length === data?.[parent]?.length
      )
    )
  }, [checkboxState, data])

  const filteredList = useMemo(() => {
    const inputValue = selectFilterValue.toLowerCase()

    return selectFilterValue
      ? Object.keys(data).filter(
          (parentKey) =>
            parentKey.toLowerCase().indexOf(inputValue) !== -1 ||
            data[parentKey].some(
              (child) => child.toLowerCase().indexOf(inputValue) !== -1
            )
        )
      : Object.keys(data)
  }, [data, selectFilterValue])

  const filteredChildren = useCallback(
    (parentKey: string) => {
      const inputValue = selectFilterValue.toLowerCase()

      return selectFilterValue
        ? data[parentKey].filter(
            (child) => child.toLowerCase().indexOf(inputValue) !== -1
          )
        : data[parentKey]
    },
    [data, selectFilterValue]
  )

  return (
    <CheckboxesContainer>
      <Typography fontSize='18px' lineHeight='24px' fontWeight={700} mt={2}>
        Выбор округов для запроса
      </Typography>

      <InputFilter />

      <Stack p={3}>
        <CheckboxLabel
          labelPlacement='start'
          label='Вся Москва'
          sx={{
            mb: 2,
            '.MuiCheckbox-root': { p: 0 },
            '.MuiTypography-body1': { fontWeight: 600 },
            ml: 0,
          }}
          control={
            <Checkbox
              checked={isEveryCheckboxChecked}
              onChange={() => handleAllCheckboxes()}
            />
          }
        />

        {filteredList.map((parentKey) => (
          <Stack key={parentKey} my={1}>
            <CheckboxLabel
              labelPlacement='start'
              label={parentKey}
              sx={{
                mb: 2,
                '.MuiCheckbox-root': { p: 0 },
                '.MuiTypography-body1': { fontWeight: 600 },
                ml: 0,
              }}
              control={
                <Checkbox
                  checked={isParentCheckboxChecked(parentKey)}
                  onChange={() => handleParentCheckboxChange(parentKey)}
                />
              }
            />

            {filteredChildren(parentKey).map((childValue) => (
              <Stack key={childValue}>
                <CheckboxLabel
                  sx={{ '.MuiCheckbox-root': { p: 0 }, ml: 0 }}
                  labelPlacement='start'
                  label={childValue}
                  control={
                    <Checkbox
                      checked={isChildCheckboxChecked(parentKey, childValue)}
                      onChange={() =>
                        handleChildCheckboxChange(parentKey, childValue)
                      }
                    />
                  }
                />
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </CheckboxesContainer>
  )
})
