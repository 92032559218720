import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

interface RouteProps {
  title: JSX.Element
  from: string
  to: string
}

export const PopularRoute = ({ title, from, to }: RouteProps) => {
  return (
    <Stack justifyContent='center'>
      {title}

      <Stack my={2}>
        <Stack flexDirection='row' width='100%' mb={1}>
          <Stack
            sx={{
              width: 24,
              height: 24,
              bgcolor: '#EB555C',
              color: '#fff',
              borderRadius: '4px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            A
          </Stack>

          <Stack flexDirection='row' alignItems='center' width='100%'>
            <KeyboardArrowLeftIcon sx={{ color: '#000' }} />
            <Box
              sx={{
                bgcolor: '#000',
                height: '2px',
                width: '100%',
                mx: '-14px',
              }}
            ></Box>
            <KeyboardArrowRightIcon sx={{ color: '#000' }} />
          </Stack>

          <Stack
            sx={{
              width: 24,
              height: 24,
              bgcolor: '#000',
              color: '#fff',
              borderRadius: '4px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            B
          </Stack>
        </Stack>

        <Stack flexDirection='row' justifyContent='space-between'>
          <Typography
            maxWidth='50%'
            color='#EB555C'
            variant='body1'
            textAlign='start'
            lineHeight='16px'
            pr={1}
          >
            {from}
          </Typography>

          <Typography
            maxWidth='50%'
            color='#030303'
            variant='body1'
            textAlign='end'
            lineHeight='16px'
            pl={1}
          >
            {to}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
