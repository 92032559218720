export const translateRelKey = (key: string) => {
  switch (key) {
    case 'phone':
      return 'телефоны'
    case 'regno':
      return 'ГРЗ'
    case 'period_to':
      return 'последняя дата'
    case 'ticket':
      return 'билет'
    case 'ticket_type':
      return 'тип билета'
    case 'ticket_type_id':
      return 'id билета'
    default:
      return key
  }
}

export const translateAppKey = (key: string) => {
  switch (key) {
    case 'application':
      return 'название приложения'
    case 'data_nm':
      return 'data_nm'
    case 'period_to':
      return 'дата последнего использования'
    case 'phone':
      return 'телефон'
    default:
      return key
  }
}
