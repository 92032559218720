import { Slider } from '@mui/material'
import React, { useCallback, useState } from 'react'

export const SpeedSlider = () => {
  const [value, setValue] = useState(20)

  const handleChange = useCallback((newValue: number) => {
    setValue(newValue)
    localStorage.setItem('animationSpeed', String(newValue))
  }, [])

  return (
    <Slider
      value={value}
      onChange={(_, newValue) => handleChange(newValue as number)}
      aria-label='Small'
      valueLabelDisplay='auto'
      id='slider'
      sx={{ width: 200 }}
      step={10}
    />
  )
}
