export const palette = {
  common: {
    white: '#FFFFFF',
    black: '#030303',
    edrew: '#000000',
  },
  text: {
    primary: '#182027',
    secondary: '#7C8A96',
    disabled: '#747474',
  },
  primary: {
    main: '#5578EB',
    // dark: '#5F4BBF',
    light: '#188FCA',
    contrast: '#56CCF2',
  },
  secondary: {
    main: '#EBEAF8',
    dark: '#E2E1F5',
    light: '#F5F4FB',
    contrast: '#8A84D6',
  },
  warning: {
    main: '#EB555C',
    light: '#EB555C',
    dark: '#EB555C',
  },
  action: {
    active: '#757483',
    hover: 'rgba(19, 18, 22, 0.04)',
    selected: 'rgba(19, 18, 22, 0.08)',
    disabled: 'rgba(19, 18, 22, 0.26)',
    focus: 'rgba(19, 18, 22, 0.12)',
  },
  background: {
    paper: '#FFFFFF',
    default: '#F1F1F1',
  },
  other: {
    divider: 'rgba(0, 0, 0, 0.12)',
    outlinedborder: 'rgba(0, 0, 0, 0.23)',
    backdropoverlay: 'rgba(0, 0, 0, 0.5)',
    filledinputbackground: 'rgba(0, 0, 0, 0.09)',
    standardinputline: 'rgba(0, 0, 0, 0.42)',
    snackbar: '#323232',
    ratingactive: '#FAC565',
  },
}
