import { Box, Stack } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext, useMemo } from 'react'
import { Context } from '../../..'
import 'react-image-gallery/styles/css/image-gallery.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import s from './TransportGallery.module.scss'
import './TransportGalleryStyles.css'
import ImageGallery from 'react-image-gallery'
import { withSize } from 'react-sizeme'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import React from 'react'
import { Header } from '../components/Header'
import { Button } from 'components/common/Button'

const withSizeHOC = withSize({ monitorWidth: true, noPlaceholder: true })

const TransportGallery = observer(({ isMobile, size }: any) => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const {
    additionalImages,
    tripPhotos,
    tripPhotoGrz,
    tripPhotosId,
    tripPhotosAdded,
    additionalImagesAdded,
    requestedParams,
  } = profileStore

  const photos = useMemo(() => {
    const result: string[] = []

    if (additionalImagesAdded && additionalImages?.length) {
      additionalImages.forEach((photo) => photo && result.push(photo))
    }

    if (tripPhotosAdded) {
      tripPhotos.forEach((photo) => photo && result.push(photo))
    }

    return result
  }, [additionalImagesAdded, additionalImages, tripPhotos, tripPhotosAdded])

  const getSize = () => {
    const width = size?.width
    if (!isMobile) {
      return 360
    }
    if (width) {
      if (width >= 600 && width < 768) {
        return width - 300
      } else if (width >= 550 && width < 600) {
        return width - 250
      } else if (width >= 500 && width < 550) {
        return width - 200
      }
      return width - 100
    }
    return undefined
  }

  const { userId, isAdmin } = requestedParams

  return photos?.length ? (
    <Stack
      className={s.block}
      direction='column'
      spacing={2}
      maxWidth='900px'
      width='100%'
      sx={{
        mt: 2,
      }}
    >
      <Header
        text='Фото транспорта'
        icon={
          <DirectionsCarIcon
            sx={{ bgcolor: 'primary.light', color: 'common.white' }}
          />
        }
      />

      <Box className={s.gallery} sx={{ mb: 10 }}>
        <ImageGallery
          showPlayButton={false}
          items={photos?.map((image: string) => ({
            original: image?.startsWith('/9j/')
              ? `data:image/jpeg;base64, ${image}`
              : image,
            originalHeight: getSize(),
            originalClass: 'autoPhoto',
            thumbnail:
              photos?.length > 1
                ? image?.startsWith('/9j/')
                  ? `data:image/jpeg;base64, ${image}`
                  : image
                : undefined,
            thumbnailHeight: 70,
            thumbnailWidth: 50,
          }))}
        />
      </Box>

      {tripPhotoGrz && tripPhotosId ? (
        <Stack width='100%' alignItems='center'>
          <Button
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            component='a'
            target='_blank'
            href={`/trip_photos/dataId=${tripPhotosId}&userId=${userId}&isAdmin=${isAdmin}`}
            variant='contained'
          >
            {`Фотофиксации ${tripPhotoGrz}`}
          </Button>
        </Stack>
      ) : null}
    </Stack>
  ) : null
})

export default withSizeHOC(TransportGallery)
