export const isVelobike = (data: any) => Array.isArray(data)
export const isPochta = (data: any) =>
  data.fio_rpo_rcpn_name && data.fio_rpo_sndr_name && data.index_to_ccode

export const getCountText = (count: number) => {
  const str = String(count)
  const countLastInt = +str[str.length - 1]
  const pluralNounEndings = [2, 3, 4]
  const ending = pluralNounEndings.includes(countLastInt) ? 'раза' : 'раз'
  return `${count} ${ending}`
}

export const transformDate = (str: string) => {
  const [date, time] = str.split('T')
  const [YYYY, MM, DD] = date.split('-')
  const [hour, min] = time.split(':')

  return `${DD}.${MM}.${YYYY} в ${hour}:${min}`
}
