import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Header = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  svg: {
    marginRight: 8,
    borderRadius: 7,
    padding: 3,
  },

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
  },
}))
