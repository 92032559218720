import { Box, Button, Collapse, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

const Template = observer(({ data, type }: any) =>
  data?.map(({ count, name }: any, idx: number) => (
    <Stack
      key={`${name}_${idx}`}
      sx={{ width: '100%', p: 1 }}
      direction='row'
      justifyContent='space-between'
    >
      {type === 'urls' ? (
        <Typography
          width='80%'
          variant='body1'
          textAlign='start'
          sx={{
            wordBreak: 'break-word',
            a: {
              color: 'primary.light',
              borderBottom: '1px solid lightgray',
            },
          }}
        >
          <a
            target='_blank'
            href={name?.startsWith('http') ? name : `https://${name}`}
            rel='noreferrer'
          >
            {name}
          </a>
        </Typography>
      ) : (
        <Typography
          variant='body1'
          width='80%'
          textAlign='start'
          sx={{ wordBreak: 'break-word' }}
        >
          {name}
        </Typography>
      )}

      <Typography variant='h6' color='text.disabled' fontWeight={400}>
        {count}
      </Typography>
    </Stack>
  ))
)

export const CollapsibleBlock = observer(({ vals, type }: any) => {
  const [showAll, setShowAll] = useState(false)

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll)
  }

  const visibleParagraphs = vals.slice(0, 2)
  const hiddenParagraphs = vals.slice(2)

  return (
    <>
      <Template data={visibleParagraphs} type={type} />

      <Collapse in={showAll}>
        <Template data={hiddenParagraphs} type={type} />
      </Collapse>

      {vals.length > 2 && (
        <Button
          onClick={toggleShowAll}
          variant='text'
          color='primary'
          sx={{ textTransform: 'none' }}
        >
          {showAll ? (
            'Скрыть'
          ) : (
            <Box component='span'>
              Показать еще{' '}
              <Box component='span' fontWeight={600}>
                {vals.length - 2}
              </Box>
            </Box>
          )}
        </Button>
      )}
    </>
  )
})
