import React from 'react'
import { Button as MUIButton } from '@mui/material'

import type { ButtonProps } from './Button.types'

export const Button: React.FC<ButtonProps> = ({
  variant = 'outlined',
  additionalSx,
  isActive,
  ...props
}) => {
  const commonStyles = {
    textTransform: 'none',
    maxWidth: 'fit-content',
    color: isActive ? '#5578EB' : '#747474',
    border: isActive ? '2px solid #5578EB' : '2px solid transparent',
    fontWeight: 550,
    padding: '8px 10px',
    ':hover': {
      border: `2px solid ${isActive ? '#5578EB' : 'transparent'}`,
    },
    ...additionalSx,
  }

  const themes: any[] = [
    {
      variant: 'outlined',
      sx: commonStyles,
    },
    {
      variant: 'text',
      sx: commonStyles,
    },
    {
      variant: 'contained',
      sx: {
        ...commonStyles,
        bgcolor: '#5578EB',
        color: '#fff',
      },
    },
  ]

  const params = themes.find((it) => it.variant === variant) || themes[0]

  return <MUIButton size='medium' {...params} {...props} />
}
