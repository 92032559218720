import { observer } from 'mobx-react-lite'
import React from 'react'
import { FaceMatchingResponse } from 'store/faceMatchingStore/types'
import * as S from './FaceMatching.styled'
import { v4 } from 'uuid'
import { Stack, Typography } from '@mui/material'
import { Image, Data } from './MordovorotCard'

export const Mordovorot = observer<{
  data: FaceMatchingResponse['mordavorot']
}>(({ data }) => {
  return (
    <Stack>
      <Typography variant='h5'>Mordovorot</Typography>

      <S.MordovorotPhotosWrapper>
        {data.map(({ url }) => (
          <S.Card key={v4()} boxShadow={4}>
            <Image src={url} />
            <Data url={url} />
          </S.Card>
        ))}
      </S.MordovorotPhotosWrapper>
    </Stack>
  )
})
