import { RootStore } from '../rootStore'
import { makeAutoObservable, runInAction } from 'mobx'
import { request } from '../../utils/request'
import { FaceMatchingResponse } from './types'

class FaceMatchingStore {
  rootStore: RootStore
  isAuth = false
  isDataRequested = false
  fetchedData: null | FaceMatchingResponse = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  authUser = async () => {
    this.rootStore.loaderStore.setIsLoading(true)

    const res = await request(
      `/login`,
      'POST',
      {},
      { login: 'im', password: 'gfh43gh56g' }
    )

    runInAction(() => {
      if (res.success) {
        localStorage.setItem('accessToken', res.payload.token)
        this.isAuth = true
      } else {
        this.isAuth = false
        this.rootStore.loaderStore.setIsLoading(false)
      }
    })
  }

  fetchData = async (params: string[]) => {
    const dataId = params.find((el) => el.includes('dataId'))
    const userId = params.find((el) => el.includes('userId'))
    const isAdmin = params.find((el) => el.includes('isAdmin'))

    let data, user, admin

    if (dataId) {
      data = dataId.slice(dataId.indexOf('=') + 1)
    }

    if (userId) {
      user = userId.slice(userId.indexOf('=') + 1)
    }

    if (isAdmin) {
      admin = isAdmin.slice(isAdmin.indexOf('=') + 1)
    }

    const headers = { dataId: data, userId: user, isAdmin: admin }

    const res = await request(
      `/face_matching/${data}`,
      'GET',
      {},
      {},
      false,
      true,
      { ...headers }
    )

    if (res.success) {
      runInAction(() => {
        this.fetchedData = res.payload
        this.rootStore.loaderStore.setIsLoading(false)
      })
    }
  }
}

export default FaceMatchingStore
