import { Context } from 'index'
import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useRef,
  ChangeEvent,
} from 'react'
import ForceGraph2D from 'react-force-graph-2d'
import user from 'assets/graphIcons/userInfo.png'
import godEye from 'assets/graphIcons/godEye.png'
import dofin from 'assets/graphIcons/dofin.png'
import external from 'assets/graphIcons/external.png'
import transPhoto from 'assets/graphIcons/transport.png'
import efor from 'assets/graphIcons/efor.png'
import { Image as ImageTag } from 'antd'
import { useMediaPredicate } from 'react-media-hook'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import React from 'react'
import {
  GraphAccordion,
  GraphAccordionSummary,
  GraphAccordionDetails,
} from 'components/common/GraphAccordion'
import * as S from './Graph.styled'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import { Box, IconButton, Stack } from '@mui/material'
import SpokeIcon from '@mui/icons-material/Spoke'
import { Loading } from 'components/Loading'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

const syncLoadAllImages = (imageQueue: any, callback: any) => {
  const numAll = imageQueue.length
  let numProcessed = 0
  const allImages: any = {}

  if (numAll === 0) {
    callback(allImages)
    return
  }

  imageQueue.forEach((img: any) => {
    const image = new Image()
    const id = img.id

    image.addEventListener('load', () => {
      numProcessed++
      allImages[id] = image
      if (numAll === numProcessed) {
        if (callback) {
          callback(allImages)
          return
        }
      }
    })
    image.addEventListener('error', () => {
      numProcessed++
    })
    image.src = img.image
  })
}

const Search = observer(
  ({ setData, initialData }: { setData: any; initialData: any }) => {
    const [searchValue, setSeachValue] = useState('')

    const filteredData = useMemo(() => {
      if (!searchValue) {
        return initialData
      }

      const filteredNodes = initialData.nodes.filter(
        (node: any) =>
          node.text.toLowerCase().includes(searchValue.toLowerCase()) ||
          (node?.values?.length &&
            node.values.some((obj: any) =>
              Object.values(obj).some(
                (val: any) =>
                  val &&
                  String(val).toLowerCase().includes(searchValue.toLowerCase())
              )
            ))
      )

      const filteredLinks = initialData?.links?.filter(
        (link: any) =>
          filteredNodes?.some((node: any) =>
            node.links?.some(
              (nodeLink: any) =>
                nodeLink.source.id === link.source.id &&
                nodeLink.target.id === link.target.id
            )
          ) &&
          link?.value &&
          link.value?.some((val: any) =>
            String(val).toLowerCase().includes(searchValue.toLowerCase())
          )
      )

      return { nodes: filteredNodes, links: filteredLinks }
    }, [initialData, searchValue])

    const handleSearch = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target

        setSeachValue(value)

        if (!value) {
          setData(initialData, '')
        }
      },
      [initialData, setData]
    )

    return (
      <S.Search
        value={searchValue}
        onChange={handleSearch}
        variant='outlined'
        placeholder='Поиск'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            searchValue
              ? setData(filteredData, searchValue)
              : setData(initialData, '')
          }
        }}
        sx={{ bgcolor: '#fff' }}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{ color: 'primary.main' }}
              onClick={() =>
                searchValue
                  ? setData(filteredData, searchValue)
                  : setData(initialData, '')
              }
            >
              <SearchOutlinedIcon />
            </IconButton>
          ),
        }}
      />
    )
  }
)

export const Graph = observer(() => {
  const { rootStore } = useContext(Context)
  const graphStore = rootStore.graphStore

  const [dto, setDto] = useState<any>()

  const maxValuesInLink = useRef(0)

  const resizeFn = useCallback(() => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])

  useEffect(() => {
    resizeFn()

    window.addEventListener('resize', resizeFn)

    return () => document.removeEventListener('resize', resizeFn)
  }, [resizeFn])

  useEffect(() => {
    if (!graphStore.isAuth) {
      graphStore.authUser()
    }
  }, [graphStore])

  const params = useParams()?.data

  useEffect(() => {
    if (params && graphStore.isAuth) {
      graphStore.fetchGraphData(params.split('&'))
    }
  }, [graphStore, params, graphStore.isAuth])

  useEffect(() => {
    if (graphStore.graphData) {
      setDto(graphStore.graphData)
    }
  }, [graphStore.graphData])

  const graphData = useMemo(() => {
    if (dto) {
      const gData = {
        nodes: dto.nodes.map((node: any) => ({
          ...node,
          id: node.id.split('node')[1] - 1,
          values: dto.data[node.id],
        })),
        links: dto.links.map((link: any) => ({
          ...link,
          source: link.source.split('node')[1] - 1,
          target: link.target.split('node')[1] - 1,
        })),
      }

      gData.links.forEach((link: any) => {
        const a = gData.nodes[link.source]
        const b = gData.nodes[link.target]
        !a.neighbors && (a.neighbors = [])
        !b.neighbors && (b.neighbors = [])
        a.neighbors.push(b)
        b.neighbors.push(a)

        !a.links && (a.links = [])
        !b.links && (b.links = [])
        a.links.push(link)
        b.links.push(link)
      })

      gData.nodes.forEach((node: any) => {
        node.linkValues = node?.links?.map((link: any) => link.value).flat()
      })

      let maxLinksSize: any

      gData.nodes.forEach((node: any) => {
        const linksSize = node?.links?.length

        if (!linksSize) {
          return
        }

        const valuesAmountInLink: number[] = node?.links?.map(
          (link: any) => link.value.length
        )
        const linkWidth = valuesAmountInLink.reduce(
          (a, b) => Math.max(a, b),
          -Infinity
        )

        if (!maxLinksSize) {
          maxLinksSize = linksSize
          return (maxValuesInLink.current = linkWidth)
        }

        if (maxLinksSize < linksSize) {
          maxLinksSize = linksSize
        }

        if (maxValuesInLink.current < linkWidth) {
          maxValuesInLink.current = linkWidth
        }

        return
      })

      const _data = {
        links: gData.links,
        nodes: gData.nodes.map((node: any) => {
          const linksSize = node?.links?.length

          if (!linksSize) {
            return {
              ...node,
              nodeSize: 6,
              linkWidth: 1,
            }
          }

          const normalizedLinksSize = Math.min(
            Math.max(linksSize, 1),
            maxLinksSize
          )

          //в ключах лежат названия соседних нод, в значениях - передаваемые по связи значения
          const tetheredValues: { [k: string]: string[] } = {}

          node.neighbors.forEach((neighbor: any) => {
            const neighbourId = neighbor?.id

            const linkFrom = node?.links?.find(
              (link: any) => link?.source === neighbourId
            )
            const linkTo = node?.links?.find(
              (link: any) => link?.target === neighbourId
            )

            const link = linkFrom ? linkFrom : linkTo

            if (!link) {
              return
            }

            tetheredValues[neighbor?.text] = link?.value
          })

          return {
            ...node,
            //размер иконки от 6 до 10
            nodeSize: Math.ceil((normalizedLinksSize / maxLinksSize) * 5) + 5,
            tetheredValues,
          }
        }),
      }

      return _data
    }

    return
  }, [dto])

  const smallerThan500 = useMediaPredicate('(max-width: 500px)')
  const smallerThan800 = useMediaPredicate('(max-width: 800px)')
  const biggerThan801 = useMediaPredicate('(min-width: 801px)')

  const [graphHeight, setGraphHeight] = useState(600)
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleHeightChange = useCallback(() => {
    const height = document.body.getBoundingClientRect().height

    if (smallerThan500) {
      setGraphHeight(height * 0.6 - 50)
    } else if (smallerThan800) {
      setGraphHeight(height * 0.6 - 50)
    } else if (biggerThan801) {
      setGraphHeight(height * 0.6 - 50)
    }
  }, [biggerThan801, smallerThan500, smallerThan800])

  useEffect(() => {
    handleHeightChange()
  }, [handleHeightChange])

  const [highlightNodes, setHighlightNodes] = useState(new Set())
  const [highlightLinks, setHighlightLinks] = useState(new Set())
  const [clickedNode, setClickedNode] = useState(null)
  const [_, setData] = useState<any>()
  const collapseRef = useRef<any>(null)
  const [activeKey, setActiveKey] = useState()
  const [activeRelations, setActiveRelations] = useState<string | undefined>()
  const graphRef = useRef<any>(null)

  const [photos, setPhotos] = useState([])
  const [images, setImages] = useState({})
  const [isParsing, setIsParsing] = useState(true)

  const [filteredData, setFilteredData] = useState({ nodes: [], links: [] })
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (graphData) {
      const images = graphData.nodes
        .filter((node: any) =>
          node.values.some((obj: any) =>
            Object.keys(obj).some((key) => key.includes('img_src_'))
          )
        )
        .map((node: any) => ({
          id: node.id,
          image:
            node.values?.[0]?.['img_src__0'] ||
            node.values?.[0]?.['img_src__1'] ||
            node.values?.[0]?.['img_src__2'] ||
            node.values?.[0]?.['img_src__3'],
        }))

      if (images.length) {
        setImages(images)
      } else {
        setIsParsing(false)
      }

      setData(graphData as any)
      setFilteredData(graphData)
    }
  }, [graphData])

  const handleFilteredData = useCallback((data: any, search: string) => {
    setFilteredData(data)
    setSearchValue(search)
  }, [])

  useEffect(() => {
    if (Object.keys(images).length && !photos.length && isParsing) {
      syncLoadAllImages(images, (loadedImages: any) => {
        setPhotos(loadedImages)
        setIsParsing(false)
      })
    }
  }, [photos.length, isParsing, images])

  const updateHighlight = useCallback(() => {
    setHighlightNodes(highlightNodes)
    setHighlightLinks(highlightLinks)
  }, [highlightNodes, highlightLinks])

  const handleNodeClick = useCallback(
    (node: any) => {
      highlightNodes.clear()
      highlightLinks.clear()
      setActiveRelations(undefined)

      if (node?.text === activeKey) {
        setActiveKey(undefined)
        setClickedNode(null)
        return updateHighlight()
      }

      if (node) {
        filteredData.nodes.sort((i: any) => (i.text === node.text ? -1 : 0))
        setActiveKey(node.text)
        collapseRef.current.scrollTo(0, 0)
        highlightNodes.add(node)
        node?.neighbors?.forEach((neighbor: any) =>
          highlightNodes.add(neighbor)
        )
        node?.links?.forEach((link: any) => highlightLinks.add(link))
      }
      setClickedNode(node || null)
      updateHighlight()
    },
    [highlightLinks, highlightNodes, updateHighlight, filteredData, activeKey]
  )

  const handleCollapseChange = useCallback(
    (key: any) => {
      setActiveRelations(undefined)

      if (activeKey === key) {
        setActiveKey(undefined)
        return handleNodeClick(undefined)
      }

      filteredData.nodes.sort((i: any) => (i.text === key ? -1 : 0))
      const nodeByKey = filteredData.nodes.find(
        (node: any) => node.text === key
      )

      setActiveKey(key)
      handleNodeClick(nodeByKey)
    },
    [filteredData, handleNodeClick, activeKey]
  )

  const changeGraphVisibility = useCallback(() => {
    if (isCollapsed) {
      handleHeightChange()
      setIsCollapsed(false)
    } else {
      setGraphHeight(0)
      setIsCollapsed(true)
    }
  }, [isCollapsed, handleHeightChange])

  const highlightText = (text: string) => {
    if (!searchValue) {
      return text
    }

    // eslint-disable-next-line security/detect-non-literal-regexp
    const parts = text.split(new RegExp(`(${searchValue})`, 'gi'))

    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === searchValue.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </>
    )
  }

  if (isParsing) {
    return <Loading />
  }

  return (
    <S.PageWrapper>
      <S.GraphWrapper graphsize={graphHeight} sx={{ boxShadow: 8 }}>
        <ForceGraph2D
          minZoom={3}
          ref={graphRef}
          graphData={filteredData}
          height={graphHeight}
          width={document.body.getBoundingClientRect().width}
          nodeAutoColorBy='group'
          nodeRelSize={3}
          backgroundColor='#fff'
          autoPauseRedraw={false}
          linkWidth={(link: any) => {
            if (!link?.value?.length) {
              return 1
            }

            const linkWidth = link.value.length

            const normalizedLinksWidth = Math.min(
              Math.max(linkWidth, 1),
              maxValuesInLink.current
            )

            const width =
              Math.ceil((normalizedLinksWidth / maxValuesInLink.current) * 5) /
                2 +
              0.5

            return highlightLinks.has(link) ? width : 1
          }}
          linkColor={(link) => (highlightLinks.has(link) ? '#5578EB' : '')}
          linkDirectionalParticles={1}
          linkDirectionalParticleWidth={(link: any) => {
            if (!highlightLinks.has(link) || !link?.value?.length) {
              return 0
            }

            const linkWidth = link.value.length

            const normalizedLinksWidth = Math.min(
              Math.max(linkWidth, 1),
              maxValuesInLink.current
            )

            const width =
              Math.ceil((normalizedLinksWidth / maxValuesInLink.current) * 5) /
                2 +
              2.5

            return width
          }}
          nodeCanvasObject={(node: any, ctx) => {
            const imgSrc = photos?.[node.id]
            ctx.beginPath()

            if (highlightNodes.has(node) && node === clickedNode) {
              const D = node.nodeSize
              const R = D / 2

              if (imgSrc) {
                ctx.rect(node.x - R, node.y - R, D, D)
              } else {
                ctx.arc(node.x, node.y, R, 0, 2 * Math.PI, false)
              }

              ctx.strokeStyle = '#5578EB'
              ctx.stroke()
            }

            const D = node.nodeSize
            const R = D / 2

            if (!imgSrc) {
              ctx.arc(node.x, node.y, R, 0, 2 * Math.PI, false)

              ctx.fillStyle = '#69B7FF'
              ctx.fill()
            }

            const img = new Image()
            if (imgSrc) {
              // img.src = `data:image/jpeg;base64,${photos[0]}`
            } else if (node.icon_type === 'external_sources') {
              img.src = external
            } else if (node.icon_type === 'dofin') {
              img.src = dofin
            } else if (node.icon_type === 'transport_profile') {
              img.src = transPhoto
            } else if (node.icon_type === 'efor') {
              img.src = efor
            } else if (node.icon_type === 'god_eye') {
              img.src = godEye
            } else {
              img.src = user
            }

            if (imgSrc) {
              ctx.drawImage(imgSrc, node.x - R, node.y - R, D, D)
            } else {
              ctx.drawImage(
                img,
                node.x - R * 1.5,
                node.y - R * 1.5,
                D * 1.5,
                D * 1.5
              )
            }
          }}
          onBackgroundClick={() => {
            highlightNodes.clear()
            highlightLinks.clear()
            setActiveKey(undefined)
            setActiveRelations(undefined)
            setClickedNode(null)
            updateHighlight()
          }}
          onNodeClick={handleNodeClick}
          cooldownTicks={0}
          onNodeDrag={(node) => {
            node.fx = node.x
            node.fy = node.y
          }}
          onNodeDragEnd={(node) => {
            node.fx = node.x
            node.fy = node.y
          }}
        />

        <Search initialData={graphData} setData={handleFilteredData} />

        <S.ChangeGraphVisBtn onClick={changeGraphVisibility}>
          <BlurOnIcon />
        </S.ChangeGraphVisBtn>
      </S.GraphWrapper>

      {filteredData ? (
        <S.AccordionsWrapper ref={collapseRef} graphsize={graphHeight}>
          {filteredData.nodes.map(
            ({ text, values, tetheredValues }: any, idx: number) => (
              <GraphAccordion
                expanded={activeKey === text}
                onChange={() => handleCollapseChange(text)}
                key={idx}
                isactive={activeKey === text ? 1 : 0}
              >
                <GraphAccordionSummary isactive={activeKey === text ? 1 : 0}>
                  {text.split(' node')[0]}
                </GraphAccordionSummary>

                <GraphAccordionDetails>
                  {tetheredValues && Object.keys(tetheredValues)?.length ? (
                    <GraphAccordion
                      sx={{ borderRadius: '20px', marginBottom: '24px' }}
                      expanded={activeRelations === `inside-${idx}`}
                      isactive={0}
                      onChange={() =>
                        setActiveRelations(
                          activeRelations === `inside-${idx}`
                            ? undefined
                            : `inside-${idx}`
                        )
                      }
                      key={idx}
                    >
                      <GraphAccordionSummary
                        isactive={0}
                        sx={{ bgcolor: '#FBFBFB' }}
                      >
                        <Stack direction='row'>
                          <SpokeIcon />

                          <Box ml={1}>{`Связей: ${
                            Object.keys(tetheredValues).length
                          }`}</Box>
                        </Stack>
                      </GraphAccordionSummary>

                      <GraphAccordionDetails>
                        {Object?.entries(tetheredValues)?.map(
                          ([key, vals]: any, linkIdx: any, arr) => (
                            <S.RelationInfo
                              sx={{
                                borderBottom:
                                  linkIdx !== arr.length - 1
                                    ? '1px solid lightgray'
                                    : '',
                              }}
                              key={`${Math.random()}-${linkIdx}`}
                            >
                              <S.RelName variant='caption'>
                                {`${key?.split(' node')[0]} (${vals.length})`}
                              </S.RelName>

                              {vals?.length ? (
                                <Stack
                                  flexDirection='row'
                                  mt='10px'
                                  flexWrap='wrap'
                                >
                                  {vals?.map((val: string, valIdx: number) => (
                                    <S.RelValue
                                      variant='body2'
                                      key={`${Math.random()}-${valIdx}`}
                                    >
                                      {highlightText(String(val))}
                                    </S.RelValue>
                                  ))}
                                </Stack>
                              ) : null}
                            </S.RelationInfo>
                          )
                        )}
                      </GraphAccordionDetails>
                    </GraphAccordion>
                  ) : null}

                  {values.map((nodeObjData: any, nodeIdx: any, arr: any) => (
                    <Stack key={nodeIdx}>
                      <S.InnerWrapper
                        sx={{
                          borderBottom:
                            arr.length !== nodeIdx + 1
                              ? '1px solid lightgray'
                              : undefined,
                        }}
                      >
                        {Object.entries(nodeObjData).map(([key, val]) => (
                          <S.Record key={key}>
                            <Box component='b'>{key}: </Box>
                            {val && key.includes('img_src__') ? (
                              <ImageTag width={70} src={val as string} />
                            ) : (
                              <Box component='span'>
                                {highlightText(String(val))}
                              </Box>
                            )}
                          </S.Record>
                        ))}
                      </S.InnerWrapper>
                    </Stack>
                  ))}
                </GraphAccordionDetails>
              </GraphAccordion>
            )
          )}
        </S.AccordionsWrapper>
      ) : null}
    </S.PageWrapper>
  )
})
