export const getTgDate = (str: string) => {
  //2023-02-07T14:24:57.330000
  const [date, time] = str.split('T')
  const [dd, mm, yyyy] = date.split('-')
  const [h, min, sec] = time.split(':')
  return `${dd}.${mm}.${yyyy} - ${h}:${min}:${sec}`
}

export const renderVkVal = (key: string, val: string | number) => {
  if (key === 'date_uploaded') {
    const [date, time] = (val as string).split(' ')
    return `${date} - ${time}`
  }

  return val
}

export const renderVal = (val: string | string[]) => {
  if (Array.isArray(val)) {
    return val.join(', ')
  }

  return val
}

export const translateKey = (key: string) => {
  if (key === 'reposts') {
    return 'Репостов'
  } else if (key === 'likes') {
    return 'Лайков'
  } else if (key === 'date_uploaded') {
    return 'Дата'
  } else if (key === 'text') {
    return 'Текст'
  }

  return key
}
