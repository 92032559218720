import { observer } from 'mobx-react-lite'
import { Context } from '../../../index'
import { useCallback, useContext, useMemo } from 'react'
import { CircularProgress, Stack, Typography } from '@mui/material'
import {
  DistrictsHeader,
  HexagonInfo,
  DistrictSubheader,
  SidebarWrapper,
} from './Districts.styled'
import React from 'react'
import districtIcon from 'assets/districtBlackAndWhite.svg'
import hexagons from 'assets/hexagons.svg'
import { Button } from 'components/common/Button'

const transformDate = (str: string) => {
  const [date, time] = str.split('T')
  const [YYYY, MM, DD] = date.split('-')
  const [hour, min] = time.split(':')

  return `${DD}.${MM}.${YYYY} в ${hour}:${min}`
}

export const Districts = observer(() => {
  const { rootStore } = useContext(Context)
  const { radarStore } = rootStore
  const {
    isHexagonDataLoading,
    hexagonArrangement,
    isHexagonDataRequested,
    activeView,
    hexagonCommunities,
    hexagonUsers,
    setSelectedPoint,
  } = radarStore

  const entitiesAmount = useMemo(
    () =>
      activeView === 'communities'
        ? hexagonCommunities?.length
        : hexagonUsers?.length,
    [activeView, hexagonUsers, hexagonCommunities]
  )

  const handleShowOnMap = useCallback(
    (coords: number[]) => {
      setSelectedPoint(coords)
    },
    [setSelectedPoint]
  )

  if (isHexagonDataLoading) {
    return (
      <Stack
        width='100%'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        <CircularProgress sx={{ color: '#5578EB' }} />
      </Stack>
    )
  }

  if (!isHexagonDataRequested) {
    return (
      <Stack
        width='100%'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        <Typography variant='body2'>
          Выберите район на карте, чтобы увидеть его детальную информацию
        </Typography>
      </Stack>
    )
  }

  if (
    (isHexagonDataRequested &&
      activeView === 'communities' &&
      !hexagonCommunities?.length) ||
    (activeView === 'persons' && !hexagonUsers?.length)
  ) {
    return (
      <Stack
        width='100%'
        justifyContent='center'
        alignItems='center'
        height='100%'
      >
        <Typography variant='body2'>
          {activeView === 'communities' &&
            !hexagonCommunities?.length &&
            'В этой точке не найдено информации о сообществах'}
          {activeView === 'persons' &&
            !hexagonUsers?.length &&
            'В этой точке не найдено информации о пользователях'}
        </Typography>
      </Stack>
    )
  }

  return (
    <SidebarWrapper>
      {(activeView === 'communities' && hexagonCommunities?.length) ||
      (activeView === 'persons' && hexagonUsers?.length) ? (
        <DistrictsHeader>
          {hexagonArrangement?.districts?.length ? (
            <DistrictSubheader>
              <Stack direction='row' alignItems='center' spacing={0.5}>
                <img src={hexagons} height={20} width={20} alt='hexagonsIcon' />
                <Typography fontWeight='bold'>Округа:</Typography>
              </Stack>

              <Stack direction='row' flexWrap='wrap' spacing={1}>
                {hexagonArrangement?.districts?.map((district, districtIdx) => (
                  <Typography component='span' key={districtIdx}>
                    {`${district} ${
                      districtIdx !== hexagonArrangement?.districts?.length - 1
                        ? ','
                        : ''
                    }`}
                  </Typography>
                ))}
              </Stack>
            </DistrictSubheader>
          ) : null}

          {hexagonArrangement?.regions?.length ? (
            <DistrictSubheader>
              <Stack direction='row' alignItems='center' spacing={0.5}>
                <img
                  src={districtIcon}
                  height={24}
                  width={24}
                  alt='districtIcon'
                />
                <Typography fontWeight='bold'>Районы:</Typography>
              </Stack>

              {hexagonArrangement?.regions?.map((region, regionIdx) => (
                <Typography key={regionIdx}>
                  {`${region} ${
                    regionIdx !== hexagonArrangement?.regions?.length - 1
                      ? ' ,'
                      : ''
                  }`}
                </Typography>
              ))}
            </DistrictSubheader>
          ) : null}

          <Stack direction='row' spacing={1}>
            <Typography fontWeight='bold' fontSize='14px'>
              {`Количество ${
                activeView === 'communities' ? 'сообществ' : 'пользователей'
              }:`}
            </Typography>

            <Typography fontSize='14px'>{entitiesAmount}</Typography>
          </Stack>
        </DistrictsHeader>
      ) : null}

      <Stack spacing={1} p={2} sx={{ overflowY: 'auto' }}>
        {activeView === 'communities'
          ? hexagonCommunities.map(
              (
                {
                  about,
                  detection_count,
                  location,
                  participants_count,
                  title,
                  username,
                },
                commIdx
              ) => (
                <HexagonInfo key={`${title}_${commIdx}`} spacing={1}>
                  {title ? (
                    username ? (
                      <Typography
                        fontSize='16px'
                        color='#5578EB'
                        fontWeight='bold'
                        component='a'
                        target='_blank'
                        rel='noreferrer'
                        href={`https://t.me/${username}`}
                        textAlign='start'
                      >
                        {title}
                      </Typography>
                    ) : (
                      <Typography
                        fontSize='16px'
                        color='#5578EB'
                        textAlign='start'
                      >
                        {title}
                      </Typography>
                    )
                  ) : null}

                  {participants_count ? (
                    <Stack direction='row' spacing={1}>
                      <Typography variant='body1' fontWeight='bold'>
                        Подписчиков:
                      </Typography>

                      <Typography variant='body1'>
                        {participants_count}
                      </Typography>
                    </Stack>
                  ) : null}

                  {about ? (
                    <Stack alignItems='start' spacing={1}>
                      <Typography variant='body1' fontWeight='bold'>
                        Описание:
                      </Typography>

                      <Typography
                        variant='body1'
                        textAlign='start'
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {about}
                      </Typography>
                    </Stack>
                  ) : null}

                  {detection_count ? (
                    <Stack direction='row' spacing={1}>
                      <Typography variant='body1' fontWeight='bold'>
                        Количество детекций:
                      </Typography>

                      <Typography variant='body1'>{detection_count}</Typography>
                    </Stack>
                  ) : null}

                  {location?.length ? (
                    <Stack direction='row' spacing={1}>
                      <Button
                        additionalSx={{
                          color: '#5578EB',
                          fontSize: '16px',
                          borderRadius: '8px',
                          p: '4px',
                        }}
                        variant='outlined'
                        onClick={() => handleShowOnMap(location)}
                      >
                        Показать на карте
                      </Button>
                    </Stack>
                  ) : null}
                </HexagonInfo>
              )
            )
          : null}

        {activeView === 'persons'
          ? hexagonUsers.map(
              (
                {
                  detection_count,
                  first_name,
                  last_name,
                  online,
                  phone,
                  username,
                },
                userIdx
              ) => (
                <HexagonInfo key={userIdx} spacing={1}>
                  {first_name || last_name ? (
                    <Stack spacing={1} alignItems='start'>
                      <Typography variant='body1' fontWeight='bold'>
                        Имя пользователя:
                      </Typography>

                      <Typography
                        fontSize='16px'
                        sx={{ wordBreak: 'break-all' }}
                      >
                        {`${first_name ? first_name : ''} ${
                          last_name ? last_name : ''
                        }`}
                      </Typography>
                    </Stack>
                  ) : null}

                  {phone ? (
                    <Stack direction='row' spacing={1}>
                      <Typography variant='body1' fontWeight='bold'>
                        Телефон:
                      </Typography>

                      <Typography variant='body1'>{phone}</Typography>
                    </Stack>
                  ) : null}

                  {online ? (
                    <Stack alignItems='start' spacing={1}>
                      <Typography variant='body1' fontWeight='bold'>
                        Был(а) онлайн:
                      </Typography>

                      <Typography variant='body1' textAlign='start'>
                        {transformDate(online)}
                      </Typography>
                    </Stack>
                  ) : null}

                  {detection_count ? (
                    <Stack direction='row' spacing={1}>
                      <Typography variant='body1' fontWeight='bold'>
                        Количество детекций:
                      </Typography>

                      <Typography variant='body1'>{detection_count}</Typography>
                    </Stack>
                  ) : null}

                  {username ? (
                    <Stack direction='row' spacing={1}>
                      <Typography variant='body1' fontWeight='bold'>
                        Никнейм:
                      </Typography>

                      <Typography
                        fontSize='16px'
                        color='#5578EB'
                        fontWeight='bold'
                        component='a'
                        target='_blank'
                        rel='noreferrer'
                        href={`https://t.me/${username}`}
                        textAlign='start'
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {username}
                      </Typography>
                    </Stack>
                  ) : null}
                </HexagonInfo>
              )
            )
          : null}
      </Stack>
    </SidebarWrapper>
  )
})
