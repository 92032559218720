import { Stack, styled } from '@mui/material'

export const PopupContainer = styled(Stack)({
  fontStyle: 'italic',
  color: '#40a9ff',
  maxHeight: '250px',
  overflowY: 'auto',
  width: '240px',
  padding: 10,
})
