import React from 'react'
import { AccordionProps, styled } from '@mui/material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const GraphAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))<{ isactive: number }>(({ isactive, theme }) => ({
  border: isactive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid lightgray',
  borderRadius: 30,
  '&:before': {
    display: 'none',
  },
  marginBottom: '4px',

  '&.Mui-expanded:last-of-type': {
    marginBottom: '4px',
  },
}))

export const GraphAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ bgcolor: '#fff', borderRadius: 30 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 18 }} />}
    {...props}
  />
))<{ isactive: number }>(({ isactive, theme }) => ({
  height: 64,
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '24px',
  color: isactive ? theme.palette.primary.main : theme.palette.common.black,

  '.MuiAccordionSummary-content': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}))

export const GraphAccordionDetails = styled(MuiAccordionDetails)({})
