import { CheckboxData } from 'store/radarStore/radar.types'
import moGeo from 'utils/moGeo.json'

const districtsInRegions: CheckboxData = {}

moGeo.forEach(([label, districtData]: any) => {
  const regionName: string = districtData.properties.ABBREV_AO

  if (!districtsInRegions[regionName]) {
    return (districtsInRegions[regionName] = [label])
  }

  return districtsInRegions[regionName].push(label)
})

export const sortedDistrictsInRegions = Object.fromEntries(
  Object.entries(districtsInRegions).map(([key, val]) => [
    key,
    val.sort((a, b) => (a < b ? -1 : 0)),
  ])
)

export const datepickerStyles = {
  '.react-datepicker-wrapper': {
    width: '100%',
    height: '100%',
    ':focus-visible': {
      outline: 'none',
    },
  },
  '.react-datepicker__input-container': {
    width: '100%',
    height: '100%',
    ':focus-visible': {
      outline: 'none',
    },
  },
  input: {
    width: '100%',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    px: '5px',
    ':focus-visible': {
      outline: '1px solid #5578EB',
    },
    ':focus': {
      border: '1px solid #5578EB',
    },
    ':hover': {
      border: '1px solid black',
    },
  },
}

export const inputProps = {
  height: '40px',
  bgcolor: '#fff',
  borderRadius: '8px',
}
