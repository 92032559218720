import JsPDF from 'jspdf'
import '../../assets/Roboto-Bold-normal'
import '../../assets/Roboto-Regular-normal'
import autoTable from 'jspdf-autotable'
import { Button, Image, Table } from 'antd'
import { useRef, useState, useEffect, useContext, useMemo } from 'react'
import s from './TransportInfo.module.scss'
import { useMediaPredicate } from 'react-media-hook'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Context } from '../../index'
import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import './last-route-map.css'
import { Close } from '@mui/icons-material'
import { SpeedSlider } from './Slider'

const columns = [
  {
    title: 'Фото',
    dataIndex: 'grz',
    align: 'center',
    width: '5%',
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    align: 'left',
    width: '15%',
  },
  // {
  //   title: 'Фото',
  //   dataIndex: 'photo',
  //   align: 'center',
  //   width: '10%',
  // },
]

const transformDate = (objDate) => {
  const [date, time] = objDate.split('T')
  const [YYYY, MM, DD] = date.split('-')
  return `${DD}.${MM}.${YYYY} - ${time}`
}

export const TransportInfo = observer(() => {
  const { rootStore } = useContext(Context)
  const tableStore = rootStore.tableStore
  const { tableData, isAuth, rerenderCount } = tableStore

  const params = useParams()?.data

  useEffect(() => {
    if (!isAuth) {
      tableStore.authUser()
    }
  }, [tableStore, isAuth])

  useEffect(() => {
    if (params && isAuth) {
      tableStore.fetchTableData(params.split('&'))
    }
  }, [tableStore, params, isAuth])

  const getColDescr = (obj) => (
    <>
      {obj.grz && (
        <span className={s.field}>
          <b>ГРЗ: </b>
          <span>{obj.grz}</span>
          <br />
        </span>
      )}
      {obj.check_dttm && (
        <span className={s.field}>
          <b>Дата: </b>
          <span>{transformDate(obj.check_dttm)}</span>
          <br />
        </span>
      )}
      {obj.camera_address && (
        <span className={s.field}>
          <b>Адрес: </b>
          <span>{obj.camera_address}</span>
          <br />
        </span>
      )}
      {obj.check_id && (
        <span className={s.field}>
          <b>Камера: </b>
          <span>{obj.check_id}</span>
        </span>
      )}
    </>
  )

  const data = tableData?.data?.map((obj, idx) => {
    const grzPhoto = obj?.photo?.find((photo) => photo.type === -1)?.photo
    const autoPhoto = obj?.photo?.find((photo) => photo.type === 0)?.photo

    return {
      grz: (
        <div className={s.grzWrapper}>
          {grzPhoto || autoPhoto ? (
            <>
              <Image
                className={s.image}
                preview={{ mask: false }}
                height={50}
                width={100}
                src={grzPhoto}
              />

              <Image
                className={s.image}
                style={{ paddingTop: 16 }}
                preview={{ mask: false }}
                height={100}
                width={100}
                src={autoPhoto ?? grzPhoto ?? obj?.grz}
              />
            </>
          ) : (
            <b>{obj?.grz}</b>
          )}
        </div>
      ),
      dataIndex: obj.dataIndex,
      description: getColDescr(obj),
      key: `title_${idx}`,
      photo: (
        <Image
          className={s.image}
          preview={{ mask: false }}
          height={100}
          width={100}
          src={autoPhoto ?? grzPhoto ?? obj?.grz}
        />
      ),
    }
  })

  const smallerThan500 = useMediaPredicate('(max-width: 500px)')
  const smallerThan800 = useMediaPredicate('(max-width: 800px)')
  const biggerThan801 = useMediaPredicate('(min-width: 801px)')

  const [paginationAmount, setPaginationAmount] = useState(3)

  const lastTripPoints = useMemo(
    () =>
      tableData?.data
        ?.filter(
          (el) =>
            el?.trip_type === 'camera_check' &&
            el?.latitude !== '0.0' &&
            el?.longitude !== '0.0'
        )
        .reverse(),
    [tableData?.data]
  )

  useEffect(() => {
    if (!lastTripPoints) {
      if (smallerThan500) {
        setPaginationAmount(3)
      } else if (smallerThan800) {
        setPaginationAmount(3)
      } else if (biggerThan801) {
        setPaginationAmount(3)
      }
    }
  }, [lastTripPoints, smallerThan500, smallerThan800, biggerThan801])

  const tableRef = useRef(null)

  const generatePDF = () => {
    const doc = new JsPDF('p', 'pt', 'a4', true)

    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular-normal', 'normal')
    doc.addFont('Roboto-Bold-normal.ttf', 'Roboto-Bold-normal', 'normal')

    doc.setFont('Roboto-Regular-normal')
    doc.setFontSize(12)

    autoTable(doc, {
      html: tableRef.current.firstChild.firstChild.firstChild.firstChild
        .firstChild.firstChild,
      font: 'Roboto-Regular-normal',
      pageBreak: 'auto',
      tableWidth: 'wrap',
      headStyles: {
        font: 'Roboto-Regular-normal',
        halign: 'center',
        valign: 'middle',
      },
      bodyStyles: {
        minCellHeight: 220,
        font: 'Roboto-Regular-normal',
        pageBreak: 'auto',
        halign: 'center',
        valign: 'top',
        cellPadding: 25,
        fontStyle: 'bolditalic',
      },
      columnStyles: {
        0: {
          minCellWidth: 170,
        },
        1: {
          halign: 'left',
          valign: 'middle',
          cellWidth: 340,
        },
      },
      didDrawCell: (data) => {
        if (data.column.index === 0 && data.cell.section === 'body') {
          const td = data.cell.raw
          const img1 = td.getElementsByTagName('img')[0]
          const img2 = td.getElementsByTagName('img')[1]
          if (img1) {
            doc.addImage(img1.src, data.cell.x + 15, data.cell.y + 45, 130, 50)
          }
          if (img2) {
            doc.addImage(
              img2.src,
              data.cell.x + 15,
              data.cell.y + 100,
              130,
              135
            )
          }
        }
      },
    })

    doc.save(`${tableData?.data?.[0].grz}.pdf`)
  }

  useEffect(() => {
    if (lastTripPoints?.length) {
      import('./last-route-map.js')
        .then((module) => module.initMap(lastTripPoints))
        .catch((error) => {
          console.error('Error loading script:', error)
        })
    }
  }, [lastTripPoints])

  return (
    <div
      className={`${s.wrapper} ${rootStore.loaderStore.isLoading && s.loading}`}
    >
      <div className={s.header}>
        <Typography variant='h5' my={2}>
          Фотофиксации
        </Typography>

        <Button className={s.saveButton} onClick={generatePDF}>
          Скачать PDF
        </Button>
      </div>

      <Table
        ref={tableRef}
        style={{ display: 'none' }}
        columnWidth={200}
        columns={columns}
        dataSource={data}
        size='small'
        pagination={{ pageSize: 40 }}
      />
      <Table
        className={s.transportTable}
        columnWidth={200}
        columns={columns}
        dataSource={data}
        size='small'
        pagination={{ pageSize: paginationAmount, position: ['bottomCenter'] }}
      />

      {/* {lastTripPoints?.length ? <LastRouteMap data={lastTripPoints} /> : null} */}

      {lastTripPoints?.length ? (
        <>
          <Stack
            justifyContent='center'
            sx={{ width: '100%', alignItems: 'center', margin: '0 auto' }}
          >
            <Typography variant='body2' color='common.black'>
              Скорость движения
            </Typography>

            <SpeedSlider />
          </Stack>

          <Stack
            sx={{
              width: '100%',
              maxWidth: 900,
              alignItems: 'center',
              margin: '0 auto',
              mb: 3,
            }}
          >
            <div id='map' className={`map mapCount-${rerenderCount}`}></div>
          </Stack>

          <div id='popup' className='ol-popup'>
            <IconButton id='popup-closer' className='ol-popup-closer'>
              <Close />
            </IconButton>
            <div id='popup-content'></div>
          </div>
        </>
      ) : null}
    </div>
  )
})
