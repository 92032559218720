import { observer } from 'mobx-react-lite'
import React from 'react'
import { LinkedUserChannel } from 'store/profileStore/profileStore.types'
import { TextRecord } from '../TextRecord'

export const UserChannels = observer<LinkedUserChannel>(
  ({ title, channel_username }) => {
    return (
      <>
        {title ? <TextRecord name='title' value={title} /> : null}

        {channel_username ? (
          <TextRecord
            name='channel_username'
            value={
              <a
                href={`https://t.me/${channel_username}`}
                target='_blank'
                rel='noreferrer'
              >
                {channel_username}
              </a>
            }
          />
        ) : null}
      </>
    )
  }
)
