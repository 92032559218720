import { Stack, Typography } from '@mui/material'
import React from 'react'

export const InfoRecord = ({ name, value }: { name: string; value: any }) => {
  return (
    <Stack
      justifyContent='space-between'
      sx={{
        mt: 1,
        width: '100%',
        maxWidth: '900px',
      }}
    >
      <Stack
        sx={{ width: '100%', p: 1 }}
        direction='row'
        justifyContent='space-between'
      >
        <Typography
          width='50%'
          textAlign='start'
          color='common.black'
          variant='body1'
          sx={{ wordBreak: 'break-word' }}
        >
          {name}
        </Typography>
        <Typography
          width='50%'
          color='text.disabled'
          variant='body1'
          textAlign='end'
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  )
}
