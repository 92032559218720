import { Box, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { vkKeyTranslate, getStringFromArr } from './utils'
import { InfoRecord } from './VkUserInfoRecord'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'

interface RelativeProps {
  name?: string
  type?: string
  id?: number | string
  birth_date?: string
}

interface PartnerProps {
  first_name?: string
  last_name?: string
  id?: string | number
  type?: string
}

const LinkToRelative = observer<RelativeProps>((props) => {
  const { type, name, id, birth_date } = props

  if (type && name && id) {
    return (
      <>
        <InfoRecord name={vkKeyTranslate.relativeType} value={type} />

        {birth_date ? (
          <InfoRecord name={vkKeyTranslate.bdate} value={birth_date} />
        ) : null}

        <InfoRecord
          name={name}
          value={
            <a
              href={`https://vk.com/${typeof id === 'number' ? `id${id}` : id}`}
              target='_blank'
              rel='noreferrer'
            >
              Профиль
            </a>
          }
        />
      </>
    )
  }

  if ((name || type) && id) {
    const _name = name ?? type

    if (!_name) {
      return null
    }

    return (
      <>
        {birth_date ? (
          <InfoRecord name={vkKeyTranslate.bdate} value={birth_date} />
        ) : null}

        <InfoRecord
          name={_name}
          value={
            <a
              href={`https://vk.com/${typeof id === 'number' ? `id${id}` : id}`}
              target='_blank'
              rel='noreferrer'
            >
              Профиль
            </a>
          }
        />
      </>
    )
  }

  return (
    <>
      {name ? <InfoRecord name={vkKeyTranslate.name} value={name} /> : null}

      {type ? (
        <InfoRecord name={vkKeyTranslate.relativeType} value={type} />
      ) : null}

      {id ? (
        <InfoRecord
          name={vkKeyTranslate.profile_link}
          value={
            <a
              href={`https://vk.com/${
                typeof id === 'number' ? `id${Math.abs(id)}` : id
              }`}
              target='_blank'
              rel='noreferrer'
            >
              Профиль
            </a>
          }
        />
      ) : null}
    </>
  )
})

const Partner = observer<PartnerProps>((props) => {
  const { first_name, last_name, type, id } = props

  const partnerName = `${first_name ?? ''} ${last_name ?? ''}`

  if (partnerName.trim() && type && id) {
    return (
      <Stack>
        <Typography variant='body1' fontWeight={700} color='common.black'>
          В отношениях с
        </Typography>

        <InfoRecord name={vkKeyTranslate.relativeType} value={type} />
        <InfoRecord
          name={partnerName}
          value={
            <a
              href={`https://vk.com/${typeof id === 'number' ? `id${id}` : id}`}
              target='_blank'
              rel='noreferrer'
            >
              Профиль
            </a>
          }
        />
      </Stack>
    )
  }

  if ((partnerName.trim() || type) && id) {
    const data = partnerName.trim() ?? type

    return (
      <Stack>
        <Typography variant='body1' fontWeight={700} color='common.black'>
          В отношениях с
        </Typography>

        <InfoRecord
          name={data}
          value={
            <a
              href={`https://vk.com/${typeof id === 'number' ? `id${id}` : id}`}
              target='_blank'
              rel='noreferrer'
            >
              Профиль
            </a>
          }
        />
      </Stack>
    )
  }

  return (
    <Stack>
      <Typography variant='body1' fontWeight={700} color='common.black'>
        В отношениях с
      </Typography>

      {partnerName.trim() ? (
        <InfoRecord name={vkKeyTranslate.name} value={partnerName} />
      ) : null}

      {type ? (
        <InfoRecord name={vkKeyTranslate.relativeType} value={type} />
      ) : null}

      {id ? (
        <InfoRecord
          name={vkKeyTranslate.profile_link}
          value={
            <a
              href={`https://vk.com/${
                typeof id === 'number' ? `id${Math.abs(id)}` : id
              }`}
              target='_blank'
              rel='noreferrer'
            >
              Профиль
            </a>
          }
        />
      ) : null}
    </Stack>
  )
})

export const UserInfoInner = observer(
  ({ data }: { data: Record<keyof typeof vkKeyTranslate, any> }) => {
    const [expanded, setExpanded] = useState<string | false>(false)

    const handleChange =
      (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
      }

    return (
      <>
        <Accordion
          expanded={expanded === 'generalInfo'}
          onChange={handleChange('generalInfo')}
        >
          <AccordionSummary>
            <Stack direction='row'>
              <Box
                sx={{
                  mr: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SubheaderIcon name='PermContactCalendarOutlinedIcon' />
              </Box>

              <Typography variant='body1' color='common.black'>
                {vkKeyTranslate.generalInfo}
              </Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            {data?.photo_max_orig ? (
              <img
                src={data.photo_max_orig}
                style={{
                  width: '100%',
                  height: '100%',
                  maxWidth: 350,
                  maxHeight: 350,
                }}
                alt='vkPhoto'
              />
            ) : null}

            {data?.first_name || data?.last_name ? (
              <InfoRecord
                name={vkKeyTranslate.first_name}
                value={`${data?.last_name ?? data.last_name} ${
                  data?.first_name ?? data.first_name
                }`}
              />
            ) : null}

            {data?.profile_link ? (
              <InfoRecord
                name={vkKeyTranslate.profile_link}
                value={
                  <a href={data.profile_link} target='_blank' rel='noreferrer'>
                    Профиль
                  </a>
                }
              />
            ) : null}

            {data?.city ? (
              <InfoRecord name={vkKeyTranslate.city} value={data.city} />
            ) : null}

            {data?.home_town ? (
              <InfoRecord
                name={vkKeyTranslate.home_town}
                value={data.home_town}
              />
            ) : null}

            {data?.bdate ? (
              <InfoRecord name={vkKeyTranslate.bdate} value={data.bdate} />
            ) : null}

            {data?.sex ? (
              <InfoRecord name={vkKeyTranslate.sex} value={data.sex} />
            ) : null}

            {data?.mobile_phone ? (
              <InfoRecord
                name={vkKeyTranslate.mobile_phone}
                value={data.mobile_phone}
              />
            ) : null}

            {data?.home_phone ? (
              <InfoRecord
                name={vkKeyTranslate.home_phone}
                value={data.home_phone}
              />
            ) : null}

            {data.last_seen?.platform ? (
              <InfoRecord
                name={vkKeyTranslate.lastSeenPlatform}
                value={data.last_seen.platform}
              />
            ) : null}

            {data.last_seen?.time ? (
              <InfoRecord
                name={vkKeyTranslate.last_seen}
                value={data.last_seen.time}
              />
            ) : null}

            {data?.relation ? (
              <InfoRecord
                name={vkKeyTranslate.relation}
                value={data.relation}
              />
            ) : null}

            {data.skype ? (
              <InfoRecord name={vkKeyTranslate.skype} value={data.skype} />
            ) : null}

            {/* PERSONAL */}
            {data?.personal && Object.keys(data.personal)?.length ? (
              <>
                {data.personal?.langs?.length ? (
                  <InfoRecord
                    name={vkKeyTranslate.langs}
                    value={getStringFromArr(data.personal.langs)}
                  />
                ) : null}

                {data.personal?.alcohol ? (
                  <InfoRecord
                    name={vkKeyTranslate.alcohol}
                    value={data.personal.alcohol}
                  />
                ) : null}

                {data.personal?.inspired_by ? (
                  <InfoRecord
                    name={vkKeyTranslate.inspired_by}
                    value={data.personal.inspired_by}
                  />
                ) : null}

                {data.personal?.life_main ? (
                  <InfoRecord
                    name={vkKeyTranslate.life_main}
                    value={data.personal.life_main}
                  />
                ) : null}

                {data.personal?.people_main ? (
                  <InfoRecord
                    name={vkKeyTranslate.people_main}
                    value={data.personal.people_main}
                  />
                ) : null}

                {data.personal?.political ? (
                  <InfoRecord
                    name={vkKeyTranslate.political}
                    value={data.personal.political}
                  />
                ) : null}

                {data.personal?.religion ? (
                  <InfoRecord
                    name={vkKeyTranslate.religion}
                    value={data.personal.religion}
                  />
                ) : null}

                {data.personal?.smoking ? (
                  <InfoRecord
                    name={vkKeyTranslate.smoking}
                    value={data.personal.smoking}
                  />
                ) : null}
              </>
            ) : null}

            {data?.status ? (
              <InfoRecord name={vkKeyTranslate.status} value={data.status} />
            ) : null}

            {typeof data?.verified === 'number' ? (
              <InfoRecord
                name={vkKeyTranslate.verified}
                value={data.relation === 0 ? 'Нет' : 'Да'}
              />
            ) : null}
          </AccordionDetails>
        </Accordion>

        {data?.career?.length ? (
          <Accordion
            expanded={expanded === 'career'}
            onChange={handleChange('career')}
          >
            <AccordionSummary>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SubheaderIcon name='AttachMoneyOutlinedIcon' />
                </Box>

                <Typography variant='body1' color='common.black'>
                  {vkKeyTranslate.career}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {data.career.map((career: any, idx: number) => (
                <Stack key={Math.random()}>
                  <Typography
                    p={1}
                    variant='body2'
                    color='common.black'
                    fontWeight={700}
                  >
                    {career.company ?? `Место работы № ${idx + 1}`}
                  </Typography>

                  {career.county_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.country}
                      value={career.county_name}
                    />
                  ) : null}

                  {career.city_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.city}
                      value={career.city_name}
                    />
                  ) : null}

                  {career.position ? (
                    <InfoRecord
                      name={vkKeyTranslate.position}
                      value={career.position}
                    />
                  ) : null}
                  {career.from ? (
                    <InfoRecord
                      name={vkKeyTranslate.from}
                      value={career.from}
                    />
                  ) : null}
                  {career.until ? (
                    <InfoRecord
                      name={vkKeyTranslate.until}
                      value={career.until}
                    />
                  ) : null}
                </Stack>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}

        {data?.counters && Object.keys(data.counters)?.length ? (
          <Accordion
            expanded={expanded === 'counters'}
            onChange={handleChange('counters')}
          >
            <AccordionSummary>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SubheaderIcon name='AssessmentOutlinedIcon' />
                </Box>

                <Typography variant='body1' color='common.black'>
                  {vkKeyTranslate.counters}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {Object.entries(data.counters)
                .filter(([_, val]) => !!val)
                .map(([counterKey, counterVal]: any, idx: number) => (
                  <InfoRecord
                    key={`${counterKey}_${counterVal}_${idx}`}
                    name={
                      vkKeyTranslate[
                        counterKey as keyof typeof vkKeyTranslate
                      ] ?? counterKey
                    }
                    value={counterVal}
                  />
                ))}
            </AccordionDetails>
          </Accordion>
        ) : null}

        {data?.relatives?.length ||
        (data?.relation_partner &&
          Object.values(data.relation_partner).some((v) => !!v)) ? (
          <Accordion
            expanded={expanded === 'relatives'}
            onChange={handleChange('relatives')}
          >
            <AccordionSummary>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SubheaderIcon name='Diversity3OutlinedIcon' />
                </Box>

                <Typography variant='body1' color='common.black'>
                  {vkKeyTranslate.relatives}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {data.relatives.map(
                (
                  { id, name, type, birth_date }: RelativeProps,
                  idx: number
                ) => (
                  <Stack key={idx}>
                    <LinkToRelative
                      name={name}
                      id={id}
                      birth_date={birth_date}
                      type={type}
                    />
                  </Stack>
                )
              )}

              {data?.relation_partner &&
              Object.values(data.relation_partner).some((v) => !!v) ? (
                <Partner
                  id={data?.relation_partner?.id}
                  first_name={data?.relation_partner?.first_name}
                  last_name={data?.relation_partner?.last_name}
                  type={data?.relation_partner?.type}
                />
              ) : null}
            </AccordionDetails>
          </Accordion>
        ) : null}

        {data?.occupation &&
        Object.keys(data.occupation)?.length &&
        (data.occupation?.type ||
          data.occupation?.name ||
          data.occupation?.graduate_year) ? (
          <Accordion
            expanded={expanded === 'occupation'}
            onChange={handleChange('occupation')}
          >
            <AccordionSummary>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SubheaderIcon name='HomeRepairServiceOutlinedIcon' />
                </Box>

                <Typography variant='body1' color='common.black'>
                  {vkKeyTranslate.occupation}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {data?.occupation?.country_name ? (
                <InfoRecord
                  name={vkKeyTranslate.country}
                  value={data.occupation.country_name}
                />
              ) : null}
              {data?.occupation?.city_name ? (
                <InfoRecord
                  name={vkKeyTranslate.city}
                  value={data.occupation.city_name}
                />
              ) : null}
              {data?.occupation?.type ? (
                <InfoRecord
                  name={vkKeyTranslate.occupationType}
                  value={data.occupation.type}
                />
              ) : null}

              {data?.occupation?.name ? (
                <InfoRecord
                  name={vkKeyTranslate.occupationName}
                  value={data.occupation.name}
                />
              ) : null}

              {data?.occupation?.graduate_year ? (
                <InfoRecord
                  name={vkKeyTranslate.graduate_year}
                  value={data.occupation.graduate_year}
                />
              ) : null}
            </AccordionDetails>
          </Accordion>
        ) : null}

        {data?.universities?.length ? (
          <Accordion
            expanded={expanded === 'universities'}
            onChange={handleChange('universities')}
          >
            <AccordionSummary>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SubheaderIcon name='SchoolOutlinedIcon' />
                </Box>

                <Typography variant='body1' color='common.black'>
                  {vkKeyTranslate.universities}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {data.universities.map((univ: any, idx: number) => (
                <Stack key={idx}>
                  <Typography
                    p={1}
                    variant='body2'
                    color='common.black'
                    fontWeight={700}
                  >
                    {univ.name ?? `Место учебы № ${idx + 1}`}
                  </Typography>

                  {univ?.country_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.country}
                      value={univ.country_name}
                    />
                  ) : null}
                  {univ?.city_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.city}
                      value={univ.city_name}
                    />
                  ) : null}

                  {univ?.chair ? (
                    <InfoRecord
                      name={vkKeyTranslate.univChair}
                      value={univ.chair}
                    />
                  ) : null}

                  {univ?.chair_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.univChair_name}
                      value={univ.chair_name}
                    />
                  ) : null}

                  {univ?.education_form ? (
                    <InfoRecord
                      name={vkKeyTranslate.univEducation_form}
                      value={univ.education_form}
                    />
                  ) : null}

                  {univ?.education_status ? (
                    <InfoRecord
                      name={vkKeyTranslate.univEducation_status}
                      value={univ.education_status}
                    />
                  ) : null}

                  {univ?.faculty ? (
                    <InfoRecord
                      name={vkKeyTranslate.univFaculty}
                      value={univ.faculty}
                    />
                  ) : null}

                  {univ?.faculty_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.univFaculty_name}
                      value={univ.faculty_name}
                    />
                  ) : null}

                  {univ?.graduation ? (
                    <InfoRecord
                      name={vkKeyTranslate.univGraduation}
                      value={univ.graduation}
                    />
                  ) : null}
                </Stack>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}

        {data?.schools?.length ? (
          <Accordion
            expanded={expanded === 'schools'}
            onChange={handleChange('schools')}
          >
            <AccordionSummary>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SubheaderIcon name='AutoStoriesOutlinedIcon' />
                </Box>

                <Typography variant='body1' color='common.black'>
                  {vkKeyTranslate.schools}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {data.schools.map((school: any, idx: number) => (
                <Stack key={idx}>
                  <Typography
                    p={1}
                    variant='body2'
                    color='common.black'
                    fontWeight={700}
                  >
                    {school.name ?? `Школа № ${idx + 1}`}
                  </Typography>

                  {school?.country_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.country}
                      value={school.country_name}
                    />
                  ) : null}

                  {school?.city_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.city}
                      value={school.city_name}
                    />
                  ) : null}

                  {school?.class ? (
                    <InfoRecord
                      name={vkKeyTranslate.schoolClass}
                      value={school.class}
                    />
                  ) : null}

                  {school?.speciality ? (
                    <InfoRecord
                      name={vkKeyTranslate.speciality}
                      value={school.speciality}
                    />
                  ) : null}

                  {school?.year_from ? (
                    <InfoRecord
                      name={vkKeyTranslate.schoolYear_from}
                      value={school.year_from}
                    />
                  ) : null}

                  {school?.year_to ? (
                    <InfoRecord
                      name={vkKeyTranslate.schoolYear_to}
                      value={school.year_to}
                    />
                  ) : null}

                  {school?.year_graduated ? (
                    <InfoRecord
                      name={vkKeyTranslate.schoolYear_graduated}
                      value={school.year_graduated}
                    />
                  ) : null}
                </Stack>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}

        {data?.military?.length ? (
          <Accordion
            expanded={expanded === 'military'}
            onChange={handleChange('military')}
          >
            <AccordionSummary>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SubheaderIcon name='MilitaryTechOutlinedIcon' />
                </Box>

                <Typography variant='body1' color='common.black'>
                  {vkKeyTranslate.military}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {data.military.map((mil: any, idx: number) => (
                <Stack key={idx}>
                  <Typography
                    p={1}
                    variant='body2'
                    color='common.black'
                    fontWeight={700}
                  >
                    {mil.unit ?? `Запись № ${idx + 1}`}
                  </Typography>

                  {mil?.country_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.country}
                      value={mil.country_name}
                    />
                  ) : null}

                  {mil?.city_name ? (
                    <InfoRecord
                      name={vkKeyTranslate.city}
                      value={mil.city_name}
                    />
                  ) : null}

                  {mil?.from ? (
                    <InfoRecord name={vkKeyTranslate.from} value={mil.from} />
                  ) : null}

                  {mil?.until ? (
                    <InfoRecord name={vkKeyTranslate.until} value={mil.until} />
                  ) : null}
                </Stack>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}

        {data?.interests ||
        data?.books ||
        data?.tv ||
        data?.quotes ||
        data?.about ||
        data?.games ||
        data?.movies ||
        data?.music ? (
          <Accordion
            expanded={expanded === 'interests'}
            onChange={handleChange('interests')}
          >
            <AccordionSummary>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SubheaderIcon name='InterestsOutlinedIcon' />
                </Box>

                <Typography variant='body1' color='common.black'>
                  {vkKeyTranslate.interests}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              {data?.interests ? (
                <InfoRecord
                  name={vkKeyTranslate.interests}
                  value={data.interests}
                />
              ) : null}

              {data?.books ? (
                <InfoRecord name={vkKeyTranslate.books} value={data.books} />
              ) : null}

              {data?.tv ? (
                <InfoRecord name={vkKeyTranslate.tv} value={data.tv} />
              ) : null}

              {data?.quotes ? (
                <InfoRecord name={vkKeyTranslate.quotes} value={data.quotes} />
              ) : null}

              {data?.about ? (
                <InfoRecord name={vkKeyTranslate.about} value={data.about} />
              ) : null}

              {data?.games ? (
                <InfoRecord name={vkKeyTranslate.games} value={data.games} />
              ) : null}

              {data?.movies ? (
                <InfoRecord name={vkKeyTranslate.movies} value={data.movies} />
              ) : null}

              {data?.music ? (
                <InfoRecord name={vkKeyTranslate.music} value={data.music} />
              ) : null}
            </AccordionDetails>
          </Accordion>
        ) : null}
      </>
    )
  }
)
