import { observer } from 'mobx-react-lite'
import React, { ReactElement } from 'react'
import { translatedKeys } from './utils'
import { Stack, Typography } from '@mui/material'

interface TextRecordParams {
  name: keyof typeof translatedKeys
  value: string | number | ReactElement
}

export const TextRecord = observer<TextRecordParams>(({ name, value }) => {
  return (
    <Stack p={1} direction='row' justifyContent='space-between'>
      <Typography
        variant='body1'
        color='common.black'
        width='50%'
        textAlign='start'
        sx={{ wordBreak: 'break-word' }}
      >
        {translatedKeys?.[name]}
      </Typography>
      <Typography variant='body1' color='text.disabled' textAlign='end'>
        {value}
      </Typography>
    </Stack>
  )
})
