import { Box, ClickAwayListener, Stack } from '@mui/material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext } from 'react'
import { sortedDistrictsInRegions } from '../Radar.constants'
import { CheckboxList } from './CheckboxList'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { OpenCheckboxesBtn } from './CheckboxList.styled'

export const DistrictsList = observer(() => {
  const { rootStore } = useContext(Context)
  const { radarStore } = rootStore
  const { isSelectOpened, setIsSelectOpened } = radarStore

  const closeSelect = useCallback(() => {
    setIsSelectOpened(false)
  }, [setIsSelectOpened])

  const handleOpenBtnClick = useCallback(() => {
    setIsSelectOpened(!isSelectOpened)
  }, [isSelectOpened, setIsSelectOpened])

  return (
    <ClickAwayListener
      onClickAway={closeSelect}
      mouseEvent='onMouseUp'
      touchEvent='onTouchEnd'
    >
      <Stack width='312px' position='relative'>
        <OpenCheckboxesBtn
          variant='outlined'
          onClick={handleOpenBtnClick}
          sx={{ borderColor: isSelectOpened ? 'primary.main' : 'lightgray' }}
        >
          <Box component='span'>Оплата и районы</Box>

          {isSelectOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDown />}
        </OpenCheckboxesBtn>

        {isSelectOpened ? (
          <Box sx={{ position: 'absolute', top: '50px', zIndex: 2 }}>
            <CheckboxList data={sortedDistrictsInRegions} />
          </Box>
        ) : null}
      </Stack>
    </ClickAwayListener>
  )
})
