import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FiltersContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}))

export const Content = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
  },
}))
