import { Box, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { VkHistoryCast } from 'store/profileStore/profileStore.types'
import { InfoRecord } from '../VkUserInfo/VkUserInfoRecord'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'

interface Props {
  cast: VkHistoryCast
  index: number
}

const translatedNames: Record<string, string> = {
  year_of_cast: 'Год изменения',
  month_of_cast: 'Месяц изменения',
  vk_id: 'Vk Id',
  url: 'Ссылка',
  vk_user_name: 'Пользователь',
  last_name_first_name: 'Имя',
  sex: 'Пол',
  birthday: 'День рождения',
  country: 'Страна',
  city: 'Город',
  region: 'Регион',
  district: 'Район',
  university: 'Университет',
  education: 'Образование',
  faculty: 'Факультет',
  skype: 'Скайп',
  instagram: 'Инстаграм',
  followers: 'Подписчиков',
  home_page: 'Домашняя страница',
  home_town: 'Родной город',
  school: 'Школа',
  secondary_education: 'Второе образование',
  aka: 'Псевдоним',
  description: 'Описание',
  places_of_work: 'Места работы',
  date_of_registration: 'Дата регистрации',
  status: 'Статус',
  www: 'www',
  website: 'website',
  ban: 'Бан',
  home_phone: 'Домашний тел.',
  mobile_phone_1: 'Мобильный тел. №1',
  mobile_phone_2: 'Мобильный тел. №2',
  mobile_phone_3: 'Мобильный тел. №3',
  interests: 'Интересы',
  music: 'Музыка',
  films: 'Фильмы',
  contacts: 'Контакты',
  family: 'Семья',
  fio_: 'ФИО',
  birthdays_: 'Дни рождения',
}

export const HistoryCast = observer<Props>((props) => {
  const { cast, index } = props

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const getValue = (key: string, value: string | string[]) => {
    if (value && Array.isArray(value)) {
      return value.map((el, idx) => (idx === value.length - 1 ? el : `${el}, `))
    }

    if (value.includes('http')) {
      if (key === 'website') {
        return (
          <a
            target='_blank'
            href={value}
            rel='noreferrer'
            style={{ color: '#40a9ff' }}
          >
            Сайт
          </a>
        )
      }

      return (
        <a
          target='_blank'
          href={value}
          rel='noreferrer'
          style={{ color: '#40a9ff' }}
        >
          Профиль
        </a>
      )
    }

    return value
  }

  if (!cast || !Object.keys(cast)?.length) {
    return null
  }

  return (
    <>
      <Accordion
        expanded={expanded === 'history'}
        onChange={handleChange('history')}
      >
        <AccordionSummary>
          <Stack direction='row'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='UpdateOutlinedIcon' />
            </Box>

            <Typography variant='body1' color='common.black'>
              {cast?.year_of_cast
                ? `Обновление от ${cast.year_of_cast} года`
                : `Обновление № ${index + 1}`}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {cast && Object.entries(cast).length
            ? Object.entries(cast).map(([key, val]) =>
                translatedNames[key] ? (
                  <InfoRecord
                    key={key}
                    name={translatedNames[key]}
                    value={getValue(key, val)}
                  />
                ) : null
              )
            : null}
        </AccordionDetails>
      </Accordion>
    </>
  )
})
