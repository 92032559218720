import { observer } from 'mobx-react-lite'
import React from 'react'
import { Relationships as IRelationships } from 'store/profileStore/profileStore.types'
import { PhoneRels } from './PhoneRels'
import { Box, Stack, Typography } from '@mui/material'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'

interface Params {
  rels: IRelationships
}

export const Relationships = observer<Params>(({ rels }) => {
  return (
    <>
      <Stack
        sx={{ width: '100%', mt: 1, p: '16px 16px 0 16px' }}
        alignItems='flex-start'
      >
        <Stack direction='row'>
          <Box
            sx={{
              mr: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SubheaderIcon name='ConnectWithoutContactIcon' />
          </Box>

          <Typography color='common.black' variant='h6'>
            Связи источников
          </Typography>
        </Stack>

        {rels?.phones?.length ? <PhoneRels data={rels.phones} /> : null}
      </Stack>
    </>
  )
})
