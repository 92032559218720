import s from '../Map.module.scss'
import React from 'react'
import { isPochta, isVelobike } from './FeaturePopup.utils'
import { Velobikes } from './Velobikes'
import { Pochta } from './Pochta'
import { PopupContainer } from './FeaturePopup.styles'
import { NotsubHexData } from './NotsubHexData'
import { Subhexdata } from './Subhexdata'
import { EforVk } from './EforVk'
import { CoddFines } from './CoddFines'

const transformDate = (str: string) => {
  const [date, time] = str.split('T')
  const [YYYY, MM, DD] = date.split('-')
  const [hour, min] = time.split(':')

  return `${hour}ч ${min}мин - ${DD}.${MM}.${YYYY}`
}

const transformDtpDate = (str: string) => {
  const [DATE, TIME] = str.split(' ')
  const [YYYY, MM, DD] = DATE.split('-')

  return `${DD}.${MM}.${YYYY} - ${TIME}`
}

const getHeaderText = (data: any) => {
  if (data.icon === 'startFlag') {
    return 'Начальная локация'
  }
  if (data.icon === 'endFlag') {
    return 'Конечная локация'
  }
  if (Array.isArray(data)) {
    if (data[0].startLocation) {
      return 'Начальная локация'
    }
    if (data[0].endLocation) {
      return 'Конечная локация'
    }
    if (data[0].sameStartEnd) {
      return 'Начальная и конечная локация'
    }
  }
  if (data.icon === 'pochta') {
    return `Почтовое отделение ${data?.index_to_ccode}`
  }

  return
}

const isLastLocations = (data: any) =>
  data.camera_place && data.speed && data.time_check
const isDtp = (data: any) => data.icon === 'dtp'

const lastLocations = (data: any) => (
  <>
    <div className={s.lastLocationPopup}>
      <p className={s.subHeader}>Локация:</p>
      <span>{data.camera_place}</span>
    </div>
    <div>
      <p className={s.subHeader}>Скорость:</p> <span>{+data.speed} км/ч</span>
    </div>
    <div className={s.date}>
      <p className={s.subHeader}>Время:</p>{' '}
      <span>{transformDate(data.time_check)}</span>
    </div>
  </>
)

const dtp = (data: any) => (
  <div className={s.locations}>
    <div className={s.dtpInfo}>
      <b style={{ width: '90px' }}>Дата ДТП:</b>
      <span>{transformDtpDate(data.date)}</span>
    </div>
  </div>
)

export const FeaturePopup = ({
  data,
  areDetectionsVis,
  areNotSubscrHexVis,
}: any) => {
  const subscrHexId = data?.subscrHexId
  const notSubscrHexId = data?.notSubscrHexId
  const coddFine = data?.icon === 'codd'

  return (
    <>
      <div
        className={`
    ${s.popupData}
    ${!!isLastLocations(data) && s.smallPopupData}
    ${!!isPochta(data) && s.pochtaPopupData}
    ${!!isDtp(data) && s.dtpPopupData}
  `}
      >
        <b className={s.title}>{getHeaderText(data)}</b>
        {!!isVelobike(data) && <Velobikes data={data} />}{' '}
        {/* проверить + изменить стили */}
        {!!isLastLocations(data) && lastLocations(data)}
        {!!isPochta(data) && <Pochta data={data} />}{' '}
        {/* проверить + изменить стили */}
        {!!isDtp(data) && dtp(data)}
      </div>

      {/* рефакторинг стилей */}
      <>
        <PopupContainer>
          {subscrHexId ? (
            <Subhexdata data={data} areNotSubscrHexVis={areNotSubscrHexVis} />
          ) : null}
          {notSubscrHexId ? (
            <NotsubHexData data={data} areDetectionsVis={areDetectionsVis} />
          ) : null}
          {data?.source === 'eforVkGeo' ? <EforVk data={data} /> : null}
          {coddFine ? <CoddFines data={data} /> : null}
        </PopupContainer>
      </>
    </>
  )
}
