import { RootStore } from '../rootStore'
import { makeAutoObservable, runInAction } from 'mobx'
import { request } from '../../utils/request'
import {
  Arrangement,
  CheckboxData,
  HexagonCommunity,
  HexagonData,
  HexagonUser,
  RadarView,
} from './radar.types'
import { RADAR } from '../apiConstants'
import { sortedDistrictsInRegions } from 'components/Radar/Radar.constants'

export class RadarStore {
  rootStore: RootStore
  error: string | undefined = undefined
  districts: HexagonData[] | null = null
  activeView: RadarView = 'communities'
  isSelectOpened = false
  checkboxState: CheckboxData = {}
  selectFilterValue = ''
  selectedRange: (Date | null)[] = [null, null]
  areDistrictsRequested = false
  hexagonId: string | null = null
  hexagonArrangement: Arrangement | null = null
  hexagonUsers: HexagonUser[] = []
  hexagonCommunities: HexagonCommunity[] = []
  isHexagonDataRequested = false
  isHexagonDataLoading = false
  selectedPoint: number[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    const initialState: CheckboxData = {}

    Object.keys(sortedDistrictsInRegions).forEach((parentKey) => {
      initialState[parentKey] = []
    })

    this.checkboxState = initialState

    makeAutoObservable(this)
  }

  fetchDistricts = async () => {
    const res = await request(RADAR, 'GET', {}, {}, false, false)

    runInAction(() => {
      if (res.success) {
        this.districts = res.payload
        this.rootStore.loaderStore.setIsLoading(false)
      }
      if (!res.success) {
        return res.error
          ? this.setError(res.error)
          : this.setError('Данные по районам не получены')
      }

      this.areDistrictsRequested = true
    })
  }

  fetchHexagonsByRegions = async () => {
    const regions = ['район Щукино']
    const districts = ['ЮЗАО', 'ЦАО', 'ВАО']

    let url = RADAR

    regions.forEach(
      (reg) =>
        (url = url.includes('?')
          ? url.concat(`&region=${reg}`)
          : url.concat(`?region=${reg}`))
    )
    districts.forEach(
      (dis) =>
        (url = url.includes('?')
          ? url.concat(`&district=${dis}`)
          : url.concat(`?district=${dis}`))
    )

    const res = await request(
      url,
      // `${RADAR}?hexagon_id=645cf74d09f792e1b3cc1058`,
      // `${RADAR}?district=СЗАО`,
      // `${RADAR}?region=${region}`,
      'GET',
      {},
      {},
      false,
      false
    )

    runInAction(() => {
      if (res.success) {
        this.districts = res.payload
        this.rootStore.loaderStore.setIsLoading(false)
      }
      if (!res.success) {
        return res.error
          ? this.setError(res.error)
          : this.setError('Данные по районам не получены')
      }
    })
  }

  fetchHexagonData = async (hexagon: Omit<HexagonData, 'geo'>) => {
    if (this.hexagonId === hexagon?._id) {
      return
    }

    this.clearHexagonData()
    this.setIsHexagonDataLoading(true)

    const res = await request(
      `${RADAR}?hexagon_id=${hexagon?._id}`,
      // `${RADAR}?hexagon_id=645cf74d09f792e1b3cc1058`,
      // `${RADAR}?district=СЗАО`,
      // `${RADAR}?region=${region}`,
      'GET',
      {},
      {},
      false,
      false
    )

    runInAction(() => {
      if (res.success) {
        this.hexagonId = hexagon?._id
        this.hexagonUsers = res?.payload?.users?.sort((a: HexagonUser) =>
          a.username ? -1 : 0
        )
        this.hexagonCommunities = res?.payload?.channels?.sort(
          (channel: HexagonCommunity) => (channel?.username ? -1 : 0)
        )

        this.hexagonArrangement = {
          districts: hexagon?.districts,
          regions: hexagon?.regions,
        }
      }
      if (!res.success) {
        this.clearHexagonData()

        return res.error
          ? this.setError(res.error)
          : this.setError('Данные района не получены')
      }
    })

    this.isHexagonDataRequested = true
    this.setIsHexagonDataLoading(false)
  }

  clearHexagonData = () => {
    this.hexagonUsers = []
    this.hexagonCommunities = []
    this.hexagonArrangement = null
  }

  setSelectedRange = (range: (Date | null)[]) => {
    this.selectedRange = range
  }

  setIsHexagonDataLoading = (isHexagonDataLoading: boolean) => {
    this.isHexagonDataLoading = isHexagonDataLoading
  }

  setActiveView = (activeView: RadarView) => {
    this.activeView = activeView
  }

  setIsSelectOpened = (isSelectOpened: boolean) => {
    this.isSelectOpened = isSelectOpened
  }

  setSelectedPoint = (selectedPoint: number[]) => {
    this.selectedPoint = selectedPoint
  }

  setSelectFilterValue = (selectFilterValue: string) => {
    this.selectFilterValue = selectFilterValue
  }

  setCheckboxState = (checkboxState: CheckboxData) => {
    this.checkboxState = checkboxState
  }

  handleParentCheckboxChange = (parentKey: string) => {
    const isChecked =
      this.checkboxState?.[parentKey]?.length ===
      sortedDistrictsInRegions[parentKey].length
    const updatedCheckboxState: CheckboxData = { ...this.checkboxState }

    if (isChecked) {
      updatedCheckboxState[parentKey] = []
    } else {
      updatedCheckboxState[parentKey] = [...sortedDistrictsInRegions[parentKey]]
    }

    this.setCheckboxState(updatedCheckboxState)
  }

  handleChildCheckboxChange = (parentKey: string, childValue: string) => {
    const isChecked = this.checkboxState[parentKey].includes(childValue)
    const updatedCheckboxState: CheckboxData = { ...this.checkboxState }

    if (isChecked) {
      updatedCheckboxState[parentKey] = updatedCheckboxState?.[
        parentKey
      ]?.filter((value) => value !== childValue)
    } else {
      updatedCheckboxState?.[parentKey]?.push(childValue)
    }

    this.setCheckboxState(updatedCheckboxState)
  }

  handleAllCheckboxes = () => {
    const updatedCheckboxState: CheckboxData = { ...this.checkboxState }

    const areChecked = Object.keys(sortedDistrictsInRegions).every(
      (parent: string) =>
        this.checkboxState[parent].length ===
        sortedDistrictsInRegions[parent].length
    )

    Object.keys(sortedDistrictsInRegions).forEach((parent) => {
      if (areChecked) {
        updatedCheckboxState[parent] = []
      } else {
        updatedCheckboxState[parent] = [...sortedDistrictsInRegions[parent]]
      }
    })

    this.setCheckboxState(updatedCheckboxState)
  }

  setError(err: string | undefined) {
    this.error = err
  }

  clearRadarStore() {
    this.error = undefined
    this.activeView = 'communities'
    this.districts = null
    this.selectFilterValue = ''
    this.isSelectOpened = false
    this.selectedRange = [null, null]
    this.areDistrictsRequested = false
    this.hexagonArrangement = null
    this.hexagonUsers = []
    this.hexagonCommunities = []
    this.isHexagonDataRequested = false
    this.isHexagonDataLoading = false
    this.hexagonId = null
  }
}
