import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

interface WrapperProps {
  arrangement?: number
}

export const DistrictsWrapper = styled(Stack)<WrapperProps>(
  ({ arrangement }) => ({
    borderRadius: '8px',
    backgroundColor: '#f1f1f1',
    alignItems: 'center',
    width: '100%',
    justifyContent: arrangement ? 'flex-start' : 'center',
  })
)
