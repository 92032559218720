/* eslint-disable @typescript-eslint/ban-ts-comment */
import { RootStore } from '../rootStore'
import { makeAutoObservable, runInAction } from 'mobx'
import { request } from '../../utils/request'

// @ts-ignore
class GraphStore {
  rootStore: RootStore
  isAuth = false
  graphData: any = null
  error = ''

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  async authUser() {
    this.rootStore.loaderStore.setIsLoading(true)

    const res = await request(
      `/login`,
      'POST',
      {},
      { login: 'im', password: 'gfh43gh56g' }
    )
    if (res.success) {
      runInAction(() => {
        localStorage.setItem('accessToken', res.payload.token)
        this.isAuth = true
      })
    }
    if (!res.success) {
      runInAction(() => (this.isAuth = false))
    }
    this.rootStore.loaderStore.setIsLoading(false)
  }

  async fetchGraphData(params: string[]) {
    this.rootStore.loaderStore.setIsLoading(true)

    const dataId = params.find((el) => el.includes('dataId')) as string
    const userId = params.find((el) => el.includes('userId')) as string
    const isAdmin = params.find((el) => el.includes('isAdmin')) as string

    const data = dataId.slice(dataId.indexOf('=') + 1)
    const user = userId.slice(userId.indexOf('=') + 1)
    const admin = isAdmin.slice(isAdmin.indexOf('=') + 1)

    const headers = { dataId: data, userId: user, isAdmin: admin }

    const res = await request(`/graph/${data}`, 'GET', {}, {}, false, true, {
      ...headers,
    })

    runInAction(() => {
      if (res.success) {
        this.graphData = res.payload
      } else if (res.code === 400) {
        this.error = 'Данные устарели, повторите ваш запрос'
      } else if (res.error) {
        this.error = res.error
      } else {
        this.error = 'Не удалось получить данные пользователя'
      }
    })
    this.rootStore.loaderStore.setIsLoading(false)
  }
}

export default GraphStore
