import { Box, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Context } from 'index'
import { PersonalInfoProps } from './PersonalInfo/TgPersonalInfo'
import yandexSvg from 'assets/yandexSvg.svg'
import DC from 'assets/DC.png'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { SubheaderIcon } from '../components/SubheaderIcon'
import { InfoChip } from '../components/InfoChip'
import { SummaryRecord } from './SummaryRecord'

const transportNames = {
  auto: {
    name: 'Авто',
    sequence: 1,
  },
  public_transport: {
    name: 'Общественный транспорт',
    sequence: 2,
  },
  velobike: {
    name: 'Велосипед',
    sequence: 3,
  },
} as any

export const AdditionalInfo = observer(
  ({ expanded, handleChange }: PersonalInfoProps) => {
    const { rootStore } = useContext(Context)
    const { profileStore } = rootStore
    const { additionalInfo, transportTypes } = profileStore

    return (
      <>
        {additionalInfo?.additional_labels?.length ? (
          <Stack sx={{ p: 2 }}>
            <Stack direction='row' mb='4px'>
              <Box
                sx={{
                  mr: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SubheaderIcon name='CreateOutlinedIcon' />
              </Box>

              <Typography color='common.black' textAlign='start' variant='h6'>
                Метки
              </Typography>
            </Stack>

            <Stack
              flexDirection='row'
              flexWrap='wrap'
              alignItems='start'
              sx={{ maxHeight: 220, overflowY: 'auto' }}
            >
              {additionalInfo.additional_labels.map(
                (label: string, idx: number) => (
                  <InfoChip key={idx} text={label} />
                )
              )}
            </Stack>
          </Stack>
        ) : null}

        {additionalInfo?.yandex_food?.orders &&
        Object.keys(additionalInfo.yandex_food.orders)?.length ? (
          <Stack sx={{ px: 2 }}>
            <Accordion
              sx={{ width: '100%' }}
              expanded={expanded === 'yandex'}
              onChange={handleChange('yandex')}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    svg: { color: 'primary.main', fontSize: 22 },
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    width={22}
                    height={22}
                    src={yandexSvg}
                    alt='yandexIcon'
                  />
                </Box>
                {additionalInfo?.yandex_food?.amount_spent ? (
                  <Typography variant='body1' color='common.black'>
                    {`Yandex Food (${additionalInfo?.yandex_food?.amount_spent} ₽)`}
                  </Typography>
                ) : (
                  <Typography variant='body1' color='common.black'>
                    Yandex Food
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  justifyContent='space-between'
                  sx={{
                    mt: 1,
                    width: '100%',
                    maxWidth: '900px',
                  }}
                >
                  {Object.entries(additionalInfo.yandex_food.orders).map(
                    ([address, ordersObj]: any, adrIdx) => (
                      <Stack key={adrIdx} sx={{ mb: 1 }}>
                        <Typography color='common.black' fontWeight={700}>
                          {address}
                        </Typography>

                        {ordersObj?.add_info?.length ? (
                          <SummaryRecord
                            name='Инфо об адресе'
                            renderedValue={
                              <Stack
                                flexWrap='wrap'
                                direction='row'
                                width='50%'
                                justifyContent='flex-end'
                              >
                                {ordersObj.add_info.map(
                                  (addInfo: string, addInfoIdx: number) => (
                                    <Typography
                                      color='text.disabled'
                                      variant='body1'
                                      key={addInfoIdx}
                                      sx={{ wordBreak: 'break-word' }}
                                      textAlign='end'
                                      m={0.5}
                                    >
                                      {addInfo}
                                    </Typography>
                                  )
                                )}
                              </Stack>
                            }
                          />
                        ) : null}

                        {ordersObj?.order?.length
                          ? ordersObj?.order?.map(
                              (orderInfo: any, orderInfoIdx: number) => (
                                <SummaryRecord
                                  key={orderInfoIdx}
                                  name={`Заказ № ${orderInfoIdx + 1} ${
                                    orderInfo?.price
                                      ? `(${orderInfo.price}₽)`
                                      : null
                                  }`}
                                  renderedValue={
                                    <Stack
                                      flexWrap='wrap'
                                      width='50%'
                                      justifyContent='flex-end'
                                    >
                                      {orderInfo?.date ? (
                                        <Typography
                                          variant='body1'
                                          sx={{
                                            wordBreak: 'break-word',
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                          textAlign='end'
                                          m={0.5}
                                        >
                                          <Box
                                            component='span'
                                            color='common.black'
                                          >
                                            Дата:{' '}
                                          </Box>
                                          <Box
                                            component='span'
                                            fontWeight='normal'
                                            color='text.disabled'
                                          >
                                            {orderInfo.date}
                                          </Box>
                                        </Typography>
                                      ) : null}

                                      {orderInfo?.place_name ? (
                                        <Typography
                                          variant='body1'
                                          sx={{
                                            wordBreak: 'break-word',
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                          textAlign='end'
                                          m={0.5}
                                        >
                                          <Box
                                            component='span'
                                            color='common.black'
                                          >
                                            Ресторан:{' '}
                                          </Box>
                                          <Box
                                            component='span'
                                            fontWeight='normal'
                                            sx={{ wordBreak: 'break-word' }}
                                            color='text.disabled'
                                          >
                                            {orderInfo.place_name}
                                          </Box>
                                        </Typography>
                                      ) : null}
                                    </Stack>
                                  }
                                />
                              )
                            )
                          : null}
                      </Stack>
                    )
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        ) : null}

        {additionalInfo?.delivery_food?.orders &&
        Object.keys(additionalInfo.delivery_food.orders)?.length ? (
          <Stack sx={{ px: 2 }}>
            <Accordion
              sx={{ width: '100%' }}
              expanded={expanded === 'delivery'}
              onChange={handleChange('delivery')}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img width={22} height={22} src={DC} alt='deliveryIcon' />
                </Box>
                {additionalInfo?.delivery_food?.amount_spent ? (
                  <Typography variant='body1' color='common.black'>
                    {`Delivery Club (${additionalInfo?.delivery_food?.amount_spent} ₽)`}
                  </Typography>
                ) : (
                  <Typography variant='body1' color='common.black'>
                    Delivery Club
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  justifyContent='space-between'
                  sx={{
                    mt: 1,
                    width: '100%',
                    maxWidth: '900px',
                  }}
                >
                  {Object.entries(additionalInfo.delivery_food.orders).map(
                    ([address, ordersArr]: any, adrIdx) => (
                      <Stack key={adrIdx} sx={{ mb: 1 }}>
                        <Typography color='common.black' fontWeight={700}>
                          {address}
                        </Typography>

                        {ordersArr?.length
                          ? ordersArr.map(
                              (orderInfo: any, orderInfoIdx: number) => (
                                <Stack key={orderInfoIdx}>
                                  <SummaryRecord
                                    key={orderInfoIdx}
                                    name={`Заказ № ${orderInfoIdx + 1} ${
                                      orderInfo?.price
                                        ? `(${orderInfo.price}₽)`
                                        : null
                                    }`}
                                    renderedValue={
                                      <Stack
                                        flexWrap='wrap'
                                        width='50%'
                                        justifyContent='flex-end'
                                      >
                                        {additionalInfo.delivery_food?.name ? (
                                          <Typography
                                            variant='body1'
                                            sx={{
                                              wordBreak: 'break-word',
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                            textAlign='end'
                                            m={0.5}
                                          >
                                            <Box
                                              color='common.black'
                                              component='span'
                                            >
                                              Имя:{' '}
                                            </Box>
                                            <Box
                                              component='span'
                                              fontWeight='normal'
                                              color='text.disabled'
                                              sx={{ wordBreak: 'break-word' }}
                                            >
                                              {
                                                additionalInfo.delivery_food
                                                  .name
                                              }
                                            </Box>
                                          </Typography>
                                        ) : null}

                                        {orderInfo?.date ? (
                                          <Typography
                                            variant='body1'
                                            sx={{
                                              wordBreak: 'break-word',
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                            textAlign='end'
                                            m={0.5}
                                          >
                                            <Box
                                              component='span'
                                              color='common.black'
                                            >
                                              Дата:{' '}
                                            </Box>
                                            <Box
                                              component='span'
                                              fontWeight='normal'
                                              color='text.disabled'
                                            >
                                              {orderInfo.date}
                                            </Box>
                                          </Typography>
                                        ) : null}
                                      </Stack>
                                    }
                                  />
                                </Stack>
                              )
                            )
                          : null}
                      </Stack>
                    )
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        ) : null}

        {transportTypes && Object.keys(transportTypes).length ? (
          <Stack sx={{ p: 2 }}>
            <Stack direction='row'>
              <Box
                sx={{
                  mr: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SubheaderIcon name='RocketLaunchOutlinedIcon' warningType />
              </Box>

              <Typography variant='h6' color='common.black' textAlign='start'>
                Транспорт
              </Typography>
            </Stack>

            <Stack
              alignItems='start'
              flexWrap='wrap'
              mt={1}
              flexDirection='row'
            >
              {Object.entries(transportTypes)
                .filter(([_, val]) => val)
                .sort(([k1, _v1], [k2, _v2]) =>
                  transportNames[k1].sequence < transportNames[k2].sequence
                    ? -1
                    : 0
                )
                .map(([key, __], idx) => (
                  <Stack
                    key={`${key}_${idx}`}
                    direction='row'
                    alignItems='center'
                    mx={1}
                  >
                    <InfoChip text={transportNames[key].name} />
                  </Stack>
                ))}
            </Stack>
          </Stack>
        ) : null}
      </>
    )
  }
)
