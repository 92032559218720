import { Stack, Typography } from '@mui/material'
import React from 'react'

export const SummaryRecord = ({ name, values, renderedValue }: any) => {
  return (
    <Stack key={name} sx={{ width: '100%' }} alignItems='flex-start'>
      <Stack
        direction='row'
        spacing={1}
        justifyContent='space-between'
        sx={{ width: '100%', p: 1, borderBottom: '1px solid lightgray' }}
      >
        <Typography
          variant='body1'
          width='50%'
          textAlign='start'
          alignSelf='center'
          sx={{ wordBreak: 'break-word' }}
        >
          {name}
        </Typography>

        {renderedValue ? (
          renderedValue
        ) : (
          <Stack
            direction='row'
            flexWrap='wrap'
            width='50%'
            justifyContent='flex-end'
          >
            {values.map((value: string, idx: number) => (
              <Typography
                key={`${value}_${idx}`}
                variant='body1'
                color='text.disabled'
                sx={{ wordBreak: 'break-word' }}
                textAlign='end'
                m={0.5}
              >
                {value}
              </Typography>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
