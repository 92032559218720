import { Context } from 'index'
import { useContext, useMemo } from 'react'

export const useFinesData = () => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { transportProfile } = profileStore

  const fines = useMemo(() => transportProfile?.trip_fines, [transportProfile])

  const finesData = useMemo(() => {
    const hash = {} as any

    fines?.auto?.map((fine: any) => {
      const fineYear = fine?.violation_year
      if (!fineYear) {
        hash.trash.push(fine)
      }

      return hash?.[fineYear]
        ? hash[fineYear].push(fine)
        : (hash[fineYear] = [fine])
    })

    const sortedFines = Object.entries(hash).map(([year, fines]: any) => [
      year,
      fines.sort((fine: any) =>
        fine?.violation_desc?.toLowerCase() === 'суммарные данные' ? -1 : 0
      ),
    ])

    return Object.fromEntries(sortedFines)
  }, [fines])

  const getFineDay = (day: string) => {
    const [YYYY, MM, DD] = day.split('-')

    return YYYY && MM && DD ? `${DD}-${MM}-${YYYY}` : ''
  }

  return { fines, finesData, getFineDay }
}
