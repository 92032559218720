/* eslint-disable @typescript-eslint/ban-ts-comment */
import { RootStore } from '../rootStore'
import { makeAutoObservable, runInAction } from 'mobx'
import Polyline from 'ol/format/Polyline'
import { request } from '../../utils/request'
import moGeo from '../../utils/moGeo.json'
import subwayLinesColor from '../../utils/subway_lines_color.json'

export interface EforUserPhoto {
  url?: string
  width?: number
  type?: string
  height?: number
}

export interface VkGeo {
  _index: string
  _source: {
    album_id?: number
    date?: number
    id?: number
    owner_id?: number
    lat: number
    long: number
    sizes?: EforUserPhoto
    text?: string
    has_tags?: boolean
  }
}

export interface CoddFines {
  brand_model: string
  camera: string
  camera_id: string
  camera_latitude: string
  camera_longitude: string
  icon: string
  id: string
  lane_num: string
  location_lat: string
  location_lon: string
  parking_zone: string
  pass_dttm: string
  regno: string
  rejection_code: string
  status: string
  transportType: string
  violation_desc: string
  violation_limit: string
  violation_place: string
  violation_speed: string
  extraLocs?: CoddFines[]
}

// @ts-ignore
const geoData: any = new Map(moGeo)
// @ts-ignore
const subwayLinesMap = new Map(subwayLinesColor)

class MapStore {
  rootStore: RootStore
  userTransActivity: any = null
  error = ''
  routes: any = []
  segmentMap: any = []
  areRoutesUpdated = false
  locations: any = []
  areLocationsUpdated = false
  isAuth = false
  token = ''
  vkGeoData: null | VkGeo[] = null
  isDataRequested = false
  hasOnlyRadarData = false
  districts: any = []
  areDistrictsUpdated = false
  yandexFood: any = []
  deliveryClub: any = []
  lastLocations: any = []
  velobikes: any = []
  profileMapError = ''
  userPhoto: null | EforUserPhoto = null
  previousMapId = ''
  possibleSelectors: string[] = []
  coddFines = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  async authUser() {
    this.rootStore.loaderStore.setIsLoading(true)
    const res = await request(
      `/login`,
      'POST',
      {},
      { login: 'im', password: 'gfh43gh56g' }
    )
    if (res.success) {
      runInAction(() => {
        localStorage.setItem('accessToken', res.payload.token)
        this.isAuth = true
      })
    }
    if (!res.success) {
      runInAction(() => (this.isAuth = false))
    }
  }

  // async fetchMapData(userId: string, mapId = null) {
  async fetchMapData(params: string[], mapId = null) {
    if (this.previousMapId) {
      return
    }

    const dataId = params.find((el) => el.includes('dataId')) as string
    const userId = params.find((el) => el.includes('userId')) as string
    const isAdmin = params.find((el) => el.includes('isAdmin')) as string

    const data = dataId.slice(dataId.indexOf('=') + 1)
    const user = userId.slice(userId.indexOf('=') + 1)
    const admin = isAdmin.slice(isAdmin.indexOf('=') + 1)

    const headers = { dataId: data, userId: user, isAdmin: admin }

    if (mapId) {
      this.previousMapId = mapId
    }

    const _headers = this.rootStore.profileStore.isFromProfile
      ? {}
      : { ...headers }

    const res = await request(
      `/get_data_coordinates/${mapId ?? data}`,
      'GET',
      {},
      {},
      false,
      true,
      { ..._headers }
    )

    if (res.success) {
      runInAction(() => {
        if (res.payload.error) {
          return this.setError(res.payload.error.description)
        }

        const { id_data, ...rest } = res.payload

        let hasData = false

        Object.entries(rest).forEach(([key, val]) => {
          if (!val) {
            return
          }

          if (val && Array.isArray(val) && !val.length) {
            return
          }

          if (typeof val === 'object' && !Object.keys(val).length) {
            return
          }

          if (
            key === 'pochta' &&
            val &&
            Array.isArray(val) &&
            val.length &&
            !val.some((el) => !!el?.coords)
          ) {
            return
          }

          hasData = true
        })

        this.setError('')

        if (!hasData) {
          this.profileMapError = 'На карте нет данных'
          return
        }

        this.userTransActivity = res.payload

        //routes
        if (this.userTransActivity?.trip_routes?.auto?.length) {
          let lastId = -1
          const routes: any = []
          this.userTransActivity?.trip_routes?.auto?.forEach(
            (autoRoute: any) => {
              if (autoRoute.points) {
                let route: string | any[] = []
                const routeLonLat = []
                autoRoute.id = ++lastId

                const polylineFormat = new Polyline()
                const feature = polylineFormat.readFeature(autoRoute.points)

                //@ts-ignore
                const geometry = feature.getGeometry()?.flatCoordinates

                for (let i = 0; i < geometry.length; i++) {
                  if (i % 2 !== 0) {
                    continue
                  }

                  const yCoordinate =
                    100 - ((geometry[i + 1] - 55.486211) / 0.480722) * 100
                  const xCoordinate =
                    ((geometry[i] - 37.172303) / 0.807089) * 100

                  route.push(`${xCoordinate},${yCoordinate}`)
                  routeLonLat.push([geometry[i], geometry[i + 1]])
                }

                route = route.join(' ')

                const coordinates = [
                  [
                    autoRoute['top_start_camera_longitude'] ||
                      autoRoute['start_location_lon'],
                    autoRoute['top_start_camera_latitude'] ||
                      autoRoute['start_location_lat'],
                  ],
                  [
                    autoRoute['top_end_camera_longitude'] ||
                      autoRoute['end_location_lon'],
                    autoRoute['top_end_camera_latitude'] ||
                      autoRoute['end_location_lat'],
                  ],
                ]

                routes.push({
                  id: autoRoute.id,
                  coordinates,
                  route,
                  routeLonLat,
                  paths: autoRoute.points,
                  periodId: autoRoute.periodId,
                })
              }
            }
          )
          this.routes = routes
          this.setAreRoutesUpdated(true)
          //routesSegments
          const segmentMap = new Map()
          const routesOverlay: any = {}
          this.routes.forEach((route: any) => {
            route.route
              .split(' ')
              .forEach((point: any, i: number, array: any[]) => {
                if (i === array.length - 1) {
                  return
                }

                const segmentName = `${point} ${array[i + 1]}`
                const segmentObj = segmentMap.get(segmentName)

                if (!segmentObj) {
                  segmentMap.set(segmentName, {
                    position: point,
                    coordinates: [
                      route.routeLonLat[i],
                      route.routeLonLat[i + 1],
                    ],
                    routesId: [route.id],
                    route: segmentName,
                  })
                } else {
                  segmentObj.routesId.push(route.id)
                  segmentMap.set(segmentName, segmentObj)

                  segmentObj.routesId.forEach((routeIdx: number) => {
                    if (routeIdx in routesOverlay) {
                      routesOverlay[routeIdx]++
                    } else {
                      routesOverlay[routeIdx] = 1
                    }
                  })
                }
              })
          })
          this.segmentMap = Array.from(segmentMap.values()).sort(
            (a, b) => a.routesId.length - b.routesId.length
          )
        }
        //locations
        if (Object.keys(this.userTransActivity?.trip_locations).length) {
          const transports = ['auto', 'subway', 'ngpt']
          const activityData: any = []

          for (const transport of transports) {
            if (
              this?.userTransActivity?.trip_locations &&
              transport in this.userTransActivity.trip_locations
            ) {
              this.userTransActivity?.trip_locations[transport].forEach(
                (item: any) => {
                  item.transportType = transport
                  if (item.parking_count > 0) {
                    item.icon = 'parking'
                    item.transportType = 'parking'
                  } else if (item.home_flag > 0) {
                    item.icon = 'home'
                  } else if (item.work_flag > 0) {
                    item.icon = 'work'
                  } else if (item.helpmos) {
                    item.icon = 'camera'
                  } else {
                    item.icon = transport
                  }

                  if (!item.parking_count) {
                    item.parking_count = ''
                  }
                  if (!item.work_flag) {
                    item.work_flag = ''
                  }
                  if (item['line']) {
                    item['subway_line_color'] = subwayLinesMap.get(item['line'])
                  }

                  if (!item['total_visit_count']) {
                    item.count = parseInt(item['parking_count'], 10) || 0
                  } else {
                    item.count = parseInt(item['total_visit_count'], 10) || 0
                    delete item['total_visit_count']
                  }
                  const geoJson = geoData.get(item.district)
                  item.districtId = parseInt(geoJson?.properties['OKATO'], 10)
                  activityData.push(item)
                }
              )
            }
          }
          this.locations = activityData.sort(
            (a: any, b: any) =>
              parseInt(b['total_visit_count']) -
              parseInt(a['total_visit_count'])
          )
          this.setAreLocationsUpdated(true)
        }
        //evacuations
        if (this.userTransActivity?.auto_evacuation?.length) {
          const dataMap = new Map()
          let lastId = 0

          this.userTransActivity?.auto_evacuation.forEach((location: any) => {
            const geoJson = geoData.get(location.evacuation_district)
            const districtId = parseInt(geoJson?.properties['OKATO'], 10)

            const title =
              location.evacuation_district ||
              location.evacuation_division ||
              `(${location.evacuation_latitude}, ${location.evacuation_longitude})`

            let entry = dataMap.get(title)
            if (!entry) {
              const latitude = location.evacuation_latitude
              const longitude = location.evacuation_longitude
              entry = {
                id: `evacuation-${lastId++}`,
                districtId,
                division: location.evacuation_division,
                title,
                location_lat: latitude,
                location_lon: longitude,
                evacuation_reason_type: location.evacuation_reason_type,
                evacuation_year: location.evacuation_year,
                transportType: 'auto',
                icon: 'evacuator',
                district: location.evacuation_district,
              }
            }
            dataMap.set(title, entry)
          })

          const locs = Array.from(dataMap.values())

          if (locs.length) {
            locs.forEach((location) => this.locations.push(location))
            this.setAreLocationsUpdated(true)
          }
        }
        //subway_sales
        if (this.userTransActivity?.subway_sales?.length) {
          const dataMap = new Map()
          let lastId = 0

          this.userTransActivity.subway_sales.forEach((location: any) => {
            const title =
              location['entrance'] ||
              location['station'] ||
              location['line'] ||
              location['district'] ||
              location['division'] ||
              `(${location['location_latitude']}, ${location['location_longitude']})`
            const station = location['station']

            let entry = dataMap.get(title)
            if (!entry) {
              const latitude =
                location['station_latitude'] || location['location_latitude']
              const longitude =
                location['station_longitude'] || location['location_longitude']

              entry = {
                id: `sales-${lastId++}`,
                title,
                station,
                icon: 'sales',
                location_lat: latitude,
                location_lon: longitude,
                transportType: 'sales',
                district: location.district,
                count: parseInt(location['transaction_cnt'], 10),
              }
            }
            dataMap.set(title, entry)
          })

          Array.from(dataMap.values()).forEach((location) =>
            this.locations.push(location)
          )
        }
        //districts
        if (this.locations.length) {
          const districtsMap = new Map()
          this.locations.forEach((location: any) => {
            if (!location.district) {
              return
            }

            let entry = districtsMap.get(location.district)
            const geoJson = geoData.get(location.district)
            if (!entry) {
              entry = {
                id: parseInt(geoJson?.properties['OKATO'], 10),
                name: location.district,
                geoJson,
                counts: {
                  all: 0,
                  auto: 0,
                  subway: 0,
                  ngpt: 0,
                  parking: 0,
                  sales: 0,
                },
              }
            }
            if (location.count) {
              entry.counts.all += +location.count
              entry.counts[location.transportType] += +location.count
            }
            districtsMap.set(location.district, entry)
          })
          this.districts = Array.from(districtsMap.values()).sort(
            (a, b) => b.counts.all - a.counts.all
          )
          this.setAreDistrictsUpdated(true)
        }
        //yandex_food
        if (this.userTransActivity?.yandex_food?.length) {
          this.userTransActivity.yandex_food.forEach((location: any) => {
            return this.yandexFood.push({
              ...location,
              icon: 'yandex',
              location_lon: location.yandex_longitude,
              location_lat: location.yandex_latitude,
            })
          })
        }
        //delivery_food
        if (this.userTransActivity?.delivery_food?.length) {
          this.userTransActivity.delivery_food.forEach((location: any) => {
            return this.deliveryClub.push({
              ...location,
              icon: 'delivery',
              location_lon: location.longitude,
              location_lat: location.latitude,
            })
          })
        }
        //last locations
        if (this.userTransActivity?.camera_check?.length) {
          const newLoc: any = []
          this.userTransActivity.camera_check
            .filter((i: any) => i?.gps_x !== '0.0' && i?.gps_y !== '0.0')
            .forEach((location: any, idx: number, arr: any[]) => {
              let icon
              if (idx === 0) {
                icon = 'startFlag'
              } else if (idx === arr.length - 1) {
                icon = 'endFlag'
              } else {
                icon = 'auto'
              }

              const location_lon = parseFloat(location.gps_x)
              const location_lat = parseFloat(location.gps_y)

              newLoc.push({
                ...location,
                icon,
                location_lon,
                location_lat,
              })
            })

          this.lastLocations = newLoc
        }
        //velobike
        if (this.userTransActivity?.velobike_sessions?.length) {
          const newLocs: any = []
          const pushLoc = (loc: any, idx: number) => {
            newLocs.push({
              ...loc,
              count: 1,
              day: [loc.day],
              session_id: [loc.session_id],
              session_dur: [loc.session_dur],
              session_distance: [loc.session_distance],
              start_session_dttm: [loc.start_session_dttm],
              end_session_dttm: [loc.end_session_dttm],
              id: `velobike_${idx}`,
            })

            if (
              (loc.start_latitude === loc.end_latitude &&
                loc.start_longitude === loc.end_longitude) ||
              loc.start_address === loc.end_address
            ) {
              newLocs.find(
                (i: any) => i.id === `velobike_${idx}`
              ).sameStartEnd = true
            }
            return
          }

          this.userTransActivity.velobike_sessions
            .sort((a: any, b: any) =>
              a.start_session_dttm < b.start_session_dttm ? -1 : 0
            )
            .forEach((loc: any, idx: number) => {
              if (idx === 0) {
                return pushLoc(loc, idx)
              }

              const sameLocIdx = newLocs.findIndex(
                (newLoc: any) =>
                  (newLoc.start_latitude === loc.start_latitude &&
                    newLoc.start_longitude === loc.start_longitude &&
                    newLoc.end_latitude === loc.end_latitude &&
                    newLoc.end_longitude === loc.end_longitude &&
                    newLoc.id !== `velobike_${idx}`) ||
                  (newLoc.start_address === loc.start_address &&
                    newLoc.end_address === loc.end_address &&
                    newLoc.id !== `velobike_${idx}`)
              )

              if (sameLocIdx !== -1) {
                newLocs[sameLocIdx].count += 1
                newLocs[sameLocIdx].day.push(loc.day)
                newLocs[sameLocIdx].session_id.push(loc.session_id)
                newLocs[sameLocIdx].session_dur.push(loc.session_dur)
                newLocs[sameLocIdx].session_distance.push(loc.session_distance)
                newLocs[sameLocIdx].start_session_dttm.push(
                  loc.start_session_dttm
                )
                newLocs[sameLocIdx].end_session_dttm.push(loc.end_session_dttm)
              } else {
                pushLoc(loc, idx)
              }
            })
          this.velobikes = newLocs
        }
        //pochta
        if (this.userTransActivity?.pochta?.length) {
          const newLocs: any[] = []

          const pushLoc = (loc: any, idx: number) => {
            if (!loc.coords) {
              return
            }

            const coords = loc?.coords?.split(';')
            newLocs.push({
              ...loc,
              count: 1,
              location_lon: coords.length ? coords[0] : null,
              location_lat: coords.length ? coords[1] : null,
              delivery_local_dts: [loc.delivery_local_dts],
              fio_rpo_rcpn_name: [loc.fio_rpo_rcpn_name],
              fio_rpo_sndr_name: [loc.fio_rpo_sndr_name],
              phone: [loc.phone],
              icon: 'pochta',
              id: `pochta_${idx}`,
            })
          }

          this.userTransActivity.pochta
            .sort((a: any, b: any) =>
              a.delivery_local_dts < b.delivery_local_dts ? -1 : 0
            )
            .forEach((location: any, idx: number) => {
              if (idx === 0) {
                return pushLoc(location, idx)
              }

              const sameLocIdx = newLocs.findIndex(
                (loc: any) => loc.coords === location.coords
              )

              if (sameLocIdx !== -1) {
                newLocs[sameLocIdx].count += 1
                newLocs[sameLocIdx].delivery_local_dts.push(
                  location.delivery_local_dts
                )
                newLocs[sameLocIdx].fio_rpo_rcpn_name.push(
                  location.fio_rpo_rcpn_name
                )
                newLocs[sameLocIdx].fio_rpo_sndr_name.push(
                  location.fio_rpo_sndr_name
                )
                newLocs[sameLocIdx].phone.push(location.phone)
              } else {
                pushLoc(location, idx)
              }
            })

          if (newLocs.length) {
            newLocs.forEach((loc) => this.locations.push(loc))
            this.setAreLocationsUpdated(true)
          }
        }
        //dtp
        if (this.userTransActivity?.road_accident?.length) {
          this.userTransActivity.road_accident.forEach(
            (
              { accident_latitude, accident_longitude, accident_dttm }: any,
              idx: number
            ) => {
              this.locations.push({
                location_lat: accident_latitude,
                location_lon: accident_longitude,
                transportType: 'auto',
                date: accident_dttm,
                icon: 'dtp',
                id: `dtp_${idx}`,
              })
            }
          )

          this.setAreLocationsUpdated(true)
        }
        //efor_vk
        if (this.userTransActivity?.efor_vk?.length) {
          this.vkGeoData = this.userTransActivity.efor_vk.filter(
            (el: VkGeo) => el._index === 'vk_geo'
          )
        }
        //fines_codd
        if (this.userTransActivity?.codd_fines?.length) {
          const locs: any[] = []

          const arr = this.userTransActivity.codd_fines.sort((a: any, b: any) =>
            new Date(a?.pass_dttm) > new Date(b?.pass_dttm) ? -1 : 0
          )

          arr.forEach((fine: any, idx: number) => {
            const location = {
              ...fine,
              camera_latitude: fine.camera_latitude,
              camera_longitude: fine.camera_longitude,
              transportType: 'coddFines',
              icon: 'codd',
              id: `coddFine_${idx}`,
            }

            if (!idx) {
              locs.push(location)

              return
            }

            const lat = fine?.camera_latitude
            const lon = fine?.camera_longitude

            const sameIdxPoint = locs.findIndex(
              ({ camera_latitude, camera_longitude }) =>
                lat &&
                lon &&
                camera_latitude &&
                camera_longitude &&
                Number(camera_latitude).toPrecision(6) ===
                  Number(lat).toPrecision(6) &&
                Number(camera_longitude).toPrecision(6) ===
                  Number(lon).toPrecision(6)
            )

            if (sameIdxPoint !== -1) {
              if (locs[sameIdxPoint]?.extraLocs) {
                locs[sameIdxPoint].extraLocs.push(location)
              } else {
                locs[sameIdxPoint].extraLocs = [location]
              }
            } else {
              locs.push(location)
            }
          })

          locs.forEach((loc) =>
            this.locations.push({
              ...loc,
              location_lat: loc.camera_latitude,
              location_lon: loc.camera_longitude,
            })
          )

          this.setAreLocationsUpdated(true)
        }
      })
    }

    if (!res.success && !mapId && !this.rootStore.profileStore.radarId) {
      // if (!res.success && !mapId) { было так и тригерило ошибку при рендере карты гексагонов без данных о поездках
      if (res.code === 400) {
        this.setError('Данные устарели, повторите ваш запрос')
      } else if (res.error) {
        this.setError(res.error)
      } else {
        this.setError('Не удалось получить данные пользователя')
      }
    }

    runInAction(() => {
      if (
        this.rootStore?.profileStore?.radarId &&
        this?.userTransActivity?.trip_routes &&
        !Object.keys(this.userTransActivity.trip_routes)?.length &&
        this?.userTransActivity?.trip_locations &&
        !Object.keys(this.userTransActivity.trip_locations)?.length
      ) {
        this.hasOnlyRadarData = true
      }

      if (!res.success && !mapId && this.rootStore.profileStore.radarId) {
        this.hasOnlyRadarData = true
      }

      if (mapId && !res.success) {
        this.profileMapError = 'Ошибка'
      }

      this.isDataRequested = true
    })

    this.rootStore.loaderStore.setIsLoading(false)
  }

  setMapData = (data: any) => {
    if (data) {
      runInAction(() => {
        this.userTransActivity = data

        //routes
        if (this.userTransActivity?.trip_routes?.auto?.length) {
          let lastId = -1
          const routes: any = []
          this.userTransActivity?.trip_routes?.auto?.forEach(
            (autoRoute: any) => {
              if (autoRoute.points) {
                let route: string | any[] = []
                const routeLonLat = []
                autoRoute.id = ++lastId

                const polylineFormat = new Polyline()
                const feature = polylineFormat.readFeature(autoRoute.points)

                //@ts-ignore
                const geometry = feature.getGeometry()?.flatCoordinates

                for (let i = 0; i < geometry.length; i++) {
                  if (i % 2 !== 0) {
                    continue
                  }

                  const yCoordinate =
                    100 - ((geometry[i + 1] - 55.486211) / 0.480722) * 100
                  const xCoordinate =
                    ((geometry[i] - 37.172303) / 0.807089) * 100

                  route.push(`${xCoordinate},${yCoordinate}`)
                  routeLonLat.push([geometry[i], geometry[i + 1]])
                }

                route = route.join(' ')

                const coordinates = [
                  [
                    autoRoute['top_start_camera_longitude'] ||
                      autoRoute['start_location_lon'],
                    autoRoute['top_start_camera_latitude'] ||
                      autoRoute['start_location_lat'],
                  ],
                  [
                    autoRoute['top_end_camera_longitude'] ||
                      autoRoute['end_location_lon'],
                    autoRoute['top_end_camera_latitude'] ||
                      autoRoute['end_location_lat'],
                  ],
                ]

                routes.push({
                  id: autoRoute.id,
                  coordinates,
                  route,
                  routeLonLat,
                  paths: autoRoute.points,
                  periodId: autoRoute.periodId,
                })
              }
            }
          )
          this.routes = routes
          this.setAreRoutesUpdated(true)
          //routesSegments
          const segmentMap = new Map()
          const routesOverlay: any = {}
          this.routes.forEach((route: any) => {
            route.route
              .split(' ')
              .forEach((point: any, i: number, array: any[]) => {
                if (i === array.length - 1) {
                  return
                }

                const segmentName = `${point} ${array[i + 1]}`
                const segmentObj = segmentMap.get(segmentName)

                if (!segmentObj) {
                  segmentMap.set(segmentName, {
                    position: point,
                    coordinates: [
                      route.routeLonLat[i],
                      route.routeLonLat[i + 1],
                    ],
                    routesId: [route.id],
                    route: segmentName,
                  })
                } else {
                  segmentObj.routesId.push(route.id)
                  segmentMap.set(segmentName, segmentObj)

                  segmentObj.routesId.forEach((routeIdx: number) => {
                    if (routeIdx in routesOverlay) {
                      routesOverlay[routeIdx]++
                    } else {
                      routesOverlay[routeIdx] = 1
                    }
                  })
                }
              })
          })
          this.segmentMap = Array.from(segmentMap.values()).sort(
            (a, b) => a.routesId.length - b.routesId.length
          )
        }
        //locations
        if (Object.keys(this.userTransActivity?.trip_locations).length) {
          const transports = ['auto', 'subway', 'ngpt']
          const activityData: any = []

          for (const transport of transports) {
            if (
              this?.userTransActivity?.trip_locations &&
              transport in this.userTransActivity.trip_locations
            ) {
              this.userTransActivity?.trip_locations[transport].forEach(
                (item: any) => {
                  item.transportType = transport
                  if (item.parking_count > 0) {
                    item.icon = 'parking'
                  } else if (item.home_flag > 0) {
                    item.icon = 'home'
                  } else if (item.work_flag > 0) {
                    item.icon = 'work'
                  } else if (item.helpmos) {
                    item.icon = 'camera'
                  } else {
                    item.icon = transport
                  }

                  if (!item.parking_count) {
                    item.parking_count = ''
                  }
                  if (!item.work_flag) {
                    item.work_flag = ''
                  }
                  if (item['line']) {
                    item['subway_line_color'] = subwayLinesMap.get(item['line'])
                  }

                  if (!item['total_visit_count']) {
                    item.count = parseInt(item['parking_count'], 10) || 0
                  } else {
                    item.count = parseInt(item['total_visit_count'], 10) || 0
                    delete item['total_visit_count']
                  }
                  const geoJson = geoData.get(item.district)
                  item.districtId = parseInt(geoJson?.properties['OKATO'], 10)
                  activityData.push(item)
                }
              )
            }
          }

          if (activityData.length) {
            this.locations = activityData.sort(
              (a: any, b: any) =>
                parseInt(b['total_visit_count']) -
                parseInt(a['total_visit_count'])
            )
            this.setAreLocationsUpdated(true)
          }
        }
        //evacuations
        if (this.userTransActivity?.auto_evacuation?.length) {
          const dataMap = new Map()
          let lastId = 0

          this.userTransActivity?.auto_evacuation.forEach((location: any) => {
            const geoJson = geoData.get(location.evacuation_district)
            const districtId = parseInt(geoJson?.properties['OKATO'], 10)

            const title =
              location.evacuation_district ||
              location.evacuation_division ||
              `(${location.evacuation_latitude}, ${location.evacuation_longitude})`

            let entry = dataMap.get(title)
            if (!entry) {
              const latitude = location.evacuation_latitude
              const longitude = location.evacuation_longitude
              entry = {
                id: `evacuation-${lastId++}`,
                districtId,
                division: location.evacuation_division,
                title,
                location_lat: latitude,
                location_lon: longitude,
                evacuation_reason_type: location.evacuation_reason_type,
                evacuation_year: location.evacuation_year,
                transportType: 'auto',
                icon: 'evacuator',
                district: location.evacuation_district,
              }
            }
            dataMap.set(title, entry)
          })

          const evacs = Array.from(dataMap.values())
          if (evacs.length) {
            evacs.forEach((location) => this.locations.push(location))
            this.setAreLocationsUpdated(true)
          }
        }
        //subway_sales
        if (this.userTransActivity?.subway_sales?.length) {
          const dataMap = new Map()
          let lastId = 0

          this.userTransActivity.subway_sales.forEach((location: any) => {
            const title =
              location['entrance'] ||
              location['station'] ||
              location['line'] ||
              location['district'] ||
              location['division'] ||
              `(${location['location_latitude']}, ${location['location_longitude']})`
            const station = location['station']

            let entry = dataMap.get(title)
            if (!entry) {
              const latitude =
                location['station_latitude'] || location['location_latitude']
              const longitude =
                location['station_longitude'] || location['location_longitude']

              entry = {
                id: `sales-${lastId++}`,
                title,
                station,
                icon: 'sales',
                location_lat: latitude,
                location_lon: longitude,
                transportType: 'sales',
                district: location.district,
                count: parseInt(location['transaction_cnt'], 10),
              }
            }
            dataMap.set(title, entry)
          })

          const locs = Array.from(dataMap.values())
          if (locs.length) {
            locs.forEach((location) => this.locations.push(location))
            this.setAreLocationsUpdated(true)
          }
        }
        //districts
        if (this.locations.length) {
          const districtsMap = new Map()
          this.locations.forEach((location: any) => {
            if (!location.district) {
              return
            }

            let entry = districtsMap.get(location.district)
            const geoJson = geoData.get(location.district)
            if (!entry) {
              entry = {
                id: parseInt(geoJson?.properties['OKATO'], 10),
                name: location.district,
                geoJson,
                counts: {
                  all: 0,
                  auto: 0,
                  subway: 0,
                  ngpt: 0,
                  parking: 0,
                  sales: 0,
                },
              }
            }
            if (location.count) {
              entry.counts.all += +location.count
              entry.counts[location.transportType] += +location.count
            }
            districtsMap.set(location.district, entry)
          })
          this.districts = Array.from(districtsMap.values()).sort(
            (a, b) => b.counts.all - a.counts.all
          )
          this.setAreDistrictsUpdated(true)
        }
        //yandex_food
        if (this.userTransActivity?.yandex_food?.length) {
          this.userTransActivity.yandex_food.forEach((location: any) => {
            return this.yandexFood.push({
              ...location,
              icon: 'yandex',
              location_lon: location.yandex_longitude,
              location_lat: location.yandex_latitude,
            })
          })
        }
        //delivery_food
        if (this.userTransActivity?.delivery_food?.length) {
          this.userTransActivity.delivery_food.forEach((location: any) => {
            return this.deliveryClub.push({
              ...location,
              icon: 'delivery',
              location_lon: location.longitude,
              location_lat: location.latitude,
            })
          })
        }
        //last locations
        if (this.userTransActivity?.camera_check?.length) {
          const newLoc: any = []
          this.userTransActivity.camera_check.forEach(
            (location: any, idx: number) => {
              let icon
              if (idx === 0) {
                icon = 'startFlag'
              } else if (
                idx ===
                this.userTransActivity.camera_check.length - 1
              ) {
                icon = 'endFlag'
              } else {
                icon = 'auto'
              }

              const location_lon =
                location.gps_x !== '0.0'
                  ? parseFloat(location.gps_x)
                  : parseFloat(location['camera_longitude'])

              const location_lat =
                location.gps_y !== '0.0'
                  ? parseFloat(location.gps_y)
                  : parseFloat(location['camera_latitude'])

              newLoc.push({
                ...location,
                icon,
                location_lon,
                location_lat,
              })
            }
          )
          this.lastLocations = newLoc
        }
        //velobike
        if (this.userTransActivity?.velobike_sessions?.length) {
          const newLocs: any = []
          const pushLoc = (loc: any, idx: number) => {
            newLocs.push({
              ...loc,
              count: 1,
              day: [loc.day],
              session_id: [loc.session_id],
              session_dur: [loc.session_dur],
              session_distance: [loc.session_distance],
              start_session_dttm: [loc.start_session_dttm],
              end_session_dttm: [loc.end_session_dttm],
              id: `velobike_${idx}`,
            })

            if (
              (loc.start_latitude === loc.end_latitude &&
                loc.start_longitude === loc.end_longitude) ||
              loc.start_address === loc.end_address
            ) {
              newLocs.find(
                (i: any) => i.id === `velobike_${idx}`
              ).sameStartEnd = true
            }
            return
          }

          this.userTransActivity.velobike_sessions
            .sort((a: any, b: any) =>
              a.start_session_dttm < b.start_session_dttm ? -1 : 0
            )
            .forEach((loc: any, idx: number) => {
              if (idx === 0) {
                return pushLoc(loc, idx)
              }

              const sameLocIdx = newLocs.findIndex(
                (newLoc: any) =>
                  (newLoc.start_latitude === loc.start_latitude &&
                    newLoc.start_longitude === loc.start_longitude &&
                    newLoc.end_latitude === loc.end_latitude &&
                    newLoc.end_longitude === loc.end_longitude &&
                    newLoc.id !== `velobike_${idx}`) ||
                  (newLoc.start_address === loc.start_address &&
                    newLoc.end_address === loc.end_address &&
                    newLoc.id !== `velobike_${idx}`)
              )

              if (sameLocIdx !== -1) {
                newLocs[sameLocIdx].count += 1
                newLocs[sameLocIdx].day.push(loc.day)
                newLocs[sameLocIdx].session_id.push(loc.session_id)
                newLocs[sameLocIdx].session_dur.push(loc.session_dur)
                newLocs[sameLocIdx].session_distance.push(loc.session_distance)
                newLocs[sameLocIdx].start_session_dttm.push(
                  loc.start_session_dttm
                )
                newLocs[sameLocIdx].end_session_dttm.push(loc.end_session_dttm)
              } else {
                pushLoc(loc, idx)
              }
            })
          this.velobikes = newLocs
        }
        //pochta
        if (this.userTransActivity?.pochta?.length) {
          const newLocs: any[] = []

          const pushLoc = (loc: any, idx: number) => {
            if (!loc.coords) {
              return
            }

            const coords = loc?.coords?.split(';')
            newLocs.push({
              ...loc,
              count: 1,
              location_lon: coords.length ? coords[0] : null,
              location_lat: coords.length ? coords[1] : null,
              delivery_local_dts: [loc.delivery_local_dts],
              fio_rpo_rcpn_name: [loc.fio_rpo_rcpn_name],
              fio_rpo_sndr_name: [loc.fio_rpo_sndr_name],
              phone: [loc.phone],
              icon: 'pochta',
              id: `pochta_${idx}`,
            })
          }

          this.userTransActivity.pochta
            .sort((a: any, b: any) =>
              a.delivery_local_dts < b.delivery_local_dts ? -1 : 0
            )
            .forEach((location: any, idx: number) => {
              if (idx === 0) {
                return pushLoc(location, idx)
              }

              const sameLocIdx = newLocs.findIndex(
                (loc: any) => loc.coords === location.coords
              )

              if (sameLocIdx !== -1) {
                newLocs[sameLocIdx].count += 1
                newLocs[sameLocIdx].delivery_local_dts.push(
                  location.delivery_local_dts
                )
                newLocs[sameLocIdx].fio_rpo_rcpn_name.push(
                  location.fio_rpo_rcpn_name
                )
                newLocs[sameLocIdx].fio_rpo_sndr_name.push(
                  location.fio_rpo_sndr_name
                )
                newLocs[sameLocIdx].phone.push(location.phone)
              } else {
                pushLoc(location, idx)
              }
            })

          if (newLocs.length) {
            newLocs.forEach((loc) => this.locations.push(loc))
            this.setAreLocationsUpdated(true)
          }
        }
        //dtp
        if (this.userTransActivity?.road_accident?.length) {
          this.userTransActivity.road_accident.forEach(
            ({ dtp_latitude, dtp_longitude, dtp_dttm }: any, idx: number) => {
              this.locations.push({
                location_lat: dtp_latitude,
                location_lon: dtp_longitude,
                date: dtp_dttm,
                icon: 'dtp',
                id: `dtp_${idx}`,
              })
            }
          )
          this.setAreLocationsUpdated(true)
        }

        this.isDataRequested = true
      })
    }
  }

  setAreLocationsUpdated = (areLocationsUpdated: boolean) => {
    this.areLocationsUpdated = areLocationsUpdated
  }

  setAreRoutesUpdated = (areRoutesUpdated: boolean) => {
    this.areRoutesUpdated = areRoutesUpdated
  }

  setAreDistrictsUpdated = (areDistrictsUpdated: boolean) => {
    this.areDistrictsUpdated = areDistrictsUpdated
  }

  setError(err: string) {
    this.error = err
  }

  setProfileMapError = (profileMapError: string) => {
    this.profileMapError = profileMapError
  }

  setUserPhoto = (photo: EforUserPhoto | null) => {
    this.userPhoto = photo
  }

  setPossibleSelectors = (possibleSelectors: string[]) => {
    this.possibleSelectors = possibleSelectors
  }

  clearMapStore() {
    this.routes = []
    this.areRoutesUpdated = false
    this.districts = []
    this.areDistrictsUpdated = false
    this.locations = []
    this.areLocationsUpdated = false
    this.segmentMap = []
    this.lastLocations = []
    this.vkGeoData = null
    this.isAuth = false
    this.isDataRequested = false
    this.error = ''
    this.userTransActivity = null
    this.isDataRequested = false
    this.userPhoto = null
    this.possibleSelectors = []
    this.coddFines = []
  }
}

export default MapStore
