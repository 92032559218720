import { Button, FormControlLabel, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CheckboxesContainer = styled(Stack)({
  minWidth: '312px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  maxHeight: '600px',
  overflowY: 'auto',
})

export const CheckboxLabel = styled(FormControlLabel)({
  justifyContent: 'space-between',
  ml: 0,
  textAlign: 'start',
})

export const OpenCheckboxesBtn = styled(Button)({
  textTransform: 'none',
  height: '100%',
  backgroundColor: '#fff',
  color: '#030303',
  display: 'flex',
  justifyContent: 'space-between',
})
