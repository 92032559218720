import { Divider, Stack, Typography } from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useContext, useState } from 'react'
import parkingIcon from 'assets/parkingRelation.png'
import parkingPhone from 'assets/parkingPhone.png'
import autoParking from 'assets/autoParking.png'

//2023-09-26T00:00
const getDate = (dateString: string) => {
  const dateAndTime = dateString.split('T')

  const [YYYY, MM, DD] = dateAndTime[0].split('-')

  return `${DD}.${MM}.${YYYY}`
}

export const TransportRelations = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { transportProfile } = profileStore

  const [expanded, setExpanded] = useState('')
  const [innerExpanded, setInnerExpanded] = useState('')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : '')
      setInnerExpanded('')
    }

  const handleInnerChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setInnerExpanded(newExpanded ? panel : '')
    }

  if (!transportProfile?.parking_connections) {
    return null
  }

  // const phoneData = {
  //   "search": "79152479955",
  //   "parking": [
  //     {
  //       "Y008XX199": {
  //         "total_relation_cnt": "213",
  //         "max_dt": "2023-09-26T00:00",
  //         "phones": [
  //           {
  //             "phone": "79250190858",
  //             "info": {
  //               "total_relation_cnt": "74",
  //               "max_dt": "2023-09-26T00:00",
  //             },
  //           },
  //           {
  //             "phone": "79152479955",
  //             "info": {
  //               "total_relation_cnt": "213",
  //               "max_dt": "2023-09-26T00:00",
  //             },
  //           },
  //           {
  //             "phone": "79257686506",
  //             "info": {
  //               "total_relation_cnt": "28",
  //               "max_dt": "2022-08-16T00:00",
  //             },
  //           },
  //           {
  //             "phone": "79778001881",
  //             "info": {
  //               "total_relation_cnt": "4",
  //               "max_dt": "2017-07-10T00:00",
  //             },
  //           },
  //         ],
  //       }
  //     }
  //   ],
  // }
  // const grzData = {
  //   "search": "Y008XX199",
  //   "parking": [
  //     {
  //       "79250190858": {
  //         "total_relation_cnt": "74",
  //         "max_dt": "2023-09-26T00:00",
  //         "grz": [
  //           {
  //             "grz": "Y008XX199",
  //             "info": {
  //               "total_relation_cnt": "74",
  //               "max_dt": "2023-09-26T00:00",
  //             },
  //           },
  //           {
  //             "grz": "H111EE199",
  //             "info": {
  //               "total_relation_cnt": "6",
  //               "max_dt": "2023-07-20T00:00",
  //             },
  //           },
  //         ],
  //       }
  //     }
  //   ],
  // }

  const grzData =
    transportProfile.parking_connections?.search_type === 'grz'
      ? transportProfile.parking_connections
      : null
  const phoneData =
    transportProfile.parking_connections?.search_type === 'phone'
      ? transportProfile.parking_connections
      : null

  return (
    <Stack>
      {grzData ? (
        <Accordion
          sx={{ width: '100%', mb: 0.5 }}
          expanded={expanded === 'grzData'}
          onChange={handleChange('grzData')}
        >
          <AccordionSummary>
            <Stack direction='row' spacing={1}>
              <img src={parkingIcon} alt='parkingIcon' width={24} height={24} />

              <Typography
                textAlign='start'
                variant='body1'
                color='common.black'
              >
                Связки парковочных сессий {grzData?.search}
              </Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            {grzData.parking.map((parking: any) =>
              Object.entries(parking).map(([pKey, pValue]: any, pIdx) => (
                <Accordion
                  key={`${pKey}-${pIdx}`}
                  expanded={innerExpanded === `inner${pKey}`}
                  onChange={handleInnerChange(`inner${pKey}`)}
                >
                  <AccordionSummary>
                    <Stack direction='row' spacing={1}>
                      <img
                        src={parkingPhone}
                        alt='parkingPhone'
                        width={24}
                        height={24}
                      />

                      <Typography variant='body1' color='common.black'>
                        {pKey}
                      </Typography>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Stack>
                      {pValue?.total_relation_cnt ? (
                        <Stack
                          justifyContent='space-between'
                          mb={1}
                          direction='row'
                        >
                          <Typography
                            textAlign='left'
                            maxWidth='50%'
                            fontSize={14}
                            lineHeight='24px'
                            color='common.black'
                            fontWeight={700}
                          >
                            {`Парковки ${grzData?.search}:`}
                          </Typography>

                          <Typography
                            textAlign='right'
                            maxWidth='50%'
                            color='common.black'
                          >
                            <Typography
                              variant='body1'
                              fontWeight={700}
                              component='span'
                            >
                              {pValue.total_relation_cnt}
                            </Typography>
                          </Typography>
                        </Stack>
                      ) : null}

                      {pValue?.max_dt ? (
                        <Stack
                          justifyContent='space-between'
                          mb={1}
                          direction='row'
                        >
                          <Typography
                            textAlign='left'
                            maxWidth='50%'
                            fontSize={14}
                            lineHeight='24px'
                            color='common.black'
                          >
                            Последняя парковка:
                          </Typography>

                          <Typography
                            textAlign='right'
                            maxWidth='50%'
                            color='text.disabled'
                          >
                            <Typography variant='body1' component='span'>
                              {getDate(pValue.max_dt)}
                            </Typography>
                          </Typography>
                        </Stack>
                      ) : null}

                      <Divider />

                      {pValue?.grz?.length ? (
                        <Stack mt={1}>
                          {pValue.grz.map(
                            (grzInfo: any, grzInfoIdx: number) => (
                              <Stack key={`${Math.random()}+${grzInfoIdx}`}>
                                <Stack
                                  direction='row'
                                  justifyContent='space-between'
                                >
                                  {grzInfo?.grz ? (
                                    <Typography
                                      textAlign='left'
                                      maxWidth='50%'
                                      fontSize={14}
                                      lineHeight='24px'
                                      color='common.black'
                                    >
                                      Парковки {grzInfo.grz}
                                    </Typography>
                                  ) : null}

                                  {grzInfo.info?.total_relation_cnt ? (
                                    <Typography
                                      textAlign='right'
                                      maxWidth='50%'
                                      color='text.disabled'
                                    >
                                      {grzInfo.info.total_relation_cnt}
                                    </Typography>
                                  ) : null}
                                </Stack>

                                {grzInfo.info.max_dt ? (
                                  <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    mb={1}
                                  >
                                    <Typography
                                      textAlign='left'
                                      maxWidth='50%'
                                      fontSize={14}
                                      lineHeight='24px'
                                      color='common.black'
                                    >
                                      Последняя парковка
                                    </Typography>

                                    <Typography
                                      textAlign='right'
                                      maxWidth='50%'
                                      color='text.disabled'
                                    >
                                      {getDate(grzInfo.info.max_dt)}
                                    </Typography>
                                  </Stack>
                                ) : null}
                              </Stack>
                            )
                          )}
                        </Stack>
                      ) : null}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
          </AccordionDetails>
        </Accordion>
      ) : null}

      {phoneData ? (
        <Accordion
          sx={{ width: '100%', mb: 0.5 }}
          expanded={expanded === 'phoneData'}
          onChange={handleChange('phoneData')}
        >
          <AccordionSummary>
            <Stack direction='row' spacing={1}>
              <img src={parkingIcon} alt='parkingIcon' width={24} height={24} />

              <Typography
                textAlign='start'
                variant='body1'
                color='common.black'
              >
                Связки парковочных сессий {phoneData?.search}
              </Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            {phoneData.parking.map((parking: any) =>
              Object.entries(parking).map(([pKey, pValue]: any, pIdx) => (
                <Accordion
                  key={`${pKey}-${pIdx}`}
                  expanded={innerExpanded === `inner${pKey}`}
                  onChange={handleInnerChange(`inner${pKey}`)}
                >
                  <AccordionSummary>
                    <Stack direction='row' spacing={1}>
                      <img
                        src={autoParking}
                        alt='autoParking'
                        width={24}
                        height={24}
                      />

                      <Typography variant='body1' color='common.black'>
                        {pKey}
                      </Typography>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Stack>
                      {pValue?.total_relation_cnt ? (
                        <Stack
                          justifyContent='space-between'
                          mb={1}
                          direction='row'
                        >
                          <Typography
                            textAlign='left'
                            maxWidth='50%'
                            fontSize={14}
                            lineHeight='24px'
                            color='common.black'
                            fontWeight={700}
                          >
                            {`Парковок с телефона ${phoneData?.search}:`}
                          </Typography>

                          <Typography
                            textAlign='right'
                            maxWidth='50%'
                            color='common.black'
                          >
                            <Typography
                              variant='body1'
                              fontWeight={700}
                              component='span'
                            >
                              {pValue.total_relation_cnt}
                            </Typography>
                          </Typography>
                        </Stack>
                      ) : null}

                      {pValue?.max_dt ? (
                        <Stack
                          justifyContent='space-between'
                          mb={1}
                          direction='row'
                        >
                          <Typography
                            textAlign='left'
                            maxWidth='50%'
                            fontSize={14}
                            lineHeight='24px'
                            color='common.black'
                          >
                            Последняя парковка:
                          </Typography>

                          <Typography
                            textAlign='right'
                            maxWidth='50%'
                            color='text.disabled'
                          >
                            <Typography variant='body1' component='span'>
                              {getDate(pValue.max_dt)}
                            </Typography>
                          </Typography>
                        </Stack>
                      ) : null}

                      <Divider />

                      {pValue?.phones?.length ? (
                        <Stack mt={1}>
                          {pValue.phones.map(
                            (phoneInfo: any, phoneInfoIdx: number) => (
                              <Stack key={`${Math.random()}+${phoneInfoIdx}`}>
                                <Stack
                                  direction='row'
                                  justifyContent='space-between'
                                >
                                  {phoneInfo?.phone ? (
                                    <Typography
                                      textAlign='left'
                                      maxWidth='50%'
                                      fontSize={14}
                                      lineHeight='24px'
                                      color='common.black'
                                    >
                                      Парковки {phoneInfo.phone}
                                    </Typography>
                                  ) : null}

                                  {phoneInfo.info?.total_relation_cnt ? (
                                    <Typography
                                      textAlign='right'
                                      maxWidth='50%'
                                      color='text.disabled'
                                    >
                                      {phoneInfo.info.total_relation_cnt}
                                    </Typography>
                                  ) : null}
                                </Stack>

                                {phoneInfo.info.max_dt ? (
                                  <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    mb={1}
                                  >
                                    <Typography
                                      textAlign='left'
                                      maxWidth='50%'
                                      fontSize={14}
                                      lineHeight='24px'
                                      color='common.black'
                                    >
                                      Последняя парковка
                                    </Typography>

                                    <Typography
                                      textAlign='right'
                                      maxWidth='50%'
                                      color='text.disabled'
                                    >
                                      {getDate(phoneInfo.info.max_dt)}
                                    </Typography>
                                  </Stack>
                                ) : null}
                              </Stack>
                            )
                          )}
                        </Stack>
                      ) : null}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
          </AccordionDetails>
        </Accordion>
      ) : null}
    </Stack>
  )
})
