import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useState } from 'react'
import { Insights } from 'store/profileStore/profileStore.types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Box, Stack, Typography } from '@mui/material'
import imgSrc from 'assets/insights.jpg'

const cleanKey = (key: string) => key.replace(/\s*\[.*?\]\s*/g, '').trim()

interface Params {
  data: Record<string, any>
  topLevelKey?: boolean
}

const RenderData = observer<Params>(({ data, topLevelKey }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const entries = topLevelKey
    ? Object.entries(data).sort(([a], [b]) => a.localeCompare(b))
    : Object.entries(data)

  return (
    <>
      {entries.map(([key, value], index) => {
        const cleanedKey = cleanKey(key)
        const match = key.match(/\[(.*)\]/)
        const valueText = match ? match[1] : key

        return value && Object.keys(value).length ? (
          <Accordion
            key={index}
            sx={{ width: '100%' }}
            expanded={expanded === `insights-${index}`}
            onChange={handleChange(`insights-${index}`)}
          >
            <AccordionSummary>
              <Box
                sx={{
                  mr: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img src={imgSrc} alt='insights' width={22} height={22} />
              </Box>
              <Typography
                variant='body1'
                color='common.black'
                textAlign='start'
              >
                {cleanedKey}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RenderData data={value} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Stack sx={{ width: '100%', m: '1px 0 5px 0' }} key={index}>
            <Stack
              sx={{ width: '100%', p: 1 }}
              direction='row'
              justifyContent='space-between'
            >
              <Typography variant='body1' color='common.black'>
                {cleanedKey}
              </Typography>
              <Typography
                variant='body1'
                width='50%'
                textAlign='end'
                color='text.disabled'
                sx={{ wordBreak: 'break-word' }}
              >
                {valueText}
              </Typography>
            </Stack>
          </Stack>
        )
      })}
    </>
  )
})

export const TgInsights = observer<Insights['info']>(({ data }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <Accordion
      sx={{ width: '100%' }}
      expanded={expanded === 'insights'}
      onChange={handleChange('insights')}
    >
      <AccordionSummary>
        <Box
          sx={{
            mr: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={imgSrc} alt='insights' width={22} height={22} />
        </Box>
        <Typography variant='body1' color='common.black'>
          Интересы
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RenderData data={data} topLevelKey />
      </AccordionDetails>
    </Accordion>
  )
})
