/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import React from 'react'
import { useEffect, useContext, useState, useRef, useCallback } from 'react'
import * as olProj from 'ol/proj'
import 'ol/ol.css'
import s from './Map.module.scss'
import { observer } from 'mobx-react-lite'
import { Context } from 'index'
import { FullScreen, defaults as defaultControls } from 'ol/control'
import { defaults as defaultInteractions, Select } from 'ol/interaction'
import { View, Feature, Overlay } from 'ol'
import OsMap from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import VectorImgLayer from 'ol/layer/VectorImage'
import VectorSource from 'ol/source/Vector'
import { BingMaps } from 'ol/source'
import { Style, Fill, Stroke } from 'ol/style'
import { LineString, Point, Polygon } from 'ol/geom'
import { Polyline, GeoJSON } from 'ol/format'
import MapSelect from '../MapSelect/MapSelect'
import DC from 'assets/DC.png'
import yandexSvg from 'assets/yandexSvg.svg'
import { useParams } from 'react-router-dom'
import { FeaturePopup } from './FeaturePopup'
import { Stack } from '@mui/material'
import { legend } from '../MapSelect/MapSelect'

import {
  routeСolors,
  routeСolorsRgb,
  getRouteStyle,
  districtColors,
  getDistrictStyles,
  getFeatureIcon,
  clickableIcons,
  getVelobikeLocCoords,
} from './MapUtils'
import { MapFilter } from './MapFilter'
import { useTransportActivityData } from './useTransportActivityData'

import moscowDistrictsJson from 'utils/moscowDistricts.json'
import allHexagons from './allHexagons.json'
import { EforUserPhoto } from './EforUserPhoto'

export const Map = observer(({ mapId }: { mapId?: string | null }) => {
  olProj.useGeographic()
  const { rootStore } = useContext(Context)
  const { mapStore, profileStore } = rootStore

  const {
    radarId,
    userDetections,
    notSubscribedHexagons,
    detectionsWithPosts,
    isSaving,
  } = profileStore

  const {
    areLocationsUpdated,
    areRoutesUpdated,
    areDistrictsUpdated,
    hasOnlyRadarData,
    vkGeoData,
  } = mapStore

  const {
    districts,
    hasTransData,
    filtredLocations,
    filteredRoutes,
    activeFilters,
    setActiveFilters,
    filteredRouteSegments,
  } = useTransportActivityData()

  const mapRef = useRef()
  const popupRef = useRef(null)
  const popupCloserRef = useRef(null)
  const [popupData, setPopupData] = useState()
  const [popup, setPopup] = useState(new Overlay({}))

  const [locationsLayer, setLocationsLayer] = useState()
  const [lastLocationsLayer, setLastLocationsLayer] = useState()
  const [velobikesLayer, setVelobikesLayer] = useState()
  const [routesLayer, setRoutesLayer] = useState()
  const [colorRouteLayer, setColorRouteLayer] = useState()
  const [districtLayer, setDistrictLayer] = useState()
  const [subscrHexsLayer, setSubscrHexsLayer] = useState()
  const [notsubscrHexsLayer, setNotsubscrHexsLayer] = useState()
  const [hexsBgLayer, setHexsBgLayer] = useState()
  const [emptyHexsLayer, setEmptyHexsLayer] = useState()
  const [tileLayer, setTileLayer] = useState()
  const [vkGeoLayer, setVkGeoLayer] = useState()

  const [isLocationsVis, setIsLocationsVis] = useState(false)
  const [isLastLocationsVis, setIsLastLocationsVis] = useState(false)
  const [isRoutesVis, setIsRoutesVis] = useState(false)
  const [isDistrictsVis, setIsDistrictsVis] = useState(false)
  const [isYandexFoodVis, setIsYandexFoodVis] = useState(false)
  const [isDeliveryClubVis, setIsDeliveryClubVis] = useState(false)
  const [isVelobikesVis, setIsVelobikesVis] = useState(false)
  const [areDetectionsVis, setAreDetectionsVis] = useState(false)
  const [areNotSubscrHexVis, setAreNotSubscrHexVis] = useState(false)
  const [selectedHexagonId, setSelectedHexagonId] = useState(null)
  const [selectedFeature, setSelectedFeature] = useState(null)
  const [isVkGeoVis, setIsVkGeoVis] = useState(false)

  const [initialDetectionsStyles, setInitialDetectionsStyles] = useState(null)
  const [initialPostsStyles, setInitialPostsStyles] = useState(null)

  const [zoom] = useState(9.5)
  const [mapView, setMapView] = useState()
  const [map] = useState(
    new OsMap({
      controls: defaultControls({ attribution: false }).extend([
        new FullScreen(),
      ]),
      interactions: defaultInteractions({
        pinchRotate: false,
        shiftDragZoom: false,
        altShiftDragRotate: false,
      }),
      target: '',
    })
  )

  const params = useParams()?.data

  const increaseIconSize = useCallback(
    (ev) => {
      const velobikeLayer = map
        .getLayers()
        .array_.find((layer) => layer.className_ === 'velobikes_layer')
      const velobikeFeatures = velobikeLayer.getSource().getFeatures()

      if (ev?.selected[0]?.values_?.id?.includes('velobike')) {
        const shouldIncreaseSize = velobikeFeatures.filter(
          (f) => f.values_.id === ev.selected[0].values_.id
        )

        shouldIncreaseSize.forEach((feat) =>
          feat.setStyle(
            new Style({
              image: getFeatureIcon(feat.values_.iconType, true),
            })
          )
        )
      }

      if (ev?.deselected?.length) {
        const shouldDecreaseSize = velobikeFeatures.filter(
          (f) => f.values_.id === ev.deselected[0].values_.id
        )

        if (
          !ev?.selected?.length ||
          ev.deselected[0].values_.id !== ev.selected[0].values_.id
        ) {
          shouldDecreaseSize.forEach((feat) =>
            feat.setStyle(
              new Style({
                image: getFeatureIcon(feat.values_.iconType),
              })
            )
          )
        }
      }
    },
    [map]
  )

  useEffect(() => {
    if (!mapStore.isAuth && !profileStore.isAuth) {
      mapStore.authUser()
    }
  }, [mapStore, profileStore.isAuth])

  useEffect(() => {
    if (params && (mapStore.isAuth || profileStore.isAuth)) {
      mapStore.fetchMapData(params.split('&'), mapId)
    }
  }, [mapId, mapStore, params, profileStore.isAuth])

  const clickHandler = useCallback(
    (map, popup) => (e) => {
      const pixel = e.pixel
      const feature = map.forEachFeatureAtPixel(pixel, (_feature) => _feature)

      const bikeFeatures = []
      map.forEachFeatureAtPixel(pixel, (_feature) => {
        if (_feature?.values_?.id?.startsWith('velobike_')) {
          bikeFeatures.push(_feature)
        }
      })

      if (
        popupCloserRef.current &&
        !feature?.values_?.id?.startsWith('flag_') &&
        !feature?.values_?.id?.startsWith('pochta_') &&
        !feature?.values_?.id?.startsWith('velobike_') &&
        !feature?.values_?.id?.startsWith('subscrHex_') &&
        !feature?.values_?.id?.startsWith('notSubscrHex_') &&
        !feature?.values_?.id?.startsWith('vkGeo_') &&
        !feature?.values_?.id?.startsWith('codd_')
      ) {
        return closePopup(popup, popupCloserRef, pixel)
      }

      if (feature?.values_?.id?.startsWith('subscrHex_')) {
        setSelectedHexagonId(feature?.values_?.data.subscrHexId)

        setSelectedFeature((prevFeature) => {
          if (prevFeature && prevFeature.values_.id !== feature.values_.id) {
            const style = new Style({
              fill: new Fill({ color: prevFeature.style_.fill_.color_ }),
              stroke: undefined,
            })

            prevFeature.setStyle(style)
          }
          return feature
        })

        const style = new Style({
          fill: new Fill({ color: feature.style_.fill_.color_ }),
          stroke: new Stroke({ color: 'black', width: 0.3 }),
        })

        feature.setStyle(style)
      }

      if (feature?.values_?.id?.startsWith('notSubscrHex_')) {
        setSelectedHexagonId(feature?.values_?.data.notSubscrHexId)

        setSelectedFeature((prevFeature) => {
          if (prevFeature && prevFeature.values_.id !== feature.values_.id) {
            const style = new Style({
              fill: new Fill({ color: prevFeature.style_.fill_.color_ }),
              stroke: undefined,
            })

            prevFeature.setStyle(style)
          }

          return feature
        })

        const style = new Style({
          fill: new Fill({ color: feature.style_.fill_.color_ }),
          stroke: new Stroke({ color: 'black', width: 0.3 }),
        })

        feature.setStyle(style)
      }

      let data

      if (bikeFeatures.length) {
        data = bikeFeatures.map((_feature) => _feature.values_.data)
      } else if (feature?.values_?.id?.startsWith('vkGeo_')) {
        if (!feature?.values_?.data) {
          return
        }

        data = {
          ...feature?.values_?.data,
          source: 'eforVkGeo',
        }
      } else {
        data = feature?.values_?.data
      }

      setPopupData(data)
      popup.setPosition(e.coordinate)
    },
    []
  )

  const closePopup = (popup, closerRef) => {
    if (closerRef) {
      closerRef.current.blur()
    }

    popup.setPosition(undefined)
    setPopupData(null)
    setSelectedHexagonId(null)
  }

  //hexagons
  const hideDetections = useCallback(() => {
    const isNotSubVis = map
      ?.getLayers()
      ?.array_?.find((layer) =>
        layer?.className_?.includes('notSubscrHexsLayer')
      )?.values_?.visible

    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('subscrHexsLayer') && isNotSubVis) {
        layer.setVisible(false)
        setAreDetectionsVis(false)
      } else if (
        !isNotSubVis &&
        (layer.className_.includes('subscrHexsLayer') ||
          layer.className_.includes('emptyHexsLayer') ||
          layer.className_.includes('hexsBgLayer'))
      ) {
        layer.setVisible(false)
        setAreDetectionsVis(false)
      }
    })
  }, [map])

  //vk geo
  const hideVkGeo = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('vkGeoLayer')) {
        layer.setVisible(false)
        setIsVkGeoVis(false)
      }
    })
  }, [map])

  //notSubscribedHexagons
  const hideNotSubscrHexs = useCallback(() => {
    const isSubLayerVis = map
      ?.getLayers()
      ?.array_?.find((layer) => layer?.className_?.includes('subscrHexsLayer'))
      ?.values_?.visible

    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('notSubscrHexsLayer') && isSubLayerVis) {
        layer.setVisible(false)
        setAreNotSubscrHexVis(false)
      } else if (
        !isSubLayerVis &&
        (layer.className_.includes('notSubscrHexsLayer') ||
          layer.className_.includes('emptyHexsLayer') ||
          layer.className_.includes('hexsBgLayer'))
      ) {
        layer.setVisible(false)
        setAreNotSubscrHexVis(false)
      }
    })
  }, [map])

  //locations visibility handlers
  const showLocations = () => {
    hideLastLocations()

    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('all_')) {
        layer.setVisible(true)
        setIsLocationsVis(true)
      }
    })
    hideNotSubscrHexs()
    hideDetections()
    hideVkGeo()
  }
  const hideLocations = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('all_')) {
        layer.setVisible(false)
        setIsLocationsVis(false)
      }
    })
  }, [map])

  //lastLocations visibility handlers
  const showLastLocations = useCallback(() => {
    hideLocations()

    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('last_loc_')) {
        layer.setVisible(true)
        setIsLastLocationsVis(true)
        layer.values_.zIndex = 2
      }
    })
    hideNotSubscrHexs()
    hideDetections()
    hideVkGeo()
  }, [hideDetections, hideLocations, hideNotSubscrHexs, hideVkGeo, map])
  const hideLastLocations = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('last_loc_')) {
        layer.setVisible(false)
        setIsLastLocationsVis(false)
      }
    })
  }, [map])

  //velobikes visibility handlers
  const showVelobikes = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('velobikes')) {
        layer.setVisible(true)
        setIsVelobikesVis(true)
      }
    })
    hideNotSubscrHexs()
    hideDetections()
  }, [hideDetections, hideNotSubscrHexs, map])
  const hideVelobikes = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('velobikes')) {
        layer.setVisible(false)
        setIsVelobikesVis(false)
      }
    })
  }, [map])

  //yandex visibility handlers
  const showYandexFood = () => {
    if (map && mapStore.yandexFood?.length) {
      mapStore.yandexFood.forEach((item, idx) => {
        const element = document.createElement('img')
        element.style.width = '18px'
        element.style.height = '18px'
        element.style.userSelect = 'none'
        element.src = yandexSvg
        const points = [
          parseFloat(item['location_lon']),
          parseFloat(item['location_lat']),
        ]

        const marker = new Overlay({
          id: `yandex_${idx}`,
          position: points,
          element,
          positioning: 'top-center',
        })
        map.addOverlay(marker)
      })
    }
    hideNotSubscrHexs()
    hideDetections()
    setIsYandexFoodVis(true)
    hideVkGeo()
  }
  const hideYandexFood = useCallback(() => {
    if (map) {
      map.getOverlays().array_.forEach((location) => {
        if (location.id && location.id.includes('yandex_')) {
          location.setPosition(undefined)
        }
      })
    }
    setIsYandexFoodVis(false)
  }, [map])

  //delivery visibility handlers
  const showDeliveryClub = () => {
    if (map && mapStore.deliveryClub?.length) {
      mapStore.deliveryClub.forEach((item, idx) => {
        const element = document.createElement('img')
        element.style.width = '18px'
        element.style.height = '18px'
        element.style.userSelect = 'none'
        element.src = DC
        const points = [
          parseFloat(item['location_lon']),
          parseFloat(item['location_lat']),
        ]

        const marker = new Overlay({
          id: `delivery_${idx}`,
          position: points,
          element,
          positioning: 'top-center',
        })
        map.addOverlay(marker)
      })
    }
    hideNotSubscrHexs()
    hideDetections()
    setIsDeliveryClubVis(true)
    hideVkGeo()
  }
  const hideDeliveryClub = useCallback(() => {
    if (map) {
      map.getOverlays().array_.forEach((location) => {
        if (location.id && location.id.includes('delivery_')) {
          location.setPosition(undefined)
        }
      })
    }
    setIsDeliveryClubVis(false)
  }, [map])

  //routes visibility handlers
  const showRoutes = () => {
    map.getLayers().array_.forEach((layer) => {
      if (
        layer.className_.includes('routes') ||
        layer.className_.includes('colorRoutesLayer')
      ) {
        layer.setVisible(true)
        setIsRoutesVis(true)
      }
    })
    hideNotSubscrHexs()
    hideDetections()
    hideVkGeo()
  }
  const hideRoutes = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (
        layer.className_.includes('routes') ||
        layer.className_.includes('colorRoutesLayer')
      ) {
        layer.setVisible(false)
        setIsRoutesVis(false)
      }
    })
  }, [map])

  //districts visibility handlers
  const showDistricts = () => {
    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('districts')) {
        layer.setVisible(true)
        setIsDistrictsVis(true)
      }
    })
    hideNotSubscrHexs()
    hideDetections()
    hideVkGeo()
  }
  const hideDistricts = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('districts')) {
        layer.setVisible(false)
        setIsDistrictsVis(false)
      }
    })
  }, [map])

  const hideTransportInfo = useCallback(() => {
    hideLocations()
    hideLastLocations()
    hideVelobikes()
    hideRoutes()
    hideDistricts()
    hideYandexFood()
    hideDeliveryClub()
  }, [
    hideDeliveryClub,
    hideDistricts,
    hideLastLocations,
    hideLocations,
    hideRoutes,
    hideVelobikes,
    hideYandexFood,
  ])

  //detections
  const showDetections = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (
        layer.className_.includes('subscrHexsLayer') ||
        layer.className_.includes('emptyHexsLayer') ||
        layer.className_.includes('hexsBgLayer')
      ) {
        layer.setVisible(true)
        setAreDetectionsVis(true)
      } else if (
        !layer.className_.includes('ol-layer') &&
        !layer.className_.includes('notSubscrHexsLayer')
      ) {
        layer.setVisible(false)
      }
    })

    hideTransportInfo()
    hideVkGeo()
  }, [hideTransportInfo, hideVkGeo, map])

  const showNotSubscrHexs = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (
        layer.className_.includes('notSubscrHexsLayer') ||
        layer.className_.includes('emptyHexsLayer') ||
        layer.className_.includes('hexsBgLayer')
      ) {
        layer.setVisible(true)
        setAreNotSubscrHexVis(true)
      } else if (
        !layer.className_.includes('ol-layer') &&
        !layer.className_.includes('subscrHexsLayer')
      ) {
        layer.setVisible(false)
      }
    })

    hideTransportInfo()
    hideVkGeo()
  }, [hideTransportInfo, hideVkGeo, map])

  //vkGeo visibility handlers
  const showVkGeo = useCallback(() => {
    map.getLayers().array_.forEach((layer) => {
      if (layer.className_.includes('vkGeoLayer')) {
        layer.setVisible(true)
        setIsVkGeoVis(true)
      }
    })

    hideTransportInfo()
    hideNotSubscrHexs()
    hideDetections()
  }, [hideDetections, hideNotSubscrHexs, hideTransportInfo, map])

  //set tileLayer + mapView
  useEffect(() => {
    if (map) {
      map.setTarget('map')
      setTileLayer(
        new TileLayer({
          source: new BingMaps({
            className: 'tileLayer',
            preload: Infinity,
            key: 'Au-hpzWQ9Ry_MF_eLWD57436BjnuUCz5lTFKtpKdwN80xjdsOZscgDUugcjARfb0',
            culture: 'ru',
            imagerySet: 'CanvasGray',
          }),
        })
      )
      setMapView(
        new View({
          center: mapId ? [37.61, 55.76] : [37.61, 55.66],
          zoom,
        })
      )

      const _popup = new Overlay({
        element: popupRef.current,
      })
      setPopup(_popup)

      popupCloserRef.current.onclick = () => closePopup(_popup, popupCloserRef)
      map.on('singleclick', clickHandler(map, _popup))

      const _select = new Select({
        filter: (feature) => feature?.values_?.id?.includes('velobike'),
      })
      _select.on('select', (event) => increaseIconSize(event, _select))
      map.addInteraction(_select)
    }
  }, [map, zoom, clickHandler, increaseIconSize, mapId])

  //set districtsSource
  useEffect(() => {
    let maxVisitCount = 0
    districts.forEach((district) => {
      if (maxVisitCount < district.counts.all) {
        maxVisitCount = district.counts.all
      }
    })

    const features = []

    districts?.forEach((district) => {
      if (!district.geoJson || !district.counts.all) {
        return
      }

      const [feature] = new GeoJSON().readFeatures(district.geoJson)
      const colorIdx = Math.ceil((district.counts.all / maxVisitCount) * 5) - 1
      feature.set('districtId', district.id)
      feature.setStyle(getDistrictStyles(districtColors[colorIdx]))
      features.push(feature)
    })

    setDistrictLayer(
      new VectorImgLayer({
        className: 'districts',
        source: new VectorSource({
          features,
        }),
      })
    )
  }, [districts])

  //set routesLayer
  useEffect(() => {
    const features = filteredRoutes.map((item) => {
      const polyline = item.paths
      const polylineFormat = new Polyline()
      const feature = polylineFormat.readFeature(polyline)
      feature.set('routeId', item.id)
      return feature
    })

    setRoutesLayer(
      new VectorImgLayer({
        className: 'routes',
        source: new VectorSource({
          features,
        }),
        style: getRouteStyle(),
      })
    )
  }, [filteredRoutes, activeFilters])

  //set colorRouteLayer
  useEffect(() => {
    let segmentMax = 0
    filteredRouteSegments.forEach((item) => {
      if (item.routesId.length > segmentMax) {
        segmentMax = item.routesId.length
      }
    })

    const features = []

    filteredRouteSegments.forEach((item) => {
      const colorIdx = Math.ceil(item.routesId.length / (segmentMax / 5))
      if (colorIdx === 0) {
        return
      }
      const points = [item.coordinates[0], item.coordinates[1]]
      const feature = new Feature(new LineString(points))
      feature.set('routeId', item.routesId[0])
      feature.setStyle(getRouteStyle(routeСolors[colorIdx]))
      return features.push(feature)
    })

    setColorRouteLayer(
      new VectorImgLayer({
        className: 'colorRoutesLayer',
        source: new VectorSource({
          features,
        }),
      })
    )
  }, [filteredRouteSegments])

  //set locationsLayer
  useEffect(() => {
    const features = filtredLocations
      .filter((loc) => activeFilters.includes(loc.transportType))
      .map((location, idx) => {
        const isClickable = clickableIcons.includes(location?.icon)
        const feature = new Feature({
          geometry: new Point([location.location_lon, location.location_lat]),
          id: isClickable ? `${location.icon}_${idx}` : null,
          data: isClickable ? location : null,
        })
        feature.setStyle(
          new Style({
            image: getFeatureIcon(location.icon || 'auto', false, location),
          })
        )
        return feature
      })

    setLocationsLayer(
      new VectorImgLayer({
        className: 'all_locations_layer',
        source: new VectorSource({
          features,
        }),
      })
    )
  }, [activeFilters, filtredLocations])

  //set lastLocationsLayer
  useEffect(() => {
    const locs = mapStore.lastLocations

    const features = locs.map((location, idx) => {
      const isFlagIcon = location.icon.toLowerCase().includes('flag')

      const feature = new Feature({
        geometry: new Point([location.location_lon, location.location_lat]),
        id: isFlagIcon ? `flag_${idx}` : null,
        data: isFlagIcon ? location : null,
      })
      feature.setStyle(
        new Style({
          image: getFeatureIcon(location.icon),
        })
      )
      return feature
    })

    const route = new LineString(
      locs.map((item) => [item.location_lon, item.location_lat])
    )

    const routeFeature = new Feature({
      type: 'route',
      geometry: route,
    })

    const styles = {
      route: new Style({
        stroke: new Stroke({
          width: 3,
          color: [30, 154, 223, 0.8],
        }),
      }),
    }

    setLastLocationsLayer(
      new VectorImgLayer({
        className: 'last_loc_layer',
        source: new VectorSource({
          features: [...features, routeFeature],
        }),
        style(feature) {
          return styles[feature.get('type')]
        },
      })
    )
  }, [mapStore.lastLocations])

  //set velobikes
  useEffect(() => {
    const locs = mapStore.velobikes
    const features = []

    locs.forEach((location) => {
      let feature

      if (location.sameStartEnd) {
        feature = new Feature({
          geometry: new Point(getVelobikeLocCoords(location)),
          id: location.id,
          data: location,
          iconType: 'velobikePurple',
        })
        feature.setStyle(
          new Style({
            image: getFeatureIcon(feature.values_.iconType),
          })
        )

        features.push(feature)
      } else {
        feature = new Feature({
          geometry: new Point(getVelobikeLocCoords(location)),
          id: location.id,
          data: { ...location, startLocation: true },
          iconType: 'velobikeGreen',
        })
        feature.setStyle(
          new Style({
            image: getFeatureIcon(feature.values_.iconType),
          })
        )
        features.push(feature)

        feature = new Feature({
          geometry: new Point(getVelobikeLocCoords(location, 'end')),
          id: location.id,
          data: { ...location, endLocation: true },
          iconType: 'velobikeRed',
        })
        feature.setStyle(
          new Style({
            image: getFeatureIcon(feature.values_.iconType),
          })
        )
        features.push(feature)
      }
    })

    setVelobikesLayer(
      new VectorImgLayer({
        className: 'velobikes_layer',
        source: new VectorSource({
          features,
        }),
      })
    )
  }, [mapStore.velobikes])

  //set vkGeoData
  useEffect(() => {
    if (vkGeoData) {
      const features = vkGeoData.map((item, idx) => {
        const feature = new Feature({
          geometry: new Point([item._source.long, item._source.lat]),
          id: `vkGeo_${idx}`,
          iconType: 'user',
          data: item?._source?.sizes?.url ? item : null, // если нет урла фотки - не добавляем прокидываем в попап инфу
        })

        feature.setStyle(
          new Style({
            image: getFeatureIcon(feature.values_.iconType),
          })
        )

        return feature
      })

      setVkGeoLayer(
        new VectorImgLayer({
          className: 'vkGeoLayer',
          source: new VectorSource({
            features,
          }),
        })
      )
    }
  }, [vkGeoData])

  useEffect(() => {
    map.addOverlay(popup)
  }, [map, popup])

  useEffect(() => {
    if (popup) {
      popup.setPosition(undefined)
      setPopupData(null)
    }
  }, [
    popup,
    isLocationsVis,
    isLastLocationsVis,
    isRoutesVis,
    isDistrictsVis,
    isYandexFoodVis,
    isDeliveryClubVis,
  ])

  //add view to map
  useEffect(() => {
    if (mapView) {
      map.setView(mapView)
    }
  }, [map, mapView])

  //add tileLayer to map
  useEffect(() => {
    if (tileLayer) {
      map.addLayer(tileLayer)
      return () => {
        if (map) {
          map.removeLayer(tileLayer)
        }
      }
    }
  }, [map, tileLayer])

  //add routesLayer to map
  useEffect(() => {
    if (routesLayer) {
      map.addLayer(routesLayer)
      return () => {
        if (map) {
          map.removeLayer(routesLayer)
        }
      }
    }
  }, [map, routesLayer])

  //add colorRouteLayer to map
  useEffect(() => {
    if (colorRouteLayer) {
      map.addLayer(colorRouteLayer)
      return () => {
        if (map) {
          map.removeLayer(colorRouteLayer)
        }
      }
    }
  }, [map, colorRouteLayer])

  //add districts to map
  useEffect(() => {
    if (districtLayer) {
      map.addLayer(districtLayer)
      return () => {
        if (map) {
          map.removeLayer(districtLayer)
        }
      }
    }
  }, [map, districtLayer])

  //add velobikes to map
  useEffect(() => {
    if (velobikesLayer) {
      map.addLayer(velobikesLayer)
      return () => {
        if (map) {
          map.removeLayer(velobikesLayer)
        }
      }
    }
  }, [map, velobikesLayer, hideVelobikes])

  //add lastLocationsLayer to map
  useEffect(() => {
    if (lastLocationsLayer) {
      map.addLayer(lastLocationsLayer)
      return () => {
        if (map) {
          map.removeLayer(lastLocationsLayer)
        }
      }
    }
  }, [map, lastLocationsLayer])

  //add vkGeo to the map
  useEffect(() => {
    if (vkGeoLayer) {
      map.addLayer(vkGeoLayer)

      return () => {
        if (map) {
          map.removeLayer(vkGeoLayer)
        }
      }
    }
  }, [map, vkGeoLayer])

  //add hexagons to the map
  //add notSubscrHexs to the map

  useEffect(() => {
    if (radarId) {
      let subscrHexsLayer
      let notSubscrHexsLayer
      let hexsBgLayer
      let emptyHexsLayer

      const _districts = moscowDistrictsJson.map((item) =>
        item.geo.coordinates[0].length === 1
          ? item.geo.coordinates.flat()
          : item.geo.coordinates
      )

      const moscowDistricts = _districts.map((districtCoords, idx) => {
        const feature = new Feature({
          geometry: new Polygon(districtCoords),
          id: `moscowDistrict_${idx}`,
        })

        const style = new Style({
          fill: new Fill({ color: 'rgba(140, 160, 220, 0.1)' }),
          stroke: new Stroke({ color: 'rgba(140, 160, 220, 3)', width: 2 }),
        })

        feature.setStyle(style)

        return feature
      })

      if (userDetections || notSubscribedHexagons) {
        let maxVisitCount = 0
        userDetections?.forEach(({ detection_count }) => {
          if (maxVisitCount < detection_count) {
            maxVisitCount = detection_count
          }
        })

        const subscrHexs = userDetections?.map(
          ({ geo, hexagon_id, detection_count, ...rest }) => {
            const colorIdx = Math.ceil(
              (detection_count / maxVisitCount) * 5 - 1
            )

            const rbgColor = routeСolorsRgb[colorIdx]

            const style = new Style({
              fill: new Fill({ color: `rgba(${rbgColor}, 0.5)` }),
            })

            const feature = new Feature({
              geometry: new Polygon([geo.coordinates[0]]),
              data: { ...rest, detection_count, subscrHexId: hexagon_id },
              id: `subscrHex_${hexagon_id}`,
            })

            feature.setStyle(style)

            return feature
          }
        )

        const notSubscrHexs = notSubscribedHexagons
          ?.filter((el) => el?.coordinates?.length)
          ?.map(({ coordinates, hexagon_id, ...rest }) => {
            const style = new Style({
              fill: new Fill({ color: `rgba(0, 240, 250, 0.8)` }),
            })

            const feature = new Feature({
              geometry: new Polygon([coordinates?.[0]]),
              data: { notSubscrHexId: hexagon_id, ...rest },
              id: `notSubscrHex_${hexagon_id}`,
            })

            feature.setStyle(style)

            return feature
          })

        const emptyHexs = allHexagons.map(({ geo }) => {
          const style = new Style({
            fill: new Fill({ color: 'rgba(140, 160, 220, 0.1)' }),
            stroke: new Stroke({ color: 'white', width: 0.4 }),
          })

          const feature = new Feature({
            geometry: new Polygon([geo.coordinates[0]]),
          })

          feature.setStyle(style)

          return feature
        })

        hexsBgLayer = new VectorImgLayer({
          className: 'hexsBgLayer',
          source: new VectorSource({
            features: moscowDistricts.flat(),
          }),
        })
        setHexsBgLayer(hexsBgLayer)

        emptyHexsLayer = new VectorImgLayer({
          className: 'emptyHexsLayer',
          source: new VectorSource({
            features: emptyHexs,
          }),
        })
        setEmptyHexsLayer(emptyHexsLayer)

        if (userDetections) {
          subscrHexsLayer = new VectorImgLayer({
            className: 'subscrHexsLayer',
            source: new VectorSource({
              features: subscrHexs,
            }),
          })

          setSubscrHexsLayer(subscrHexsLayer)
        }

        if (notSubscribedHexagons) {
          notSubscrHexsLayer = new VectorImgLayer({
            className: 'notSubscrHexsLayer',
            source: new VectorSource({
              features: notSubscrHexs,
            }),
          })

          setNotsubscrHexsLayer(notSubscrHexsLayer)
        }

        if (detectionsWithPosts) {
          const _detectionsStyles = subscrHexs?.map((det) => det.style_)
          const _postsStyles = notSubscrHexs?.map((det) => det.style_)

          setInitialDetectionsStyles(_detectionsStyles)
          setInitialPostsStyles(_postsStyles)
        }
      }

      return () => {
        if (map) {
          map.removeLayer(emptyHexsLayer)
          map.removeLayer(hexsBgLayer)
          map.removeLayer(subscrHexsLayer)
          map.removeLayer(notSubscrHexsLayer)
          setInitialPostsStyles(null)
          setInitialDetectionsStyles(null)
        }
      }
    }
  }, [map, notSubscribedHexagons, radarId, userDetections, detectionsWithPosts])

  useEffect(() => {
    if (hexsBgLayer) {
      map.addLayer(hexsBgLayer)

      return () => {
        if (map) {
          map.removeLayer(hexsBgLayer)
        }
      }
    }
  }, [hexsBgLayer, hideDetections, map])

  useEffect(() => {
    if (emptyHexsLayer) {
      map.addLayer(emptyHexsLayer)

      return () => {
        if (map) {
          map.removeLayer(emptyHexsLayer)
        }
      }
    }
  }, [emptyHexsLayer, map])

  useEffect(() => {
    if (subscrHexsLayer) {
      map.addLayer(subscrHexsLayer)

      if (hasOnlyRadarData) {
        showDetections()
      }

      return () => {
        if (map) {
          map.removeLayer(subscrHexsLayer)
        }
      }
    }
  }, [subscrHexsLayer, map, hasOnlyRadarData, showDetections])

  useEffect(() => {
    if (notsubscrHexsLayer) {
      map.addLayer(notsubscrHexsLayer)

      if (hasOnlyRadarData) {
        showNotSubscrHexs()
      }

      return () => {
        if (map) {
          map.removeLayer(notsubscrHexsLayer)
        }
      }
    }
  }, [notsubscrHexsLayer, map, hasOnlyRadarData, showNotSubscrHexs])

  useEffect(() => {
    if (!selectedHexagonId && selectedFeature) {
      const style = new Style({
        fill: new Fill({ color: selectedFeature.style_.fill_.color_ }),
        stroke: undefined,
      })

      selectedFeature.setStyle(style)
      setSelectedFeature(null)
    }
  }, [map, selectedHexagonId, selectedFeature])

  useEffect(() => {
    if (detectionsWithPosts && map) {
      const detectionsFeatures = map
        ?.getLayers()
        ?.array_?.find((layer) => layer?.className_ === 'subscrHexsLayer')
        ?.getSource()
        ?.getFeatures()

      const postsFeatures = map
        ?.getLayers()
        ?.array_?.find((layer) => layer?.className_ === 'notSubscrHexsLayer')
        ?.getSource()
        ?.getFeatures()

      if (areDetectionsVis && areNotSubscrHexVis) {
        detectionsFeatures?.forEach((detFeature) => {
          const id = detFeature.values_.id.slice(
            detFeature.values_.id.indexOf('_') + 1
          )

          if (detectionsWithPosts.some((det) => det.hexagon_id === id)) {
            const style = new Style({
              fill: new Fill({ color: '#e291e2' }),
              stroke: undefined,
            })

            detFeature.setStyle(style)
          }
        })

        postsFeatures?.forEach((postFeature) => {
          const id = postFeature.values_.id.slice(
            postFeature.values_.id.indexOf('_') + 1
          )

          if (
            detectionsWithPosts.some(
              (detFeature) => detFeature.hexagon_id === id
            )
          ) {
            const style = new Style({
              fill: new Fill({ color: '#e291e2' }),
              stroke: undefined,
            })

            postFeature.setStyle(style)
          }
        })
      } else if (areDetectionsVis || areNotSubscrHexVis) {
        detectionsFeatures?.forEach((detFeature, idx) => {
          const style = new Style({
            fill: new Fill({
              color: initialDetectionsStyles?.[idx]?.fill_?.color_,
            }),
            stroke: undefined,
          })

          detFeature.setStyle(style)
        })

        postsFeatures?.forEach((postFeature, idx) => {
          const style = new Style({
            fill: new Fill({ color: initialPostsStyles?.[idx]?.fill_?.color_ }),
            stroke: undefined,
          })

          postFeature.setStyle(style)
        })
      }
    }
  }, [
    initialPostsStyles,
    initialDetectionsStyles,
    areDetectionsVis,
    areNotSubscrHexVis,
    detectionsWithPosts,
    map,
  ])

  //add locationsLayer to map + control default map visibility
  useEffect(() => {
    if (locationsLayer) {
      map.addLayer(locationsLayer)
      hideLastLocations()
      hideVelobikes()
      hideVkGeo()

      if (!hasOnlyRadarData) {
        hideDetections()
        hideNotSubscrHexs()
      }

      if (areRoutesUpdated) {
        setIsRoutesVis(true)
        // hideDistricts()
        // hideLocations()
      }
      if (areLocationsUpdated) {
        setIsLocationsVis(true)
        // hideDistricts()
      }
      if (areDistrictsUpdated) {
        setIsDistrictsVis(true)
      }

      return () => {
        if (map) {
          map.removeLayer(locationsLayer)
        }
      }
    }
  }, [
    areDistrictsUpdated,
    areLocationsUpdated,
    areRoutesUpdated,
    hasOnlyRadarData,
    hideDetections,
    hideLastLocations,
    hideNotSubscrHexs,
    hideVelobikes,
    hideVkGeo,
    locationsLayer,
    map,
  ])

  useEffect(() => {
    if (hasOnlyRadarData) {
      if (userDetections?.length) {
        showDetections()
      }

      if (notSubscribedHexagons?.length) {
        showNotSubscrHexs()
      }
    }
  }, [
    hasOnlyRadarData,
    notSubscribedHexagons?.length,
    showDetections,
    showNotSubscrHexs,
    userDetections?.length,
  ])

  useEffect(() => {
    if (!hasTransData && !hasOnlyRadarData && vkGeoData) {
      showVkGeo()
      setIsVkGeoVis(true)
    }
  }, [hasOnlyRadarData, hasTransData, showVkGeo, vkGeoData])

  useEffect(() => {
    if (
      !mapStore?.routes?.length &&
      !mapStore?.locations?.length &&
      !mapStore?.districts?.length
    ) {
      if (mapStore.userTransActivity?.camera_check?.length) {
        showLastLocations()
      }

      if (mapStore.userTransActivity?.velobike_sessions?.length) {
        showVelobikes()
      }
    }
  }, [
    mapStore?.locations?.length,
    mapStore?.districts?.length,
    mapStore?.routes?.length,
    showLastLocations,
    showVelobikes,
    mapStore.userTransActivity,
  ])

  // const screensWithPallete = ['routes', 'districts']

  return (
    <>
      <EforUserPhoto />

      <div className={`${s.container}`}>
        {hasTransData ? (
          <MapFilter
            setActiveFilters={setActiveFilters}
            activeFilters={activeFilters}
          />
        ) : null}
        <MapSelect
          isVkGeoVis={isVkGeoVis}
          hideVkGeo={hideVkGeo}
          showVkGeo={showVkGeo}
          isLocationsVis={isLocationsVis}
          isLastLocationsVis={isLastLocationsVis}
          isVelobikesVis={isVelobikesVis}
          isRoutesVis={isRoutesVis}
          isDistrictsVis={isDistrictsVis}
          isYandexFoodVis={isYandexFoodVis}
          isDeliveryClubVis={isDeliveryClubVis}
          areNotSubscrHexVis={areNotSubscrHexVis}
          areDetectionsVis={areDetectionsVis}
          showNotSubscrHexs={showNotSubscrHexs}
          hideNotSubscrHexs={hideNotSubscrHexs}
          showLocations={showLocations}
          hideLocations={hideLocations}
          showLastLocations={showLastLocations}
          hideLastLocations={hideLastLocations}
          showVelobikes={showVelobikes}
          hideVelobikes={hideVelobikes}
          showRoutes={showRoutes}
          hideRoutes={hideRoutes}
          showDistricts={showDistricts}
          hideDistricts={hideDistricts}
          showYandexFood={showYandexFood}
          hideYandexFood={hideYandexFood}
          showDeliveryClub={showDeliveryClub}
          hideDeliveryClub={hideDeliveryClub}
          hideDetections={hideDetections}
          showDetections={showDetections}
        />

        {/* {isSaving && !screensWithPallete.includes(screeningLayer) ? ( */}
        {isSaving ? (
          <Stack id='legend'>
            {legend
              // .filter(({ name, hasTemplate }) => {
              //   if (screeningLayer === 'deliveryClub') {
              //     return name === 'Доставка из Деливери Клаба'
              //   }
              //   if (screeningLayer === 'yandexFood') {
              //     return name === 'Доставка из Яндекс Еды'
              //   }
              //   if (
              //     screeningLayer === 'locations' ||
              //     screeningLayer === 'lastRoute'
              //   ) {
              //     return (
              //       (!hasTemplate || (hasTemplate && name === 'vkGeo')) &&
              //       name !== 'Доставка из Деливери Клаба' &&
              //       name !== 'Доставка из Яндекс Еды'
              //     )
              //   }

              //   if (screeningLayer === 'velobikes') {
              //     return name?.includes('Велосипеды')
              //   }

              //   if (screeningLayer === 'vkGeo') {
              //     return name === 'vkGeo'
              //   }

              //   if (
              //     screeningLayer === 'userDetections' ||
              //     screeningLayer === 'notSubscribedHexagons'
              //   ) {
              //     return name !== 'vkGeo' && hasTemplate
              //   }

              //   return false
              // })
              .map(({ icon, name, hasTemplate }, idx) => (
                <Stack
                  alignItems='flex-start'
                  direction='row'
                  textAlign='start'
                  key={`${name}_${idx}`}
                  my={0.5}
                >
                  {hasTemplate ? (
                    <>{icon}</>
                  ) : (
                    <>
                      <img
                        alt={name}
                        width='20'
                        height='20'
                        src={icon as string}
                      />{' '}
                      - {name}
                    </>
                  )}
                </Stack>
              ))}
          </Stack>
        ) : null}

        <Stack id='mapWithPallete'>
          <div
            style={{ height: mapId ? '500px' : undefined }}
            className={s.map_wrapper}
            ref={mapRef}
            id='map'
          ></div>

          <Stack direction='row' spacing={0.5} mt={1}>
            {routeСolors.map((color, idx) => (
              <Stack
                key={color}
                sx={{
                  width: '20%',
                  height: '32px',
                  bgcolor: color,
                  borderRadius: '4px',
                  color: '#fff',
                  fontWeight: 700,
                  fontSize: '0.8rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {idx === 0 && 'Меньше'}
                {idx === 4 && 'Больше'}
              </Stack>
            ))}
          </Stack>
        </Stack>

        <div className={s.ol_popup} ref={popupRef}>
          {/* eslint-disable-next-line */}
          <a href='#' ref={popupCloserRef} className={s.ol_popup_closer}></a>
          {popupData && (
            <FeaturePopup
              data={popupData}
              areDetectionsVis={areDetectionsVis}
              areNotSubscrHexVis={areNotSubscrHexVis}
            />
          )}
        </div>
      </div>
    </>
  )
})
