import { Box, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { SubheaderIcon } from '../components/SubheaderIcon'

const icons = {
  parking: {
    name: 'Наиболее частая локация парковки',
    icon: <SubheaderIcon name='LocalParkingIcon' warningType />,
  },
  autoTrips: {
    name: 'Поездки на авто',
    icon: <SubheaderIcon name='EmojiTransportationIcon' warningType />,
  },
  metroTrips: {
    name: 'Поздки на метро',
    icon: <SubheaderIcon name='SubwayIcon' warningType />,
  },
  ngptTrips: {
    name: 'Поздки на НГПТ',
    icon: <SubheaderIcon name='DirectionsBusIcon' warningType />,
  },
  districts: {
    name: 'Наиболее популярные районы',
    icon: <SubheaderIcon name='ShareLocationOutlinedIcon' warningType />,
  },
} as any

export const SummaryTransRecord = observer(
  ({ title, name, description, blackKey, rowSx, descSx }: any) => {
    return (
      <Stack key={title} sx={{ width: '100%' }} alignItems='flex-start'>
        {title && (
          <Stack direction='row'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {icons?.[title]?.icon}
            </Box>

            <Typography textAlign='left' color='common.black' variant='h6'>
              {icons?.[title]?.name}
            </Typography>
          </Stack>
        )}

        {name || description ? (
          <Stack
            direction='row'
            spacing={1}
            justifyContent='space-between'
            sx={{ width: '100%', p: 1, ...rowSx }}
          >
            <Typography
              variant='body1'
              width='50%'
              textAlign='start'
              color={blackKey ? 'common.black' : 'primary.main'}
              sx={{ wordBreak: 'break-word' }}
            >
              {name}
            </Typography>
            <Typography
              variant='body1'
              width='50%'
              color='text.disabled'
              textAlign='end'
              sx={descSx}
            >
              {description}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    )
  }
)
