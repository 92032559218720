import { Stroke, Fill, Icon, Style } from 'ol/style'

//новое

import carIcon from 'assets/carIcon.svg'
import evacuator from 'assets/mapIcons/evacuator.svg'
import ngptIcon from 'assets/ngptIcon.svg'
import work from 'assets/mapIcons/work.svg'
import home from 'assets/mapIcons/home.svg'
import camera from 'assets/mapIcons/camera.svg'
import salesIcon from 'assets/mapIcons/salesIcon.svg'
import parking from 'assets/mapIcons/parking.svg'
import accidentIcon from 'assets/mapIcons/accidentRed.svg'
import coddIcon from 'assets/mapIcons/coddWithBg.svg'
import user from 'assets/mapIcons/user.svg'
import carOverRoute from 'assets/mapIcons/carOverRoute.png'

//старое

import velobikePurple from 'assets/velobikePurple.svg'
import velobikeGreen from 'assets/velobikeGreen.svg'
import velobikeRed from 'assets/velobikeRed.svg'
import letter from 'assets/letter.svg'
import startFlag from 'assets/startFlag.svg'
import endFlag from 'assets/endFlag.svg'

export const routeСolors = [
  '#1E9ADF',
  '#28B778',
  '#F2D123',
  '#F58C2A',
  '#EB555C',
]
export const routeСolorsRgb = [
  '30, 154, 223',
  '40, 183, 120',
  '242, 209, 35',
  '245, 140, 42',
  '235, 85, 92',
]
export const getRouteStyle = (color = '#1E9ADF') =>
  new Style({
    stroke: new Stroke({
      color,
      width: 3,
    }),
  })
export const districtColors = [
  'rgba(30, 154, 223, 0.4)',
  'rgba(40, 183, 120, 0.4)',
  'rgba(242, 209, 35, 0.4)',
  'rgba(245, 140, 42, 0.4)',
  'rgba(235, 85, 92, 0.4)',
  'rgba(152, 22, 244, 0.7)',
]
export const getDistrictStyles = (district) =>
  new Style({
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0.4)',
      width: 2,
    }),
    fill: new Fill({
      color: district,
    }),
  })

const getSVGMetroIcon = (
  color = '#EB555C'
) => `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" rx="12" fill="${color}"/>
  <path d="M5 16.4371V15.1708H5.94553L9.23965 7L12.0153 11.7939L14.7603 7L18.024 15.1708H19V16.4371H14.0283V15.1708H14.7603L14.0283 13.1507L12.0153 16.4974L9.94118 13.1507L9.20915 15.1708H9.94118V16.4371H5Z" fill="white"/>
</svg>`

export const getFeatureSrc = (icon, color) => {
  if (icon === 'auto') {
    return carIcon
  }
  if (icon === 'subway') {
    return `data:image/svg+xml;utf8, ${encodeURIComponent(
      `${getSVGMetroIcon(color)}`
    )}`
  }
  if (icon === 'ngpt') {
    return ngptIcon
  }
  if (icon === 'camera') {
    return camera
  }
  if (icon === 'evacuator') {
    return evacuator
  }
  if (icon === 'carOverRoute') {
    return carOverRoute
  }
  if (icon === 'sales') {
    return salesIcon
  }
  if (icon === 'work') {
    return work
  }
  if (icon === 'home') {
    return home
  }
  if (icon === 'parking') {
    return parking
  }
  if (icon === 'startFlag') {
    return startFlag
  }
  if (icon === 'endFlag') {
    return endFlag
  }
  if (icon === 'velobikePurple') {
    return velobikePurple
  }
  if (icon === 'velobikeGreen') {
    return velobikeGreen
  }
  if (icon === 'velobikeRed') {
    return velobikeRed
  }
  if (icon === 'pochta') {
    return letter
  }

  if (icon === 'dtp') {
    return accidentIcon
  }

  if (icon === 'codd') {
    return coddIcon
  }

  if (icon === 'user') {
    return user
  }
}

export const getScale = (icon, shouldIncrease) => {
  if (
    icon === 'auto' ||
    icon === 'subway' ||
    icon === 'ngpt' ||
    icon === 'sales' ||
    icon === 'startFlag' ||
    icon === 'endFlag'
  ) {
    return 0.7
  }

  if (icon === 'user' || icon === 'evacuator' || icon === 'parking') {
    return 1
  }

  if (icon === 'dtp' || icon === 'home' || icon === 'work') {
    return 0.3
  }

  if (icon.includes('velobike')) {
    return shouldIncrease ? 0.7 : 0.4
  }

  return 0.7
}

export const getFeatureIcon = (icon, shouldIncrease = false, feature) => {
  return new Icon({
    anchor: icon === 'startFlag' || icon === 'endFlag' ? [20, 20] : [12, 12],
    anchorXUnits: 'pixels',
    anchorYUnits: 'pixels',
    src: getFeatureSrc(icon, feature?.subway_line_color),
    scale: getScale(icon, shouldIncrease),
  })
}

export const clickableIcons = ['pochta', 'codd']

export const getVelobikeLocCoords = (loc, locationType = 'start') => {
  return locationType === 'end'
    ? [loc.end_longitude, loc.end_latitude]
    : [loc.start_longitude, loc.start_latitude]
}
