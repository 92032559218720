/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, useEffect, useMemo, useState } from 'react'
import { Context } from '../..'
import { FILTERS, FilterName } from './MapFilter'

import moGeo from '../../utils/moGeo.json'

// @ts-ignore
const geoData: any = new Map(moGeo)

export const useTransportActivityData = () => {
  const { rootStore } = useContext(Context)
  const { mapStore } = rootStore
  const { locations, routes, segmentMap } = mapStore

  const [activeFilters, setActiveFilters] = useState<FilterName[]>([
    // 'ngpt',
    'auto',
    // 'subway',
    // 'sales',
    // 'multitrip',
    // parking
  ])

  const filtredLocations = useMemo(() => {
    const res = locations.filter((loc: any) =>
      activeFilters.includes(loc.transportType)
    )
    return res
  }, [activeFilters, locations])

  const initialDistricts = useMemo(() => {
    const districtsMap = new Map()

    locations.forEach((item: any) => {
      if (!item.district) {
        return
      }

      let entry = districtsMap.get(item.district)
      const geoJson: any = geoData.get(item.district)

      if (!entry) {
        entry = {
          id: parseInt(geoJson?.properties['OKATO'], 10),
          name: item.district,
          geoJson,
          counts: {
            all: 0,
            auto: 0,
            subway: 0,
            ngpt: 0,
            parking: 0,
            sales: 0,
          },
        }
      }

      if (item.count) {
        entry.counts.all += +item.count
        entry.counts[item.transportType] += +item.count
      }

      districtsMap.set(item.district, entry)
    })

    const arr = Array.from(districtsMap.values()).sort(
      (a, b) => b.counts.all - a.counts.all
    )
    return arr
  }, [locations])

  const districts = useMemo(() => {
    const districtsMap = new Map()

    filtredLocations.forEach((item: any) => {
      if (!item.district) {
        return
      }

      let entry = districtsMap.get(item.district)
      const geoJson: any = geoData.get(item.district)

      if (!entry) {
        entry = {
          id: parseInt(geoJson?.properties['OKATO'], 10),
          name: item.district,
          geoJson,
          counts: {
            all: 0,
            auto: 0,
            subway: 0,
            ngpt: 0,
            parking: 0,
            sales: 0,
          },
        }
      }

      if (item.count) {
        entry.counts.all += +item.count
        entry.counts[item.transportType] += +item.count
      }

      districtsMap.set(item.district, entry)
    })

    const arr = Array.from(districtsMap.values()).sort(
      (a, b) => b.counts.all - a.counts.all
    )
    return arr
  }, [filtredLocations])

  const filteredRoutes = useMemo(() => {
    const res = routes.filter((route: any) =>
      activeFilters.includes(route.transportType)
    )
    return res
  }, [activeFilters, routes])

  const filteredRouteSegments = useMemo(() => {
    return activeFilters.includes('auto' || 'subway' || '') ? segmentMap : []
  }, [segmentMap, activeFilters])

  const hasTransData = useMemo(
    () =>
      locations?.length ||
      initialDistricts?.length ||
      routes?.length ||
      segmentMap?.length,
    [
      initialDistricts?.length,
      segmentMap?.length,
      routes?.length,
      locations?.length,
    ]
  )

  const filtersWithData = useMemo(() => {
    if (!initialDistricts || !initialDistricts.length) {
      return []
    }

    return FILTERS.filter((filter) =>
      initialDistricts.some((district) => district?.counts?.[filter.value])
    )
  }, [initialDistricts])

  useEffect(() => {
    if (
      filtersWithData?.length &&
      !filtersWithData.some((f) => f.value === 'auto') &&
      activeFilters.length === 1 &&
      activeFilters[0] === 'auto'
    ) {
      setActiveFilters([filtersWithData[0].value])
    }
  }, [activeFilters, filtersWithData])

  return {
    activeFilters,
    setActiveFilters,
    filtredLocations,
    districts,
    filteredRoutes,
    filteredRouteSegments,
    hasTransData,
    filtersWithData,
  }
}
