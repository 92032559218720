import s from './Loading.module.scss'

import { observer } from 'mobx-react-lite'
import { Spin } from 'antd'
import React from 'react'

export const Loading = observer(() => {
  return (
    <div className={s.Loading}>
      <Spin size='large' />
    </div>
  )
})
