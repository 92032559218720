import { Stack } from '@mui/system'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { VkFriendsCharts } from './VkFriendsCharts'
import { VkUserInfo } from './VkUserInfo'

export const VkUserInfoWrapper = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { vkUserInfo } = profileStore

  if (!vkUserInfo) {
    return null
  }

  return (
    <Stack
      sx={{
        mt: 2,
        maxWidth: '900px',
        width: '100%',
        px: '16px',
      }}
    >
      <VkFriendsCharts />

      <VkUserInfo />
    </Stack>
  )
})
