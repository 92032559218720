export const typography = {
  h1: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '96px',
    lineHeight: '116px',
    letterSpacing: '-1.5px',
  },
  h3: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '48px',
    lineHeight: '116.7%',
  },
  h4: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '123.5%',
  },
  h5: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '24px',
  },
  h6: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
  },
  subtitle1: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '0.1px',
  },
  body1: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
  },
  body2: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
  },
  buttonLarge: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
  caption: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15.6px',
    letterSpacing: '0.15px',
  },
}
