import { Box, Radio, Stack } from '@mui/material'
import { observer } from 'mobx-react-lite'
import ngptIcon from 'assets/ngptIcon.svg'
import metroIcon from 'assets/metroIcon.svg'
import carIcon from 'assets/carIcon.svg'
import sales from 'assets/mapIcons/salesIcon.svg'
import coddLogo from 'assets/mapIcons/coddLogo.svg'
import { Dispatch } from 'react'
// import { Commute } from '@mui/icons-material'
import React from 'react'
import parking from 'assets/mapIcons/parking.svg'

import * as S from './MapFilter.styled'
import { useTransportActivityData } from './useTransportActivityData'

export type FilterName = 'ngpt' | 'subway' | 'auto' | 'sales' | 'coddFines'

export const FILTERS = [
  {
    value: 'ngpt',
    displayName: 'НГПТ',
    icon: <img src={ngptIcon} height={24} width={24} alt='filterIcon' />,
  },
  {
    value: 'subway',
    displayName: 'Метро',
    icon: <img src={metroIcon} height={24} width={24} alt='filterIcon' />,
  },
  {
    value: 'auto',
    displayName: 'Авто',
    icon: <img src={carIcon} height={24} width={24} alt='filterIcon' />,
  },
  {
    value: 'parking',
    displayName: 'Парковки',
    icon: <img src={parking} height={24} width={24} alt='filterIcon' />,
  },
  // {
  //   value: 'multitrip',
  //   displayName: 'Мульти',
  //   icon: (
  //     <Commute
  //       sx={{
  //         height: 24,
  //         width: 24,
  //         padding: '4px',
  //         bgcolor: '#F2994A',
  //         color: '#fff',
  //         borderRadius: '50%',
  //       }}
  //     />
  //   ),
  // },
  {
    value: 'sales',
    displayName: 'Билет',
    icon: <img src={sales} height={24} width={24} alt='filterIcon' />,
  },
  {
    value: 'coddFines',
    displayName: 'Нарушения',
    icon: <img src={coddLogo} height={24} width={24} alt='filterIcon' />,
  },
] as {
  value: FilterName
  displayName: string
  icon: JSX.Element
}[]

interface MapFilterProps {
  activeFilters: FilterName[]
  setActiveFilters: Dispatch<React.SetStateAction<FilterName[]>>
}

export const MapFilter = observer(
  ({ activeFilters, setActiveFilters }: MapFilterProps) => {
    const handleFilterChange = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      value: FilterName
    ) => {
      const isActive = activeFilters.findIndex((f) => value === f) > -1

      setActiveFilters(
        isActive
          ? activeFilters.filter((f) => f !== value)
          : [...activeFilters, value]
      )
    }

    const { filtersWithData } = useTransportActivityData()

    if (!filtersWithData.length) {
      return null
    }

    return (
      <S.FiltersContainer spacing={0.5} mb={1.5}>
        <S.Content>
          {filtersWithData.map(({ value, displayName, icon }, idx) => (
            <Stack
              key={`${value}_${idx}`}
              direction='row'
              mr={1}
              pr={1}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  bgcolor: 'rgba(85, 120, 235, 0.04)',
                  borderRadius: '8px',
                },
              }}
              onClick={(e) => handleFilterChange(e, value)}
            >
              <Radio
                sx={{ height: 40, width: 40, ':hover': { bgcolor: 'unset' } }}
                checked={activeFilters.includes(value)}
              />
              <Stack direction='row' alignItems='center'>
                {icon}
                <Box component='span' ml={1} fontSize='1rem'>
                  {displayName}
                </Box>
              </Stack>
            </Stack>
          ))}
        </S.Content>
      </S.FiltersContainer>
    )
  }
)
