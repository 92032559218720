import { IconButton, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

interface ContainerProps {
  width: string
  height: string
  maxwidth: number | string
  maxheight: number | string
}

export const BgContainer = styled(Stack)({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  backgroundColor: 'lightgray',
  height: '100vh',
  width: '100vw',
  top: 0,
  left: 0,
  zIndex: 1000,
})

export const ImageContainer = styled(Stack)<ContainerProps>(
  ({ height, width, maxwidth, maxheight }) => ({
    height,
    width,
    maxWidth: maxwidth,
    maxHeight: maxheight,
  })
)

export const EforImage = styled('img')({
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: '100%',
  backgroundPosition: 'center',
})

export const CloseIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 15,
  right: 15,
  border: `2px solid ${theme.palette.primary.main}`,
  width: 36,
  height: 36,
}))
