import { Box, Stack, Typography } from '@mui/material'
import { Person } from '@mui/icons-material'
import 'react-image-gallery/styles/css/image-gallery.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import { observer } from 'mobx-react-lite'
import { useContext, SyntheticEvent, useState, useRef } from 'react'
import PersonalGallery from '../PersonalGallery'
import { Context } from 'index'
import React from 'react'
import { translateAppKey, translateRelKey } from './PersonalInfo.utils'
import { TgPersonalInfo } from './TgPersonalInfo'
import { AdditionalInfo } from '../AdditionalInfo'
import { PersonalSlicedData } from './PersonalSlicedData'
import { Header } from 'components/Profile/components/Header'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import { InfoChip } from 'components/Profile/components/InfoChip'
import cdek from 'assets/cdek.svg'
import { SocialProfile } from './SocialProfile'
import { TgCommunitiesInfo } from 'components/Profile/TgCommunitiesInfo'
import { usePersonalInfo, vkKeys } from './usePersonalInfo'
import { VkUserInfoWrapper } from './VkUserInfo/VkUserInfoWrapper'
import { TgLinkedUsers } from './TgLinkedUsers/TgLinkedUsers'
import { VkHistoryRobots } from './VkHistoryRobots'
import { AdditionalAddresses } from '../AdditionalAddresses'
import { Relationships } from './Relationships'

interface Params {
  name: string
  value: string | any[]
}

const VkValue = observer<Params>((props) => {
  const { name, value } = props

  if (name === 'fio_' && Array.isArray(value)) {
    return (
      <>{value.map((el, idx) => (idx !== value.length - 1 ? `${el}; ` : el))}</>
    )
  }

  if (name === 'busyness' && Array.isArray(value)) {
    return (
      <>
        {value.map((el, idx) =>
          idx !== value.length - 1 ? `${el.name}; ` : el.name
        )}
      </>
    )
  }

  return <>{value}</>
})

export const PersonalInfo = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const {
    vk,
    relations,
    relationships,
    links,
    additionalInfo,
    vkUserInfo,
    vkHistory,
    radarUserInfo,
  } = profileStore

  const { vkId } = links

  const { combinedApps, normalizedProfileData } = usePersonalInfo()

  const cdekSrc = useRef(cdek)

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <Box width='100%'>
      <Box ml='20px'>
        <Header
          text='Личная информация'
          icon={
            <Person sx={{ bgcolor: 'primary.light', color: 'common.white' }} />
          }
        />
      </Box>

      <PersonalGallery />

      <PersonalSlicedData from={0} to={2} data={normalizedProfileData} />

      {additionalInfo?.getcontact?.length ? (
        <Stack sx={{ px: 2, pt: 2 }}>
          <Stack direction='row' mb='4px'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='NotificationsNoneOutlinedIcon' />
            </Box>

            <Typography variant='h6' color='common.black' textAlign='start'>
              Get Contact
            </Typography>
          </Stack>

          <Stack
            flexDirection='row'
            flexWrap='wrap'
            alignItems='start'
            sx={{ maxHeight: 220, overflowY: 'auto' }}
          >
            {additionalInfo.getcontact.map(
              (contantName: string, idx: number) => (
                <InfoChip key={idx} text={contantName} />
              )
            )}
          </Stack>
        </Stack>
      ) : null}

      {additionalInfo?.cdek?.names?.length ? (
        <Stack sx={{ px: 2, pt: 2 }}>
          <Stack mr={1} mb={0.5}>
            <img width={60} height={24} src={cdekSrc.current} alt='cdekIcon' />
          </Stack>

          <Stack
            flexDirection='row'
            flexWrap='wrap'
            alignItems='start'
            sx={{ maxHeight: 220, overflowY: 'auto' }}
          >
            {additionalInfo.cdek.names.map((name: string, idx: number) => (
              <InfoChip key={idx} text={name} />
            ))}
          </Stack>
        </Stack>
      ) : null}

      <PersonalSlicedData from={2} to={3} data={normalizedProfileData} />

      {additionalInfo?.efor_addresses ? <AdditionalAddresses /> : null}

      <SocialProfile />

      <PersonalSlicedData from={3} to={6} data={normalizedProfileData} />

      <TgPersonalInfo expanded={expanded} handleChange={handleChange} />

      <TgCommunitiesInfo />

      {radarUserInfo ? <TgLinkedUsers /> : null}

      {vk && Object.keys(vk)?.length ? (
        <Stack sx={{ px: 2, pt: 2 }}>
          <Stack direction='row'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='MarkUnreadChatAltIcon' />
            </Box>

            <Typography variant='h6' color='common.black' textAlign='start'>
              VK
            </Typography>
          </Stack>

          <Stack sx={{ width: '100%' }}>
            {vkId ? (
              <Stack
                sx={{ width: '100%', p: 1 }}
                direction='row'
                justifyContent='space-between'
              >
                <Typography variant='body1' color='common.black'>
                  VK ID
                </Typography>
                <Typography
                  variant='body1'
                  width='50%'
                  textAlign='end'
                  color='text.disabled'
                  sx={{ wordBreak: 'break-word' }}
                >
                  {vkId}
                </Typography>
              </Stack>
            ) : null}
          </Stack>

          {vk &&
            Object?.entries(vk)
              .filter(
                ([_key, _val]) =>
                  _key !== 'birthdays_' &&
                  _key !== 'addresses_' &&
                  _key !== 'database' &&
                  // TODO: обработать и отрендерить
                  _key !== 'partner' &&
                  _key !== 'relatives' &&
                  (vkId ? _key !== 'id' : false)
              )
              .map(([key, val]: any, idx: number) => (
                <Stack sx={{ width: '100%' }} key={idx}>
                  <Stack
                    key={idx}
                    sx={{ width: '100%', p: 1 }}
                    direction='row'
                    justifyContent='space-between'
                  >
                    <Typography variant='body1' color='common.black'>
                      {vkKeys?.[key] ?? key}
                    </Typography>
                    <Typography
                      variant='body1'
                      width='50%'
                      textAlign='end'
                      color='text.disabled'
                      sx={{ wordBreak: 'break-word' }}
                    >
                      <VkValue name={key} value={val} />
                    </Typography>
                  </Stack>
                </Stack>
              ))}
        </Stack>
      ) : null}

      {vkUserInfo ? <VkUserInfoWrapper /> : null}

      {vkHistory ? <VkHistoryRobots /> : null}

      {combinedApps?.length ? (
        <Stack sx={{ px: 2, pt: 2 }}>
          <Stack direction='row'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='LayersOutlinedIcon' />
            </Box>

            <Typography variant='h6' color='common.black' textAlign='start'>
              Приложения
            </Typography>
          </Stack>

          <Stack sx={{ width: '100%' }}>
            {combinedApps.map((app: any, appIdx: number) => (
              <Stack key={appIdx}>
                {app &&
                  Object.entries(app)
                    .filter(
                      (i) => i?.[0] !== 'data_value' && i?.[0] !== 'data_nm'
                    )
                    .map(([key, val]: any, keyIdx: number) => (
                      <Stack
                        key={keyIdx}
                        sx={{ width: '100%', p: 1 }}
                        direction='row'
                        justifyContent='space-between'
                      >
                        {translateAppKey(key) === 'название приложения' ? (
                          <>
                            <Stack direction='row'>
                              <Box
                                sx={{
                                  svg: {
                                    color: 'primary.main',
                                    fontSize: 20,
                                  },
                                  mr: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                component='span'
                              >
                                <SubheaderIcon name='AdUnitsIcon' />
                              </Box>

                              <Typography
                                variant='h6'
                                color='common.black'
                                textAlign='start'
                              >
                                {val}
                              </Typography>
                            </Stack>

                            {null}
                          </>
                        ) : (
                          <>
                            <Typography
                              variant='body1'
                              width='50%'
                              textAlign='start'
                              sx={{ wordBreak: 'break-word' }}
                            >
                              {translateAppKey(key)}
                            </Typography>

                            <Typography
                              color='text.disabled'
                              variant='body1'
                              textAlign='end'
                            >
                              {val}
                            </Typography>
                          </>
                        )}
                      </Stack>
                    ))}
              </Stack>
            ))}
          </Stack>
        </Stack>
      ) : null}

      {relations && Object.keys(relations)?.length ? (
        <Stack sx={{ px: 2, pt: 2 }}>
          <Stack direction='row'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='WorkspacesOutlinedIcon' />
            </Box>

            <Typography variant='h6' color='common.black' textAlign='start'>
              Связки
            </Typography>
          </Stack>

          {relations &&
            Object.entries(relations).map(([key, val]: any, keyIdx: number) =>
              key &&
              val &&
              !key?.includes('hash') &&
              !key?.includes('period_from') ? (
                <Stack
                  key={keyIdx}
                  sx={{ width: '100%', p: 1 }}
                  direction='row'
                  justifyContent='space-between'
                >
                  <Typography
                    variant='body1'
                    width='50%'
                    sx={{ wordBreak: 'break-word' }}
                    textAlign='start'
                  >
                    {translateRelKey(key)}
                  </Typography>

                  <Typography
                    variant='body1'
                    width='50%'
                    textAlign='end'
                    color='text.disabled'
                    sx={{ wordBreak: 'break-word' }}
                  >
                    {val}
                  </Typography>
                </Stack>
              ) : null
            )}
        </Stack>
      ) : null}

      {relationships && Object.keys(relationships).length ? (
        <Relationships rels={relationships} />
      ) : null}

      <AdditionalInfo expanded={expanded} handleChange={handleChange} />
    </Box>
  )
})
