import { Stack, Typography } from '@mui/material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { getCountText, transformDate } from '../FeaturePopup.utils'

interface HexagonInfo {
  channelUsername?: string
  postsCount: number
  title?: string
  notSubscrHexId: string
}

interface SubHexdataProps {
  data: HexagonInfo
  areDetectionsVis?: boolean
}

export const NotsubHexData = observer(
  ({ data, areDetectionsVis }: SubHexdataProps) => {
    const { title, postsCount, channelUsername, notSubscrHexId } = data

    const { rootStore } = useContext(Context)
    const {
      profileStore: { userDetections },
    } = rootStore

    const detectionData = userDetections?.find(
      (det) => det.hexagon_id === notSubscrHexId
    )

    return (
      <Stack alignItems='flex-start'>
        {title ? (
          <Stack alignItems='flex-start' mb={1}>
            <Typography component='b' variant='body2'>
              Название сообщества:
            </Typography>
            <Typography
              component='span'
              variant='body2'
              color='dimgray'
              textAlign='start'
            >
              {title}
            </Typography>
          </Stack>
        ) : null}

        {postsCount ? (
          <Stack alignItems='flex-start' mb={1}>
            <Typography component='b' variant='body2'>
              Сообщений в сообществе:
            </Typography>
            <Typography component='span' variant='body2' color='dimgray'>
              {postsCount}
            </Typography>
          </Stack>
        ) : null}

        {channelUsername ? (
          <Stack alignItems='flex-start' mb={1}>
            <Typography component='b' variant='body2'>
              Cсылка на группу:
            </Typography>
            <a
              target='_blank'
              rel='noreferrer'
              href={`https://t.me/${channelUsername}`}
            >
              {channelUsername}
            </a>
          </Stack>
        ) : null}

        {detectionData?.detection_count && areDetectionsVis ? (
          <Stack alignItems='flex-start' mb={1}>
            <Typography component='b' variant='body2'>
              Появлялся в локации:
            </Typography>
            <Typography component='span' variant='body2' color='dimgray'>
              {getCountText(detectionData.detection_count)}
            </Typography>
          </Stack>
        ) : null}

        {detectionData?.last_detect && areDetectionsVis ? (
          <Stack alignItems='flex-start'>
            <Typography component='b' variant='body2'>
              Последнее появление:
            </Typography>
            <Typography component='span' variant='body2' color='dimgray'>
              {transformDate(detectionData.last_detect)}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    )
  }
)
