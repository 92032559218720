import { SxProps, useTheme } from '@mui/material'
import React from 'react'
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined'
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined'
import LanguageIcon from '@mui/icons-material/Language'
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt'
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import AdUnitsIcon from '@mui/icons-material/AdUnits'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'
import PinOutlinedIcon from '@mui/icons-material/PinOutlined'
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import SubwayIcon from '@mui/icons-material/Subway'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import ShareLocationOutlinedIcon from '@mui/icons-material/ShareLocationOutlined'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined'
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined'
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined'
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined'
import DirectionsBusOutlinedIcon from '@mui/icons-material/DirectionsBusOutlined'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'

const icons = {
  PersonSearchOutlinedIcon,
  LocalPhoneOutlinedIcon,
  AlternateEmailIcon,
  NotificationsNoneOutlinedIcon,
  CakeOutlinedIcon,
  LanguageIcon,
  NearMeOutlinedIcon,
  MarkUnreadChatAltIcon,
  LayersOutlinedIcon,
  WorkspacesOutlinedIcon,
  AdUnitsIcon,
  BookmarksIcon,
  CreateOutlinedIcon,
  RocketLaunchOutlinedIcon,
  PinOutlinedIcon,
  DirectionsCarFilledIcon,
  LocalParkingIcon,
  EmojiTransportationIcon,
  SubwayIcon,
  DirectionsBusIcon,
  SmsOutlinedIcon,
  TrackChangesIcon,
  ShareLocationOutlinedIcon,
  FamilyRestroomIcon,
  EmojiPeopleOutlinedIcon,
  PeopleOutlinedIcon,
  PersonOutlinedIcon,
  PermContactCalendarOutlinedIcon,
  AssessmentOutlinedIcon,
  DonutLargeOutlinedIcon,
  Diversity3OutlinedIcon,
  HomeRepairServiceOutlinedIcon,
  SchoolOutlinedIcon,
  AutoStoriesOutlinedIcon,
  InterestsOutlinedIcon,
  MilitaryTechOutlinedIcon,
  AttachMoneyOutlinedIcon,
  PeopleAltIcon,
  ChatOutlinedIcon,
  ContentCopyOutlinedIcon,
  UpdateOutlinedIcon,
  DirectionsBusOutlinedIcon,
  ConnectWithoutContactIcon,
}

export const SubheaderIcon = ({
  name,
  sx,
  warningType,
}: {
  name: keyof typeof icons
  sx?: SxProps
  warningType?: boolean
}) => {
  const {
    palette: { primary, warning },
  } = useTheme()

  const IconComponent = icons[name]

  const color = warningType ? warning.dark : primary.main

  return (
    <IconComponent
      sx={{
        color,
        border: `1px solid ${color}`,
        borderRadius: '8px',
        padding: '2px',
        ...sx,
      }}
    />
  )
}
