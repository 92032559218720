import { observer } from 'mobx-react-lite'
import React from 'react'
import { LinkedUserInfo } from 'store/profileStore/profileStore.types'
import { TextRecord } from '../TextRecord'
import { LinkedUserChannels } from './LinkedUserChannels'

export const LinkedUser = observer<LinkedUserInfo>((props) => {
  const {
    first_name,
    last_name,
    username,
    phone,
    reply_count,
    tag_count,
    timedelta_count,
    w,
    channels,
  } = props

  const fullName = `${first_name ?? ''} ${last_name ?? ''}`

  return (
    <>
      {fullName?.trim() ? <TextRecord name='name' value={fullName} /> : null}
      {username ? (
        <TextRecord
          name='link'
          value={
            <a
              href={`https://t.me/${username}`}
              target='_blank'
              rel='noreferrer'
            >
              Профиль
            </a>
          }
        />
      ) : null}
      {phone ? <TextRecord name='phone' value={phone} /> : null}
      {reply_count ? (
        <TextRecord name='reply_count' value={reply_count} />
      ) : null}
      {tag_count ? <TextRecord name='tag_count' value={tag_count} /> : null}
      {timedelta_count ? (
        <TextRecord name='timedelta_count' value={timedelta_count} />
      ) : null}
      {w ? <TextRecord name='w' value={w} /> : null}

      {channels && Object.keys(channels)?.length ? (
        <LinkedUserChannels {...channels} />
      ) : null}
    </>
  )
})
