import { Box, Stack, Typography } from '@mui/material'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { profileInfoSections } from './usePersonalInfo'
import { CollapsibleBlock } from 'components/Profile/components/CollapsibleBlock'

export const PersonalSlicedData = observer(
  ({ from, to, data }: { from: number; to: number; data: any }) => {
    return data?.slice(from, to).length ? (
      <Stack sx={{ px: 2, pt: 2 }}>
        {data?.slice(from, to)?.map(
          ([type, vals]: any) =>
            vals?.length && (
              <Stack
                key={type}
                sx={{ width: '100%', mt: 1 }}
                alignItems='flex-start'
              >
                <Stack direction='row'>
                  <Box
                    sx={{
                      mr: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <SubheaderIcon
                      name={profileInfoSections?.[type]?.iconName as any}
                    />
                  </Box>

                  <Typography color='common.black' variant='h6'>
                    {profileInfoSections?.[type]?.name}
                  </Typography>
                </Stack>

                <Stack sx={{ width: '100%' }}>
                  <CollapsibleBlock vals={vals} type={type} />
                </Stack>
              </Stack>
            )
        )}
      </Stack>
    ) : null
  }
)
