import { Box, Stack, Typography } from '@mui/material'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import * as S from './TgLinkedUsers.styled'
import { LinkedUsers } from './LinkedUsers'
import { TextRecord } from './TextRecord'

export const TgLinkedUsers = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { radarUserInfo: data } = profileStore

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  if (!data) {
    return null
  }

  const fullName = `${data?.first_name ?? ''} ${data?.last_name ?? ''}`

  return (
    <S.Wrapper>
      <Accordion
        expanded={expanded === 'tgLinkedUsers'}
        onChange={handleChange('tgLinkedUsers')}
      >
        <AccordionSummary>
          <Stack direction='row'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='PeopleAltIcon' />
            </Box>

            <Typography variant='body1' color='common.black'>
              Связанные пользователи Tg
            </Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <Stack>
            <S.Header>
              <Typography fontWeight={700} variant='body1'>
                Данные о пользователе
              </Typography>

              {fullName?.trim() ? (
                <TextRecord name='name' value={fullName} />
              ) : null}
              {data?.username ? (
                <TextRecord
                  name='link'
                  value={
                    <a
                      href={`https://t.me/${data.username}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Профиль
                    </a>
                  }
                />
              ) : null}
              {data?.phone ? (
                <TextRecord name='phone' value={data.phone} />
              ) : null}
              {data?.online ? <TextRecord name='online' value={'Да'} /> : null}
              {data?.verified ? (
                <TextRecord name='verified' value={'Да'} />
              ) : null}
              {data?.bot ? <TextRecord name='bot' value={'Да'} /> : null}
              {data?.deleted ? (
                <TextRecord name='deleted' value={'Да'} />
              ) : null}
              {data?.fake ? <TextRecord name='fake' value={'Да'} /> : null}
              {data?.lang_code ? (
                <TextRecord name='lang_code' value={data.lang_code} />
              ) : null}
            </S.Header>

            {data?.linked_users && Object.keys(data.linked_users)?.length ? (
              <LinkedUsers {...data.linked_users} />
            ) : null}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </S.Wrapper>
  )
})
