import { Stack, Chip as MuiChip, chipClasses, Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Wrapper = styled(Stack)({
  flexDirection: 'row',
  width: '100%',
  marginBottom: 16,
})

export const TransportBtns = styled(Stack)({
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'center',
})

export const CommunitiesBtns = styled(Stack)({
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'center',
})

export const Chip = styled(MuiChip)<{ community?: number }>(
  ({ community }) => ({
    [`&.${chipClasses.filled}`]: {
      backgroundColor: community ? '#188FCA' : '#161616',
      color: '#fff',
      border: community ? '1px solid #188FCA' : '1px solid #161616',
    },

    [`&.${chipClasses.outlined}`]: {
      color: community ? '#188FCA' : '#161616',
      border: community ? '1px solid #188FCA' : '1px solid #161616',
    },

    height: 22,
    margin: '3px',
  })
)

export const LegendBtn = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  width: 300,
  alignSelf: 'center',
  margin: '12px 0',
  fontSize: 14,
  lineHeight: '24px',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))
