import { SxProps, Typography } from '@mui/material'
import React from 'react'

import * as S from './Header.styled'

export const Header = ({
  text,
  icon,
  sx,
}: {
  text: string
  icon: JSX.Element
  sx?: SxProps
}) => {
  return (
    <S.Header sx={{ ...sx, mb: 2 }}>
      {icon}

      <Typography color='common.black' variant='h5'>
        {text}
      </Typography>
    </S.Header>
  )
}
