import { observer } from 'mobx-react-lite'
import s from './Radar.module.scss'
import { RadarMap } from './Map'
import { Districts } from './District'
import {
  Card,
  Stack,
  Typography,
  Box,
  TextField,
  IconButton,
} from '@mui/material'
import { useCallback, useContext, useEffect } from 'react'
import { Button } from '../common/Button'
import { Person } from '@mui/icons-material'
import GroupIcon from '@mui/icons-material/Group'
import CloseIcon from '@mui/icons-material/Close'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
import { Context } from '../..'
import React from 'react'
import { datepickerStyles, inputProps } from './Radar.constants'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { DistrictsWrapper } from './Radar.styled'
import { DistrictsList } from './components/DistrictsList'

export const Radar = observer(() => {
  const { rootStore } = useContext(Context)
  const { radarStore } = rootStore
  const {
    activeView,
    setActiveView,
    setSelectedRange,
    selectedRange,
    areDistrictsRequested,
    fetchDistricts,
    hexagonArrangement,
    fetchHexagonsByRegions,
  } = radarStore

  const [start, end] = selectedRange

  useEffect(() => {
    if (!areDistrictsRequested) {
      fetchDistricts()
    }
  }, [areDistrictsRequested, fetchDistricts])

  const onCommunitiesBtnClick = useCallback(() => {
    if (activeView !== 'communities') {
      setActiveView('communities')
    }
  }, [activeView, setActiveView])

  const onPersonsBtnClick = useCallback(() => {
    if (activeView !== 'persons') {
      setActiveView('persons')
    }
  }, [activeView, setActiveView])

  return (
    <Stack className={s.wrapper}>
      <Stack
        direction='row'
        justifyContent='space-between'
        sx={{ alignItems: 'center', mb: 3 }}
      >
        <Box>
          <Typography sx={{ fontSize: '30px', fontWeight: 550 }}>
            Карта активности Telegram
          </Typography>
        </Box>

        <Stack direction='row' height='40px' spacing={1}>
          <Button
            startIcon={<GroupIcon />}
            onClick={onCommunitiesBtnClick}
            isActive={activeView === 'communities'}
          >
            Сообщества
          </Button>
          <Button
            startIcon={<Person />}
            onClick={onPersonsBtnClick}
            isActive={activeView === 'persons'}
          >
            Персоны
          </Button>
        </Stack>
      </Stack>

      <Stack sx={{ bgcolor: '#F1F1F1', p: '24px', borderRadius: '8px' }}>
        <Stack direction='row' spacing={1} alignItems='center' mb={2}>
          <SearchOutlinedIcon sx={{ fontSize: '20px' }} />

          <Typography fontWeight={550} fontSize={20} textAlign='left'>
            Поиск по ключевым словам
          </Typography>
        </Stack>

        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Stack sx={{ width: '55%' }}>
            <TextField
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ ':hover': { bgcolor: '#fff' } }}
                    onClick={() => console.log(222)}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              size='small'
              sx={{ ...inputProps, '.MuiInputBase-root': { pr: 0 } }}
              placeholder='Введите данные для запроса'
            />
          </Stack>

          <DistrictsList />

          <Box sx={datepickerStyles}>
            <DatePicker
              placeholderText='Интервал'
              startDate={start}
              endDate={end}
              selectsRange
              onChange={(update) => setSelectedRange(update)}
              locale={ru}
            />
          </Box>

          <Button
            variant='contained'
            additionalSx={{ height: '38px', borderRadius: '8px', px: '12px' }}
            onClick={fetchHexagonsByRegions}
          >
            Искать
          </Button>
        </Stack>

        <Typography mt={2} textAlign='left' fontWeight={550}>
          Итого количество сущностей:{' '}
        </Typography>
      </Stack>

      <Stack className={s.card_wrapper} direction='row' spacing={3}>
        <Card className={s.card_map} sx={{ minWidth: '70%' }}>
          <RadarMap />
        </Card>

        <DistrictsWrapper arrangement={hexagonArrangement ? 1 : 0}>
          <Districts />
        </DistrictsWrapper>
      </Stack>
    </Stack>
  )
})
