import React from 'react'
import { AccordionProps, styled } from '@mui/material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  border: '1px solid lightgray',
  borderRadius: '8px',
  '&:before': {
    display: 'none',
  },
  marginBottom: '4px',

  '&.Mui-expanded:last-of-type': {
    marginBottom: '4px',
  },
})

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ bgcolor: '#F7F7FF', borderRadius: '8px' }}
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: 14, color: 'primary.main' }} />
    }
    {...props}
  />
))(() => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)({})
