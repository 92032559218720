/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck

import { RootStore } from '../rootStore'
import { makeAutoObservable, runInAction } from 'mobx'
import { request } from '../../utils/request'
import { TG_RADAR } from 'store/apiConstants'
import {
  TgChange,
  GeoInfo,
  RadarUserInfo,
  HexagonInfo,
  ChannelInfo,
  HexagonsGeoData,
  NotSubscribedChannel,
  VkHistory,
  Insights,
  Relationships,
  HistoryPastUser,
} from './profileStore.types'

export class ProfileStore {
  rootStore: RootStore
  loaderStore
  additionalImages: null | any[] = null
  fetchProfileError = ''
  externalData = null
  metaData: any = null
  transportProfile: any = null
  avtocodData: any = {}
  autoPairsData: any = []
  isSaving = false
  screeningLayer = null
  accidents: any[] = []
  isProfileDataRequested = false
  isFromProfile = false
  requestedParams: RequestedParams = {}
  userPhotos: string[] = []
  tripPhotos: string[] = []
  isAuth = false
  mapId: string | null = null
  radarId: string | null = null
  isRadarDataRequesting = false
  radarUserInfo: RadarUserInfo | null = null
  notSubscribedChannels: null | NotSubscribedChannel[] = null
  // посты с подпиской и без
  notSubscribedHexagons: HexagonsGeoData[] = null
  subscribedChannels: null | ChannelInfo[] = null
  userDetections: HexagonInfo[] | null = null
  detectionsWithPosts: HexagonInfo[] | null = null
  radarError = ''
  tripPhotosId: string | null = null
  tripPhotoGrz: string | null = null
  tripPhotosRequested = false
  additionalImagesAdded = false
  tripPhotosAdded = false
  transportTypes: null | Record<string, boolean> = null
  links = {
    vkId: null,
    tgId: null,
    tgUserName: null,
  } as Record<string, string | null>
  tgData = {
    registration: '',
    geoInfo: [] as GeoInfo[],
    communities: [] as string[],
    changes: null as TgChange[] | null,
    servers: null as string[] | null,
    historyPastUsers: null as HistoryPastUser[] | null,
  }
  // tgLinkedUsers: null | TgLinkedUsers = null

  vk = {}
  vkUserInfo = null
  vkHistory: null | VkHistory = null

  apps: any = null
  relations: any = null
  additionalInfo: any = null
  gibddData: any = null
  insurance: any = null

  socialProfileData: any = null

  tripPhotosTableData: any = null

  insights: null | Insights = null
  relationships: null | Relationships = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.loaderStore = rootStore.loaderStore
    makeAutoObservable(this)
  }

  authUser = async () => {
    this.rootStore.loaderStore.setIsLoading(true)

    const res = await request(
      `/login`,
      'POST',
      {},
      { login: 'im', password: 'gfh43gh56g' }
    )

    runInAction(() => {
      if (res.success) {
        localStorage.setItem('accessToken', res.payload.token)
        this.isAuth = true
      } else {
        this.isAuth = false
        this.rootStore.loaderStore.setIsLoading(false)
      }
    })
  }

  fetchProfileData = async (params) => {
    const dataId = params.find((el) => el.includes('dataId'))
    const userId = params.find((el) => el.includes('userId'))
    const isAdmin = params.find((el) => el.includes('isAdmin'))

    const data = dataId.slice(dataId.indexOf('=') + 1)
    const user = userId.slice(userId.indexOf('=') + 1)
    const admin = isAdmin.slice(isAdmin.indexOf('=') + 1)

    const headers = { dataId: data, userId: user, isAdmin: admin }

    this.requestedParams = headers

    const res: any = await request(
      `/get_data_profile/${data}`,
      'GET',
      {},
      {},
      false,
      true,
      { ...headers }
    )

    runInAction(() => {
      if (res.success) {
        this.isFromProfile = true
        // this.isProfileDataRequested = true
        const data = res?.payload

        if (data?.additional_images) {
          this.additionalImages = data?.additional_images?.transport_imgs

          if (this?.additionalImages?.length) {
            this.additionalImagesAdded = true
          }
        }

        if (data?.additional_info) {
          this.additionalInfo = data.additional_info

          if (data?.additional_info?.modes_transport) {
            this.transportTypes = data.additional_info.modes_transport
          }
        }

        this.vkUserInfo = data?.vk_info

        // this.tgLinkedUsers = withTag
        // this.tgLinkedUsers = data?.linked_users

        this.handleExternalInfo(data?.external_sources)
        this.metaData = data?.meta_data
        this.transportProfile = data?.transport_profile
        this.socialProfileData = data?.transport_profile?.infogorod_stats
        this.accidents = data?.transport_profile?.road_accident
        this.autoPairsData = data?.transport_profile?.profile_auto_pairs
        this.apps = data?.transport_profile?.app_profile?.map((app: any) => ({
          application: app?.application,
          data_nm: app?.data_nm,
          data_value: app?.data_value,
          period_to: app?.period_to,
          phone: app?.phone,
        }))
        this.userPhotos = data?.additional_images?.person_imgs

        this.links.vkId = data?.external_sources?.vk?.godeye_vk_id?.request
        if (
          !this.links.vkId &&
          data?.external_sources?.vk?.godeye_vk_url?.request
        ) {
          this.links.vkId = data?.external_sources?.vk?.godeye_vk_url?.request
        }

        this.links.tgId = data?.external_sources?.tg_id?.godeye_tg_id?.request
        this.links.tgUserName = data?.external_sources?.tg_user_name?.request
        if (
          !this.links.tgUserName &&
          data?.external_sources?.tg_user_name?.godeye_tg_user_name?.request
        ) {
          this.links.tgUserName =
            data.external_sources.tg_user_name.godeye_tg_user_name.request
        }

        if (
          !this.links.tgId &&
          data?.external_sources?.tg_user_name?.godeye_tg_user_name?.response
            ?.id
        ) {
          this.links.tgId =
            data.external_sources.tg_user_name.godeye_tg_user_name.response.id
        }

        const relations = data?.transport_profile?.passenger_rel?.main

        if (relations && Object.keys(relations)?.length) {
          this.relations = relations
        }

        if (data?.relationships && Object.keys(data.relationships).length) {
          this.relationships = data.relationships
        }

        this.mapId = data?.map_id
        this.tripPhotosId = data?.trip_photo_id
        this.tripPhotoGrz = data?.trip_photo_grz

        this.radarId = data?.radar_id

        if (this.radarId) {
          this.fetchRadarData(data.radar_id)
        }

        if (data?.trip_photo_id) {
          this.fetchTripPhotos(data.trip_photo_id, headers)
        }

        if (
          (!this.radarId && !data.trip_photo_id) ||
          (this.radarId && !data.trip_photo_id)
        ) {
          this.rootStore.loaderStore.setIsLoading(false)
        }
      } else {
        this.fetchProfileError =
          res.error ?? 'Данные устарели, пожалуйста, повторите запрос'
        this.loaderStore.setIsLoading(false)
      }
    })
  }

  handleExternalInfo = (data: any) => {
    const godEyePhoneRes = data?.phone?.godeye_phone?.response
    const godEyeGrzRes = data?.grz?.godeye_grz?.response
    const avtonomerRes = data?.grz?.avtonomer?.response
    const nomerogramRes = data?.grz?.nomerogram?.response?.info
    const avtocodRes = data?.grz?.avtocod?.response
    const tgIdRes = data?.tg_id?.godeye_tg_id?.response
    const godEyeTgUserName = data?.tg_user_name?.godeye_tg_user_name?.response
    const insightRes =
      data?.tg_id?.insight_tg_id?.response ||
      data?.tg_user_name?.insight_tg_user_name?.response
    // const tgProfileTgUserName = data?.tg_user_name?.tg_profile?.response
    let vkRes = data?.vk?.godeye_vk_id?.response
    if (!vkRes && data?.vk?.godeye_vk_url?.response) {
      vkRes = data.vk.godeye_vk_url.response
    }
    const vkHistory = data?.vk?.vk_history_robots

    if (avtonomerRes?.length) {
      // avtonomerRes.forEach((cur: any) => {
      //   if (cur?.photo) {
      //     this.tripPhotos.push(cur.photo)
      //   }
      // })
    }

    if (nomerogramRes?.photos?.length) {
      // nomerogramRes.photos.forEach((photo: string) =>
      //   this.tripPhotos.push(photo)
      // )
    }

    if (godEyePhoneRes?.photos?.length) {
      // godEyePhoneRes.photos.forEach((photo: string) =>
      //   this.userPhotos.push(photo)
      // )
    }

    if (godEyeGrzRes?.photos?.length) {
      // godEyeGrzRes.photos.forEach((photo: string) =>
      //   this.tripPhotos.push(photo)
      // )
    }

    if (godEyeGrzRes?.gibdd) {
      this.gibddData = godEyeGrzRes.gibdd
    }

    if (godEyeGrzRes?.insurance) {
      this.insurance = godEyeGrzRes.insurance
    }

    //tgIdRes - повторяются с tgUserName
    if (tgIdRes?.registration) {
      this.tgData.registration = tgIdRes?.registration
    }

    if (tgIdRes?.profile_changes?.length) {
      this.tgData.changes = tgIdRes.profile_changes
    }

    if (tgIdRes?.possible_servers?.length) {
      this.tgData.servers = tgIdRes.possible_servers
    }

    if (tgIdRes?.possible_communities?.length) {
      this.tgData.communities = tgIdRes?.possible_communities
    }

    //godEyeTgUserName - повторяются с tgIdRes
    if (godEyeTgUserName?.registration && !this.tgData.registration) {
      this.tgData.registration = godEyeTgUserName.registration
    }

    if (godEyeTgUserName?.profile_changes?.length && !this.tgData.changes) {
      this.tgData.changes = godEyeTgUserName.profile_changes
    }

    if (
      godEyeTgUserName?.possible_servers?.length &&
      !this.tgData.servers?.length
    ) {
      this.tgData.servers = godEyeTgUserName.possible_servers
    }

    if (
      godEyeTgUserName?.possible_communities?.length &&
      !this.tgData.communities?.length
    ) {
      this.tgData.communities = godEyeTgUserName?.possible_communities
    }

    if (godEyeTgUserName?.id_history_past_users?.length) {
      this.tgData.historyPastUsers =
        godEyeTgUserName?.id_history_past_users?.length
    }

    if (vkRes && Object.keys(vkRes)?.length) {
      this.vk = vkRes
    }

    if (vkHistory) {
      this.vkHistory = vkHistory
    }

    if (avtocodRes) {
      if (avtocodRes === "'Grz' не актуальный" || avtocodRes?.error) {
        return
      }

      this.avtocodData = avtocodRes
    }

    if (insightRes && Object.keys(insightRes).length) {
      this.insights = insightRes
    }
  }

  fetchTripPhotos = async (id: string, headers) => {
    const _headers = this.isFromProfile ? {} : { ...headers }

    const res = await request(
      `get_data_trip_photos/${id}`,
      'GET',
      {},
      {},
      false,
      true,
      { ..._headers }
    )
    const { success, payload } = res

    runInAction(() => {
      if (success) {
        this.tripPhotosTableData = payload

        const photos = payload.data.map((info: any) =>
          info?.photo?.find((photo: any) => photo.type === 0)
        )

        photos?.forEach((photo: any) => {
          if (!photo) {
            return
          }

          this?.tripPhotos?.push(photo?.photo)
        })
        this.tripPhotosAdded = true
      }

      this.tripPhotosRequested = true
      this.isProfileDataRequested = true
    })

    this.rootStore.loaderStore.setIsLoading(false)
  }

  fetchRadarData = async (id: string, headers) => {
    this.setIsRadarDataRequesting(true)

    const _headers = this.isFromProfile ? {} : { ...headers }

    const res = await request(`${TG_RADAR}/${id}`, 'GET', {}, {}, false, true, {
      ..._headers,
    })
    const { error, success, payload } = res

    runInAction(() => {
      if (success) {
        this.radarUserInfo = payload?.radar_user_info

        if (this.radarUserInfo?.hexagons?.length) {
          this.userDetections = this.radarUserInfo.hexagons
        }

        if (
          this.radarUserInfo?.channels_not_subscribed?.length ||
          this.radarUserInfo?.channels_subscribed?.length
        ) {
          if (this.radarUserInfo?.channels_subscribed?.length) {
            this.subscribedChannels = this.radarUserInfo.channels_subscribed
          }

          if (this.radarUserInfo?.channels_not_subscribed?.length) {
            this.notSubscribedChannels =
              this.radarUserInfo.channels_not_subscribed
          }

          // посты с подпиской и без
          const notSubscribedHexagons =
            this.radarUserInfo.channels_not_subscribed
              .concat(this.radarUserInfo?.channels_subscribed)
              .map((channel) => ({
                coordinates: channel?.geo?.coordinates,
                hexagon_id: channel?.hexagon_id,
                title: channel?.title,
                postsCount: channel?.posts_count,
                channelUsername: channel?.channel_username,
              }))

          if (notSubscribedHexagons.some((hex) => hex.hexagon_id)) {
            this.notSubscribedHexagons = notSubscribedHexagons
          }
        }

        if (this.userDetections?.length && this.notSubscribedHexagons?.length) {
          const detectionsWithPosts = this.userDetections?.filter(
            (detection) =>
              this.notSubscribedHexagons?.findIndex(
                (post) => post.hexagon_id === detection.hexagon_id
              ) !== -1
          )

          if (detectionsWithPosts.length) {
            this.detectionsWithPosts = detectionsWithPosts.map((det) => {
              const detX = det.geo.coordinates[0].map(([x, _y]) => x)
              const detY = det.geo.coordinates[0].map(([_x, y]) => y)

              const midX = detX
                .slice(0, 6)
                .reduce((acc, cur, _idx, arr) => acc + cur / arr.length, 0)
              const midY = detY
                .slice(0, 6)
                .reduce((acc, cur, _idx, arr) => acc + cur / arr.length, 0)

              return {
                ...det,
                middle: [midX, midY],
              }
            })
          }
        }
      } else {
        this.rootStore.mapStore.setProfileMapError(error)
        this.rootStore.newMapStore.setProfileMapError(error)
      }
    })

    this.setIsRadarDataRequesting(false)
  }

  setIsFromProfile = (isFromProfile: boolean) => {
    this.isFromProfile = isFromProfile
  }

  setSelectedTab = (selectedTab: number) => {
    this.selectedTab = selectedTab
  }

  setIsRadarDataRequesting = (isRadarDataRequesting: boolean) => {
    this.isRadarDataRequesting = isRadarDataRequesting
  }

  setIsSaving = (isSaving: boolean) => {
    this.isSaving = isSaving
  }

  setScreeningLayer = (screeningLayer: string) => {
    this.screeningLayer = screeningLayer
  }
}
