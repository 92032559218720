import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { UserChannels } from './UserChannels'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Box, Stack, Typography } from '@mui/material'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import { LinkedUserChannels as LinkedUserChannelsI } from 'store/profileStore/profileStore.types'

export const LinkedUserChannels = observer<LinkedUserChannelsI>((channels) => {
  const [expandedChannel, setExpandedChannel] = useState<string | false>(false)

  const handleChannelChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedChannel(newExpanded ? panel : false)
    }

  return (
    <Stack>
      <Typography mb={2} fontWeight={700}>
        Сообщества пользователя
      </Typography>

      {Object.entries(channels).map(([id, data]) => (
        <Accordion
          key={Math.random()}
          expanded={expandedChannel === id}
          onChange={handleChannelChange(id)}
        >
          <AccordionSummary>
            <Stack direction='row'>
              <Box
                sx={{
                  mr: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SubheaderIcon name='ChatOutlinedIcon' />
              </Box>

              <Typography variant='body1' color='common.black'>
                ID Сообщества: {id}
              </Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <UserChannels key={Math.random()} {...data} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  )
})
