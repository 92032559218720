import { Box, Stack, styled } from '@mui/material'

export const Wrapper = styled(Stack)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  overflowY: 'auto',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.secondary.light,
  alignItems: 'center',

  '*': {
    boxSizing: 'border-box',
  },

  p: {
    color: theme.palette.common.black,
  },
}))

export const InnerWrapper = styled(Stack)(({ theme }) => ({
  maxWidth: 900,
  width: '100%',
  justifyContent: 'center',
  alignContent: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: '16px 24px 40px 24px',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    padding: '16px 16px 40px 16px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '8px 8px 40px 8px',
  },
}))

export const MainPhotoWrapper = styled(Stack)({
  maxWidth: 300,
  height: 300,
  marginTop: 16,
  borderRadius: 16,
  position: 'relative',
  width: '100%',
})

export const Card = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  width: 'calc(50% - 16px)',
  margin: 8,
  borderRadius: 12,

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const GodEyeTextInfoWrapper = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
}))

export const GodEyePhotosWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  flexWrap: 'wrap',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}))

export const GodEyePhotoBox = styled(Box)({
  height: 150,
  width: 150,
  margin: 8,
})

export const MordovorotPhotosWrapper = styled(Stack)({
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  justifyContent: 'center',
  borderRadius: 12,
})

export const MordovorotCard = styled(Stack)({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  maxHeight: 150,
  alignItems: 'flex-start',
  padding: 8,

  p: {
    wordBreak: 'break-all',
    textAlign: 'left',
  },
})
