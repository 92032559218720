import { Stack, Typography } from '@mui/material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import * as S from './FaceMatching.styled'
import { v4 } from 'uuid'
import { Mordovorot } from './Mordovorot'
import { Image } from 'antd'
import checkedIcon from 'assets/checkedIcon.svg'

export const FaceMatching = observer(() => {
  const { rootStore } = useContext(Context)
  const { faceMatchingStore } = rootStore
  const { authUser, isAuth, isDataRequested, fetchData, fetchedData } =
    faceMatchingStore

  const params = useParams()?.data

  useEffect(() => {
    if (!isAuth) {
      authUser()
    }
  }, [authUser, isAuth])

  useEffect(() => {
    if (isAuth) {
      if (!isDataRequested && params) {
        fetchData(params?.split('&'))
      }
    }
  }, [fetchData, isAuth, isDataRequested, params])

  const findClone = useMemo(() => {
    if (
      fetchedData?.external_src?.findclone?.response?.clone_profiles?.length
    ) {
      const arr = fetchedData.external_src.findclone.response.clone_profiles

      return arr
    }

    return null
  }, [fetchedData?.external_src?.findclone?.response?.clone_profiles])

  const godyEyePhotos = useMemo(() => {
    if (fetchedData?.external_src?.godeye_photo?.response?.profiles?.length) {
      const arr = fetchedData.external_src.godeye_photo.response.profiles

      return arr
    }

    return null
  }, [fetchedData?.external_src?.godeye_photo?.response?.profiles])

  const mordovorotData = useMemo(() => {
    if (fetchedData?.mordavorot?.length) {
      return fetchedData.mordavorot.filter((item) => item?.url)
    }
  }, [fetchedData?.mordavorot])

  if (!fetchedData) {
    return null
  }

  return (
    <S.Wrapper>
      <S.InnerWrapper spacing={2}>
        {fetchedData?.target_img ? (
          <Stack justifyContent='center' alignItems='center'>
            <Typography variant='h5'>Исходное изображение</Typography>

            <S.MainPhotoWrapper boxShadow={4}>
              <Stack
                sx={{
                  width: 1,
                  alignItems: 'flex-end',
                  zIndex: 10,
                  position: 'absolute',
                  top: { xs: -23, sm: -20 },
                  right: { xs: -13, sm: -20 },
                }}
              >
                <img
                  src={checkedIcon}
                  alt='checkedIcon'
                  width={85}
                  height={85}
                />
              </Stack>

              <Image
                style={{ cursor: 'pointer' }}
                preview={{ mask: false }}
                height={'100%'}
                width={'100%'}
                src={fetchedData.target_img}
              />
            </S.MainPhotoWrapper>
          </Stack>
        ) : null}

        {findClone ? (
          <Stack>
            <Typography variant='h5'>FindClone</Typography>

            <Stack direction='row' flexWrap='wrap' mt={2}>
              {findClone.map(({ city, firstname, img_link, score, uri }) => (
                <S.Card key={v4()} boxShadow={4}>
                  {img_link ? (
                    <Image
                      style={{
                        cursor: 'pointer',
                        borderRadius: '12px 0 0 12px',
                      }}
                      preview={{ mask: false }}
                      height={150}
                      width={150}
                      src={img_link}
                    />
                  ) : null}

                  <Stack
                    ml={2}
                    spacing={1}
                    alignItems='flex-start'
                    justifyContent='center'
                  >
                    {uri ? (
                      <Typography variant='body2'>
                        <a
                          href={`https://${uri}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Профиль
                        </a>
                      </Typography>
                    ) : null}

                    {firstname ? (
                      <Typography variant='body2'>Имя: {firstname}</Typography>
                    ) : null}

                    {city ? (
                      <Typography variant='body2'>Город: {city}</Typography>
                    ) : null}

                    {score ? (
                      <Typography variant='body2'>Очки: {score}</Typography>
                    ) : null}
                  </Stack>
                </S.Card>
              ))}
            </Stack>
          </Stack>
        ) : null}

        {godyEyePhotos ? (
          <Stack>
            <Typography variant='h5'>Глаз Бога</Typography>

            <Stack direction='row' flexWrap='wrap' mt={2}>
              {godyEyePhotos.map(({ name, photos, city, matches, page }) => (
                <S.Card
                  key={v4()}
                  boxShadow={4}
                  sx={{ width: '100%', flexDirection: 'column', p: 1 }}
                >
                  <S.GodEyeTextInfoWrapper
                    m={1}
                    spacing={1}
                    alignItems='flex-start'
                    justifyContent='center'
                  >
                    {page ? (
                      <Typography variant='body2'>
                        <a
                          href={`https://${page}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Профиль
                        </a>
                      </Typography>
                    ) : null}

                    {name ? (
                      <Typography variant='body2'>Имя: {name}</Typography>
                    ) : null}

                    {city ? (
                      <Typography variant='body2'>Город: {city}</Typography>
                    ) : null}

                    {matches ? (
                      <Typography variant='body2'>
                        Точность: {matches}
                      </Typography>
                    ) : null}
                  </S.GodEyeTextInfoWrapper>

                  {photos?.length ? (
                    <S.GodEyePhotosWrapper>
                      {photos.map((photo) => (
                        <S.GodEyePhotoBox key={v4()}>
                          <Image
                            style={{
                              cursor: 'pointer',
                              height: 150,
                              width: 150,
                              borderRadius: 12,
                              boxShadow:
                                '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
                            }}
                            preview={{ mask: false }}
                            src={photo}
                          />
                        </S.GodEyePhotoBox>
                      ))}
                    </S.GodEyePhotosWrapper>
                  ) : null}
                </S.Card>
              ))}
            </Stack>
          </Stack>
        ) : null}

        {mordovorotData?.length ? <Mordovorot data={mordovorotData} /> : null}
      </S.InnerWrapper>
    </S.Wrapper>
  )
})
