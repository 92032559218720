import { observer } from 'mobx-react-lite'
import React from 'react'

import policeman from 'assets/policeIcon.png'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Box, Stack, Typography } from '@mui/material'
import { useGibddInfo } from './useGibddInfo'

export const GibddInfo = observer(() => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const { gibddData, translateGibdd } = useGibddInfo()

  return gibddData ? (
    <Box sx={{ mt: 2, maxWidth: '900px', width: '100%', px: '20px' }}>
      <Accordion
        expanded={expanded === 'gibdd'}
        onChange={handleChange('gibdd')}
      >
        <AccordionSummary>
          <Stack direction='row' alignItems='center'>
            <Stack mr={1} justifyContent='center' alignItems='center'>
              <img src={policeman} alt='policeman' width={24} height={24} />
            </Stack>

            <Typography color='common.black' variant='body1'>
              Данные ГИБДД
            </Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <Stack
            justifyContent='space-between'
            sx={{
              mt: 1,
              width: '100%',
              maxWidth: '900px',
            }}
          >
            {gibddData.map(([key, value]: any, idx: number) =>
              translateGibdd?.[key] ? (
                <Stack
                  key={idx}
                  justifyContent='space-between'
                  flexDirection='row'
                  sx={{ mt: idx ? 1 : 0 }}
                >
                  <Typography
                    color='common.black'
                    width='50%'
                    textAlign='start'
                  >
                    {translateGibdd[key].text}:
                  </Typography>

                  <Typography
                    width='50%'
                    textAlign='end'
                    color='text.disabled'
                    sx={{ overflowWrap: 'break-word' }}
                  >
                    {value}
                  </Typography>
                </Stack>
              ) : null
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  ) : null
})
