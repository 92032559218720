import { makeAutoObservable } from 'mobx'
import MapStore from './mapStore/mapStore'
import NewMapStore from './newMapStore/newMapStore'
import GraphStore from './graphStore/graphStore'
import TableStore from './tableStore/tableStore'
import LoaderStore from './loaderStore'
import AthenaStore from './athenaStore'
import { ProfileStore } from './profileStore'
import { RadarStore } from './radarStore'
import FaceMatchingStore from './faceMatchingStore/faceMatchingStore'
export class RootStore {
  mapStore = new MapStore(this)
  newMapStore = new NewMapStore(this)
  graphStore = new GraphStore(this)
  tableStore = new TableStore(this)
  loaderStore = new LoaderStore(this)
  athenaStore = new AthenaStore(this)
  profileStore = new ProfileStore(this)
  radarStore = new RadarStore(this)
  faceMatchingStore = new FaceMatchingStore(this)

  constructor() {
    makeAutoObservable(this)
  }
}
