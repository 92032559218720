export const translatedKeys = {
  first_name: 'Имя',
  last_name: 'Фамилия',
  name: 'Имя',
  phone: 'Телефон',
  link: 'Ссылка на профиль',
  online: 'В сети',
  verified: 'Профиль подтвержден',
  linkedUsers: 'Связанные пользователи',
  bot: 'Бот',
  deleted: 'Профиль удален',
  fake: 'Фейковый профиль',
  lang_code: 'Код языка',
  userame: 'Профиль',
  reply_count: 'Количество ответов',
  tag_count: 'Количество Тегов',
  timedelta_count: 'Таймдельта',
  w: 'Вес',
  channel_username: 'Ссылка на сообщество',
  title: 'Заголовок',
}
