import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SidebarWrapper = styled(Stack)({
  width: '100%',
  height: '100%',
})

export const DistrictsHeader = styled(Stack)({
  backgroundColor: '#030303',
  borderRadius: '8px 8px 0px 0px',
  color: '#fff',
  alignItems: 'flex-start',
  padding: '16px',
})

export const DistrictSubheader = styled(Stack)({
  width: '100%',
  alignItems: 'flex-start',
  color: '#fff',
  marginBottom: '8px',
})

export const HexagonInfo = styled(Stack)({
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: 16,
})
