import React, { useMemo } from 'react'
import { getCountText } from '../FeaturePopup.utils'
import BikeSessions from './BikeSessions'
import s from '../../Map.module.scss'

interface _VelobikeInfo {}

interface VelobikeProps {
  // data: VelobikeInfo
  data: any
}

export const Velobikes = ({ data }: VelobikeProps) => {
  const count = useMemo(() => {
    let _count = 0
    data.forEach((location: any) => (_count += location.count))
    return _count
  }, [data])

  const getVelobikeSessionsData = useMemo(() => {
    if (data && data.length) {
      const result: any = []

      data.forEach((obj: any) => {
        obj.session_id.forEach((session: any, sessionIdx: number) => {
          result.push({
            ...obj,
            day: obj.day[sessionIdx],
            end_session_dttm: obj.end_session_dttm[sessionIdx],
            session_distance: obj.session_distance[sessionIdx],
            session_dur: obj.session_dur[sessionIdx],
            session_id: session,
            start_session_dttm: obj.start_session_dttm[sessionIdx],
          })
        })
      })
      return result
    }
  }, [data])

  return (
    <div className={s.locations}>
      <div className={s.visitedCount}>
        <b>Появлялся в локации:</b> <span>{getCountText(count)}</span>
      </div>

      <BikeSessions sessions={getVelobikeSessionsData(data)} />
    </div>
  )
}
