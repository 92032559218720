import React from 'react'
import { CheckCircleOutlineOutlined, ErrorOutline } from '@mui/icons-material'

import colors from '../colors/colors.module.scss'

export const components = {
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        error: <ErrorOutline />,
        success: <CheckCircleOutlineOutlined />,
      },
    },
    styleOverrides: {
      root: {
        borderRadius: '6px',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        '&:hover': {
          cursor: 'pointer',
        },
        textDecoration: 'none',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        '&.Mui-disabled': {
          backgroundColor: '#F1F0FA',
          color: '#D7D4F1',
        },
      },
      sizeLarge: {
        padding: '8px 20px',
        height: '40px',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        marginRight: 4,
        marginLeft: 4,

        '&.Mui-selected': {
          backgroundColor: '#E7EAF8',
          color: '#5578EB',
          fontWeight: 700,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: colors.webRed,
        '&$error': {
          color: colors.webRed,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: '#5578EB',
      },
    },
  },
}
