import { Box, OutlinedInput } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { Context } from 'index'

export const InputFilter = observer(() => {
  const { rootStore } = useContext(Context)
  const { radarStore } = rootStore
  const { selectFilterValue, setSelectFilterValue } = radarStore

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const val = e.target.value

      setSelectFilterValue(val)
    },
    [setSelectFilterValue]
  )

  return (
    <Box sx={{ p: 2 }}>
      <OutlinedInput
        sx={{ bgcolor: '#F1F1F1' }}
        value={selectFilterValue}
        onChange={(e) => handleInputChange(e)}
        placeholder='Поиск округа или района'
        endAdornment={<SearchIcon />}
        type='search'
        size='small'
        fullWidth
      />
    </Box>
  )
})
