/* eslint-disable react/prop-types */
import s from './Map.module.scss'
import React from 'react'

const normalizeDate = (dateAndTime) => {
  const [date, time] = dateAndTime.split(' ')
  const [YYYY, MM, DD] = date.split('-')
  const normalizedTime = time.split('.')[0]

  return `${DD}.${MM}.${YYYY} - ${normalizedTime}`
}

export const PochtaVisits = ({ visits }) => {
  return (
    <div className={s.visit}>
      {visits &&
        visits.map((visit, idx) => (
          <div
            key={`${visit.time_search}_${idx}`}
            className={`${s.pochtaVisit} ${
              idx < visits.length - 1 && s.pochtaVisitMargin
            }`}
          >
            <>
              <p className={s.subHeader}>Отправитель:</p>
              <span>{visit.fio_rpo_sndr_name}</span>

              <p className={s.subHeader}>Дата доставки посылки:</p>
              <span>{normalizeDate(visit.delivery_local_dts)}</span>

              <p className={s.subHeader}>Получатель:</p>
              <span>{visit.fio_rpo_rcpn_name}</span>

              <p className={s.subHeader}>Город получателя:</p>
              <span>{visit.address_settlement_to_name}</span>

              <p className={s.subHeader}>Индекс получателя:</p>
              <span>{visit.index_to_ccode}</span>

              <p className={s.subHeader}>Адрес доставки посылки:</p>
              <span>{visit.address_by_index}</span>

              <p className={s.subHeader}>Телефон получателя:</p>
              <span className={s.lastItem}>{visit.phone}</span>
            </>
          </div>
        ))}
    </div>
  )
}
