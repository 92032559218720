import { Stack, styled } from '@mui/material'

export const ChartContainer = styled(Stack)<{ count: number }>(
  ({ count, theme }) => ({
    margin: '24px auto 0 auto',
    width: `calc(40% + ${count}%)`,

    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '24px 0',
    },
  })
)
