import metroIcon from 'assets/metroIcon.svg'
import ngptIcon from 'assets/ngptIcon.svg'

import { Stack, Typography } from '@mui/material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { SubheaderIcon } from '../components/SubheaderIcon'
import { PopularRoute } from './PopularRoute'
import { SummaryTransRecord } from './SummaryTransRecord'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'

export const PublicTransportInfo = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { transportProfile } = profileStore

  const summary = useMemo(() => transportProfile?.summary, [transportProfile])

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const hasData = useMemo(
    () =>
      !summary ||
      Boolean(
        (summary &&
          (summary?.top_start_subway_station ||
            summary?.top_end_subway_station)) ||
          summary?.top_start_subway_line ||
          summary?.top_end_subway_line ||
          summary?.top_subway_route?.end_line ||
          summary?.top_subway_route?.end_station ||
          summary?.top_subway_route?.start_line ||
          summary?.top_subway_route?.start_station ||
          summary?.total_subway_trips ||
          summary?.top_start_ngpt_station ||
          summary?.top_end_ngpt_station ||
          summary?.total_ngpt_trips
      ),
    [summary]
  )

  if (!summary || !hasData) {
    return null
  }

  return (
    <Accordion
      expanded={expanded === 'publicTransport'}
      onChange={handleChange('publicTransport')}
    >
      <AccordionSummary>
        <Stack direction='row' alignItems='center'>
          <Stack mr={1} justifyContent='center' alignItems='center'>
            <SubheaderIcon name='DirectionsBusOutlinedIcon' warningType />
          </Stack>

          <Typography color='common.black' variant='body1'>
            Общественный транспорт
          </Typography>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack
          justifyContent='space-between'
          sx={{
            mt: 1,
            width: '100%',
            maxWidth: '900px',
          }}
        >
          {summary?.top_start_subway_station ||
          summary?.top_end_subway_station ? (
            <SummaryTransRecord
              title='metroTrips'
              blackKey
              name='Популярная станция начала поездки метро:'
              description={`${
                `м.${summary?.top_start_subway_station}` ||
                summary?.top_start_subway_line
              }`}
            />
          ) : null}

          {summary?.top_start_subway_line || summary?.top_end_subway_line ? (
            <SummaryTransRecord
              blackKey
              name='Популярная станция окончания поездки метро:'
              description={`${
                `м.${summary?.top_end_subway_station}` ||
                summary?.top_end_subway_line
              }`}
            />
          ) : null}

          {summary?.top_subway_route?.end_line ||
          summary?.top_subway_route?.end_station ||
          summary?.top_subway_route?.start_line ||
          summary?.top_subway_route?.start_station ? (
            <PopularRoute
              title={
                <Stack flexDirection='row' alignItems='center'>
                  <img
                    src={metroIcon}
                    height={24}
                    width={24}
                    alt='metroRouteIcon'
                  />

                  <Typography
                    textAlign='left'
                    variant='h6'
                    color='common.black'
                    sx={{ ml: 1 }}
                  >
                    Популярный маршрут поездки метро
                  </Typography>
                </Stack>
              }
              from={
                `м.${summary?.top_subway_route?.start_station}` ||
                summary?.top_subway_route?.start_line
              }
              to={
                `м.${summary?.top_subway_route?.end_station}` ||
                summary?.top_subway_route?.end_line
              }
            />
          ) : null}

          {summary?.total_subway_trips ? (
            <SummaryTransRecord
              blackKey
              name='Всего поездок на метро:'
              description={summary?.total_subway_trips}
            />
          ) : null}

          {summary?.top_start_ngpt_station || summary?.top_end_ngpt_station ? (
            <PopularRoute
              title={
                <Stack flexDirection='row' alignItems='center'>
                  <img src={ngptIcon} height={24} width={24} alt='filterIcon' />

                  <Typography
                    textAlign='left'
                    variant='h6'
                    color='common.black'
                    sx={{ ml: 1 }}
                  >
                    Популярный маршрут НГПТ:
                  </Typography>
                </Stack>
              }
              from={`ост.${summary?.top_start_ngpt_station}`}
              to={`ост.${summary?.top_end_ngpt_station}`}
            />
          ) : null}

          {summary?.total_ngpt_trips ? (
            <SummaryTransRecord
              blackKey
              name='Всего поездок на НГПТ:'
              description={summary?.total_ngpt_trips}
            />
          ) : null}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
})
