import { Stack, Typography, Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { Context } from '../../..'
import CommuteIcon from '@mui/icons-material/Commute'
import { SummaryTransRecord } from './SummaryTransRecord'
import { ProfileTransportSummary } from './ProfileTransportSummary'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'

import AssessmentIcon from '@mui/icons-material/Assessment'
import { SubheaderIcon } from '../components/SubheaderIcon'
import { Header } from '../components/Header'
import { FinesAndAccidents } from './FinesAndAccidents'
import { TransportRelations } from './TransportRelations'
import { CollapsibleBlock } from '../components/CollapsibleBlock'
import { v4 } from 'uuid'

export const ProfileTransportInfo = observer(({ info }: { info: any }) => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { transportProfile, avtocodData, autoPairsData } = profileStore

  const summary = useMemo(() => transportProfile?.summary, [transportProfile])

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const fields = {
    grzs: {
      order: 3,
      name: 'Автомобильные номера',
      icon: <SubheaderIcon name='PinOutlinedIcon' warningType />,
    },
    marks: {
      order: 7,
      name: 'Автомобильные бренды',
      icon: <SubheaderIcon name='DirectionsCarFilledIcon' warningType />,
    },
  } as any

  return (summary && Object.keys(summary)?.length) ||
    (avtocodData && Object.keys(avtocodData)?.length) ||
    autoPairsData?.length ||
    (info && Object.keys(info)?.length) ? (
    <Stack
      justifyContent='space-between'
      sx={{
        mt: 3,
        width: '100%',
        maxWidth: '900px',
        px: '20px',
      }}
    >
      <Header
        text='Транспортные данные'
        icon={
          <CommuteIcon
            sx={{ bgcolor: 'primary.light', color: 'common.white' }}
          />
        }
      />

      {info && Object.keys(info)?.length ? (
        <Stack sx={{ py: 2 }}>
          {Object.entries(info)?.map(([type, vals]: any) => (
            <Stack key={v4()}>
              <Stack direction='row'>
                <Box
                  sx={{
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {fields?.[type]?.icon}
                </Box>

                <Typography variant='h6' color='common.black'>
                  {fields?.[type]?.name}
                </Typography>
              </Stack>

              <CollapsibleBlock type={type} vals={vals} />
            </Stack>
          ))}
        </Stack>
      ) : null}

      <TransportRelations />

      <FinesAndAccidents />

      <ProfileTransportSummary />

      {(avtocodData && Object.keys(avtocodData)?.length) ||
      autoPairsData?.length ? (
        <Box sx={{ mt: 3, mb: 1 }}>
          <Accordion
            expanded={expanded === 'avtocodData'}
            onChange={handleChange('avtocodData')}
          >
            <AccordionSummary>
              <Typography
                alignItems='center'
                color='common.black'
                variant='body1'
              >
                <AssessmentIcon sx={{ color: 'primary.main', mb: '-6px' }} />{' '}
                Информация об авто
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                justifyContent='space-between'
                sx={{
                  mt: 1,
                  width: '100%',
                  maxWidth: '900px',
                }}
              >
                {avtocodData?.grz ? (
                  <SummaryTransRecord
                    rowSx={{ p: 0 }}
                    name='ГРЗ'
                    description={avtocodData?.grz}
                    blackKey
                  />
                ) : null}

                {avtocodData?.model ? (
                  <SummaryTransRecord
                    rowSx={{ p: '8px 0 0 0' }}
                    name='Модель авто'
                    description={avtocodData?.model}
                    blackKey
                  />
                ) : null}

                {avtocodData?.year_of_production ? (
                  <SummaryTransRecord
                    rowSx={{ p: '8px 0 0 0' }}
                    name='Год производства'
                    description={avtocodData?.year_of_production}
                    blackKey
                  />
                ) : null}

                {avtocodData?.model ? (
                  <SummaryTransRecord
                    rowSx={{ p: '8px 0 0 0' }}
                    name='Расположение руля'
                    description={avtocodData?.model}
                    blackKey
                  />
                ) : null}

                {avtocodData?.engine_capacity ? (
                  <SummaryTransRecord
                    rowSx={{ p: '8px 0 0 0' }}
                    name='Объем двигателя'
                    description={avtocodData?.engine_capacity}
                    blackKey
                  />
                ) : null}

                {avtocodData?.engine_power ? (
                  <SummaryTransRecord
                    rowSx={{ p: '8px 0 0 0' }}
                    name='Мощность двигателя'
                    description={avtocodData?.engine_power}
                    blackKey
                  />
                ) : null}

                {avtocodData?.engine_type ? (
                  <SummaryTransRecord
                    rowSx={{ p: '8px 0 0 0' }}
                    name='Тип двигателя'
                    description={avtocodData?.engine_type}
                    blackKey
                  />
                ) : null}
              </Stack>
            </AccordionDetails>
          </Accordion>

          {autoPairsData?.length ? (
            <Accordion
              expanded={expanded === 'autoPairsData'}
              onChange={handleChange('autoPairsData')}
            >
              <AccordionSummary>
                <Typography
                  color='common.white'
                  fontWeight={700}
                  lineHeight='24px'
                  fontSize={20}
                >
                  Автопары
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  justifyContent='space-between'
                  sx={{
                    mt: 1,
                    width: '100%',
                    maxWidth: '900px',
                  }}
                >
                  {autoPairsData.map((pair: any, pairIdx: number) => (
                    <Stack key={pairIdx} sx={{ mb: 1 }}>
                      <Typography fontStyle='italic' color='primary.main'>
                        Пара № {pairIdx + 1}:
                      </Typography>

                      {pair?.regno ? (
                        <SummaryTransRecord
                          name='ГРЗ пользователя:'
                          description={pair?.regno}
                          blackKey
                        />
                      ) : null}

                      {pair?.pair_regno ? (
                        <SummaryTransRecord
                          name='ГРЗ пары:'
                          description={pair?.pair_regno}
                          blackKey
                        />
                      ) : null}

                      {pair?.total_trip_cnt ? (
                        <SummaryTransRecord
                          name='Количество дней с совместными поездками:'
                          description={pair?.total_trip_cnt}
                          blackKey
                        />
                      ) : null}

                      {pair?.total_trip_cnt ? (
                        <SummaryTransRecord
                          name='Количество совместных поездок:'
                          description={pair?.total_trip_cnt}
                          blackKey
                        />
                      ) : null}

                      {pair?.rush_hour_trip_cnt ||
                      pair?.non_rush_hour_trip_cnt ? (
                        <SummaryTransRecord
                          name='Поездок в час пик:'
                          description={pair?.rush_hour_trip_cnt}
                          blackKey
                        />
                      ) : null}

                      {pair?.rush_hour_trip_cnt ||
                      pair?.non_rush_hour_trip_cnt ? (
                        <SummaryTransRecord
                          name='Поездок не в час пик:'
                          description={pair?.non_rush_hour_trip_cnt}
                          blackKey
                        />
                      ) : null}

                      {pair?.pair_cnt ? (
                        <SummaryTransRecord
                          name='Количество пар'
                          description={pair?.pair_cnt}
                          blackKey
                        />
                      ) : null}
                    </Stack>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Box>
      ) : null}
    </Stack>
  ) : null
})
