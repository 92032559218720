/* eslint-disable react/prop-types */
import s from '../../Map.module.scss'
import React from 'react'

const formatDate = (date) => {
  const dateAndTime = date.split(' ')
  const [YYYY, MM, DD] = dateAndTime[0].split('-')

  return `${DD}.${MM}.${YYYY} - ${dateAndTime[1]}`
}

const getSessionDur = (duration) => {
  const hours = Math.floor(duration / 3600)
  const dur = duration % 3600
  const mins = Math.floor(dur / 60)
  const secs = dur % 60

  return `${hours ? hours + 'ч ' : ''}${mins ? mins + 'м ' : ''}${
    secs ? secs + 'с ' : ''
  }`
}

const getSessoinDistance = (distance) => {
  const KMs = Math.floor(distance / 1000)
  const dist = distance % 1000

  return `${KMs ? KMs + '.' : ''}${dist}м`
}

const BikeSessions = ({ sessions }) => {
  return (
    <div className={s.session}>
      {sessions &&
        sessions.map((session, idx) => (
          <div
            key={session.session_id}
            className={`${s.bikeSession} ${
              idx < sessions.length - 1 && s.bikeSessionMargin
            }`}
          >
            <>
              <p className={s.subHeader}>
                Маршрут{sessions.length > 1 && ` №${idx + 1}`}:{' '}
              </p>
              <span>
                {session.start_address} - {session.end_address}
              </span>

              <p className={s.subHeader}>Временные отметки:</p>
              <div className={s.timeIntervals}>
                Начало: {formatDate(session.start_session_dttm)}
              </div>
              <div className={s.timeIntervals}>
                Конец: {formatDate(session.end_session_dttm)}
              </div>

              <p className={s.subHeader}>Длительность поездки:</p>
              <span>{getSessionDur(session.session_dur)}</span>

              <p className={s.subHeader}>Дистанция поездки:</p>
              <span>{getSessoinDistance(session.session_distance)}</span>
            </>
          </div>
        ))}
    </div>
  )
}

export default BikeSessions
