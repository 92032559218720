import axios from 'axios'
import { stringify } from 'qs'

const baseFromLS = localStorage.getItem('baseUrl')

const baseUrl = baseFromLS || 'https://mfindsomething.ru:19876'

const createRequestConfig = (
  url,
  method,
  params,
  data,
  blob,
  useBaseUrl,
  headers
) => {
  const config = {
    url,
    method,
    params,
    data,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
  }
  const token = localStorage.getItem('accessToken')
  if (
    token &&
    (!config.headers.Authorization ||
      !config.headers.Authorization.startsWith(`Bearer`))
  ) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  if (blob) {
    config.responseType = 'blob'
  }
  if (baseUrl && useBaseUrl) {
    config.baseURL = baseUrl
  }
  return config
}

export const request = async (
  url,
  method = 'GET',
  params = {},
  data = {},
  blob = false,
  useBaseUrl = true,
  headers
) => {
  try {
    const config = createRequestConfig(
      url,
      method,
      params,
      data,
      blob,
      useBaseUrl,
      headers
    )
    const response = await axios.request(config)
    return { success: true, payload: response.data, code: response.status }
  } catch (e) {
    if (!e.response) {
      return {
        success: false,
        error: 'Что-то пошло не так',
        code: e.response?.status,
      }
    }

    return {
      success: false,
      error: e?.response?.data?.message,
      code: e.response.status,
    }
  }
}
