import { Box, Stack, Typography } from '@mui/material'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { HistoryCast } from './HistoryCast'

export const VkHistoryRobots = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { vkHistory } = profileStore

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  if (!vkHistory || !vkHistory?.response) {
    return null
  }

  const { request, response } = vkHistory

  const { casts } = response

  const getHref = (url: string) => {
    if (url.includes('http')) {
      return url
    }

    return url.includes('id')
      ? `https://vk.com/${url}`
      : `https://vk.com/id${url}`
  }

  return (
    <Stack
      sx={{
        maxWidth: '900px',
        width: '100%',
        px: '16px',
      }}
    >
      <Accordion
        expanded={expanded === 'historyRobots'}
        onChange={handleChange('historyRobots')}
      >
        <AccordionSummary>
          <Stack direction='row'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='ContentCopyOutlinedIcon' />
            </Box>

            <Typography variant='body1' color='common.black'>
              История страницы Vk
            </Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <Stack
            justifyContent='space-between'
            sx={{
              mt: 1,
              width: '100%',
              maxWidth: '900px',
            }}
          >
            {request ? (
              <Typography variant='body1' sx={{ mb: 2, fontSize: 14 }}>
                <a
                  target='_blank'
                  href={getHref(request)}
                  rel='noreferrer'
                  style={{ color: '#40a9ff' }}
                >
                  Профиль
                </a>
              </Typography>
            ) : null}

            {casts && casts?.length
              ? casts.map((cast, castIdx) => (
                  <HistoryCast
                    index={castIdx}
                    key={Math.random()}
                    cast={cast}
                  />
                ))
              : null}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
})
