import { useContext, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from 'index'
import { Modal } from 'antd'
import { Button, Stack, Typography } from '@mui/material'
import carIcon from 'assets/carIcon.svg'
import evacuator from 'assets/mapIcons/evacuator.svg'
import ngptIcon from 'assets/ngptIcon.svg'
import work from 'assets/mapIcons/work.svg'
import home from 'assets/mapIcons/home.svg'
import dtp from 'assets/mapIcons/accidentRed.svg'
import sales from 'assets/mapIcons/salesIcon.svg'
import parking from 'assets/mapIcons/parking.svg'
import startFlag from 'assets/startFlag.svg'
import endFlag from 'assets/endFlag.svg'
import velobikePurple from 'assets/velobikePurple.svg'
import velobikeGreen from 'assets/velobikeGreen.svg'
import velobikeRed from 'assets/velobikeRed.svg'
import letter from 'assets/letter.svg'
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded'

import yandexSvg from 'assets/yandexSvg.svg'
import DC from 'assets/DC.png'
import metroIcon from 'assets/metroIcon.svg'

import HexagonIcon from '@mui/icons-material/Hexagon'
import React from 'react'

import * as S from './MapSelect.styled'

export const legend = [
  { icon: DC, name: 'Доставка из Деливери Клаба' },
  { icon: yandexSvg, name: 'Доставка из Яндекс Еды' },
  { icon: carIcon, name: 'Поездка на личном автомобиле' },
  { icon: evacuator, name: 'Эвакуация автомобиля' },
  { icon: dtp, name: 'ДТП' },
  { icon: ngptIcon, name: 'Поездка на наземном транспорте' },
  { icon: work, name: 'Рабочая локация' },
  {
    name: 'vkGeo',
    icon: (
      <Stack direction='row'>
        <Person2RoundedIcon sx={{ color: '#2196F3', width: 20, height: 20 }} />-
        Vk Geo
      </Stack>
    ),
    hasTemplate: true,
  },
  { icon: home, name: 'Домашнаяя локация' },
  { icon: metroIcon, name: 'Поездка на метро' },
  { icon: sales, name: 'Пополнение Карты "Тройка"' },
  { icon: parking, name: 'Парковка личного автомобиля' },
  { icon: letter, name: 'Почта России' },
  { icon: startFlag, name: 'Начальная локация (Фильтр "Последний маршрут")' },
  { icon: endFlag, name: 'Конечная локация (Фильтр "Последний маршрут")' },
  {
    icon: velobikePurple,
    name: 'Начальная и конечная локация (Фильтр - "Велосипеды")',
  },
  { icon: velobikeGreen, name: 'Начальная локация (Фильтр - "Велосипеды")' },
  { icon: velobikeRed, name: 'Конечная локация (Фильтр - "Велосипеды")' },
  {
    icon: (
      <Stack direction='row'>
        <HexagonIcon sx={{ color: 'rgba(0, 240, 250, 0.8)' }} />- Сообщение в
        Telegram
      </Stack>
    ),
    hasTemplate: true,
  },
  {
    icon: (
      <Stack direction='row'>
        <HexagonIcon sx={{ color: '#1E9ADF' }} />
        <HexagonIcon sx={{ color: '#28B778' }} />
        <HexagonIcon sx={{ color: '#F2D123' }} />
        <HexagonIcon sx={{ color: '#F58C2A' }} />
        <HexagonIcon sx={{ color: '#EB555C', mr: '2px' }} />- Частота появления
        в этой локации
      </Stack>
    ),
    hasTemplate: true,
  },
  {
    icon: (
      <Stack direction='row'>
        <HexagonIcon sx={{ color: '#e291e2' }} />- Писал сообщение и появлялся в
        локации
      </Stack>
    ),
    hasTemplate: true,
  },
]

interface IProps {
  isLocationsVis: boolean
  isLastLocationsVis: boolean
  isRoutesVis: boolean
  isDistrictsVis: boolean
  isYandexFoodVis: boolean
  isDeliveryClubVis: boolean
  isVelobikesVis: boolean
  areDetectionsVis: boolean
  areNotSubscrHexVis: boolean
  isVkGeoVis: boolean
  hideVkGeo: () => void
  showVkGeo: () => void
  showNotSubscrHexs: () => void
  hideNotSubscrHexs: () => void
  hideDetections: () => void
  showDetections: () => void
  showLocations: () => void
  hideLocations: () => void
  showLastLocations: () => void
  hideLastLocations: () => void
  showVelobikes: () => void
  hideVelobikes: () => void
  showRoutes: () => void
  hideRoutes: () => void
  showDistricts: () => void
  hideDistricts: () => void
  showYandexFood: () => void
  hideYandexFood: () => void
  showDeliveryClub: () => void
  hideDeliveryClub: () => void
}

const MapSelect = observer(
  ({
    isVkGeoVis,
    isLocationsVis,
    isLastLocationsVis,
    isRoutesVis,
    isDistrictsVis,
    isYandexFoodVis,
    isDeliveryClubVis,
    isVelobikesVis,
    areDetectionsVis,
    areNotSubscrHexVis,
    hideVkGeo,
    showVkGeo,
    hideNotSubscrHexs,
    showNotSubscrHexs,
    hideDetections,
    showDetections,
    showLocations,
    hideLocations,
    showLastLocations,
    hideLastLocations,
    showVelobikes,
    hideVelobikes,
    showRoutes,
    hideRoutes,
    showDistricts,
    hideDistricts,
    showYandexFood,
    hideYandexFood,
    showDeliveryClub,
    hideDeliveryClub,
  }: IProps) => {
    const { rootStore } = useContext(Context)
    const { mapStore, profileStore } = rootStore

    const { setPossibleSelectors } = mapStore
    const { userDetections, notSubscribedHexagons } = profileStore

    const [isLegendVis, setIsLegendVis] = useState(false)

    const handleLegendClick = () => {
      setIsLegendVis(!isLegendVis)
    }

    const handleCancel = () => {
      setIsLegendVis(false)
    }

    const hasTransportData = useMemo(
      () =>
        mapStore?.locations?.length ||
        mapStore?.routes?.length ||
        mapStore?.districts?.length ||
        !!mapStore?.userTransActivity?.camera_check?.length ||
        mapStore?.yandexFood?.length ||
        mapStore?.deliveryClub?.length ||
        !!mapStore?.velobikes?.length,
      [
        mapStore?.deliveryClub?.length,
        mapStore?.districts?.length,
        mapStore?.locations?.length,
        mapStore?.routes?.length,
        mapStore?.userTransActivity?.camera_check?.length,
        mapStore?.velobikes?.length,
        mapStore?.yandexFood?.length,
      ]
    )

    const hasCommunitiesData = useMemo(
      () =>
        userDetections?.length ||
        notSubscribedHexagons ||
        mapStore?.vkGeoData?.length,
      [
        mapStore?.vkGeoData?.length,
        notSubscribedHexagons,
        userDetections?.length,
      ]
    )

    useEffect(() => {
      const selectors = []

      if (hasTransportData) {
        if (mapStore?.locations?.length) {
          const btn = document.getElementById('locations')
          selectors.push(`locations-${btn?.dataset.id}`)
        }

        if (mapStore?.routes?.length) {
          const btn = document.getElementById('routes')
          selectors.push(`routes-${btn?.dataset.id}`)
        }

        if (mapStore?.districts?.length) {
          const btn = document.getElementById('districts')
          selectors.push(`districts-${btn?.dataset.id}`)
        }

        if (mapStore?.userTransActivity?.camera_check?.length) {
          const btn = document.getElementById('lastRoute')
          selectors.push(`lastRoute-${btn?.dataset.id}`)
        }

        if (mapStore?.yandexFood?.length) {
          const btn = document.getElementById('yandexFood')
          selectors.push(`yandexFood-${btn?.dataset.id}`)
        }

        if (mapStore?.deliveryClub?.length) {
          const btn = document.getElementById('deliveryClub')
          selectors.push(`deliveryClub-${btn?.dataset.id}`)
        }

        if (mapStore?.velobikes?.length) {
          const btn = document.getElementById('velobikes')
          selectors.push(`velobikes-${btn?.dataset.id}`)
        }
      }

      if (hasCommunitiesData) {
        if (userDetections?.length) {
          const btn = document.getElementById('userDetections')
          selectors.push(`userDetections-${btn?.dataset.id}`)
        }

        if (notSubscribedHexagons) {
          const btn = document.getElementById('notSubscribedHexagons')
          selectors.push(`notSubscribedHexagons-${btn?.dataset.id}`)
        }

        if (mapStore?.vkGeoData?.length) {
          const btn = document.getElementById('vkGeo')
          selectors.push(`vkGeo-${btn?.dataset.id}`)
        }
      }

      setPossibleSelectors(selectors)
    }, [
      hasCommunitiesData,
      setPossibleSelectors,
      mapStore?.velobikes?.length,
      mapStore?.deliveryClub?.length,
      mapStore?.yandexFood?.length,
      mapStore?.userTransActivity?.camera_check?.length,
      mapStore?.districts?.length,
      mapStore?.routes?.length,
      hasTransportData,
      mapStore?.locations?.length,
      mapStore?.vkGeoData?.length,
      notSubscribedHexagons,
      userDetections?.length,
    ])

    return (
      <Stack>
        <S.LegendBtn onClick={handleLegendClick} variant='contained'>
          Показать легенду
        </S.LegendBtn>

        <Modal
          title='Список используемых иконок'
          maskClosable={false}
          visible={isLegendVis}
          onOk={handleLegendClick}
          onCancel={handleCancel}
          footer={
            <Button
              variant='text'
              onClick={handleCancel}
              sx={{ textTransform: 'none' }}
            >
              OK
            </Button>
          }
        >
          {legend.map(({ icon, name, hasTemplate }, idx) => (
            <div key={`${name}_${idx}`}>
              {hasTemplate ? (
                <>{icon}</>
              ) : (
                <>
                  <img alt={name} width='20' height='20' src={icon as string} />{' '}
                  - {name}
                </>
              )}
            </div>
          ))}
        </Modal>

        {
          <S.Wrapper>
            {hasTransportData ? (
              <Stack width='100%'>
                <Typography variant='body1' color='common.black' mb='8px'>
                  Транспорт:
                </Typography>

                <S.TransportBtns>
                  {mapStore?.locations?.length ? (
                    isLocationsVis ? (
                      <S.Chip
                        id='locations'
                        data-id='On'
                        label='Локация'
                        variant='filled'
                        onClick={() => hideLocations()}
                      />
                    ) : (
                      <S.Chip
                        id='locations'
                        data-id='Off'
                        label='Локация'
                        variant='outlined'
                        onClick={() => showLocations()}
                      />
                    )
                  ) : null}
                  {mapStore?.routes?.length ? (
                    isRoutesVis ? (
                      <S.Chip
                        id='routes'
                        data-id='On'
                        label='Маршрут'
                        variant='filled'
                        onClick={() => hideRoutes()}
                      />
                    ) : (
                      <S.Chip
                        id='routes'
                        data-id='Off'
                        label='Маршрут'
                        variant='outlined'
                        onClick={() => showRoutes()}
                      />
                    )
                  ) : null}
                  {mapStore?.districts?.length ? (
                    isDistrictsVis ? (
                      <S.Chip
                        id='districts'
                        data-id='On'
                        label='Район'
                        variant='filled'
                        onClick={() => hideDistricts()}
                      />
                    ) : (
                      <S.Chip
                        id='districts'
                        data-id='Off'
                        label='Район'
                        variant='outlined'
                        onClick={() => showDistricts()}
                      />
                    )
                  ) : null}
                  {!!mapStore?.userTransActivity?.camera_check?.length &&
                    (isLastLocationsVis ? (
                      <S.Chip
                        id='lastRoute'
                        data-id='On'
                        label='Последний маршрут'
                        variant='filled'
                        onClick={() => hideLastLocations()}
                      />
                    ) : (
                      <S.Chip
                        id='lastRoute'
                        data-id='Off'
                        label='Последний маршрут'
                        variant='outlined'
                        onClick={() => showLastLocations()}
                      />
                    ))}
                  {mapStore?.yandexFood?.length ? (
                    isYandexFoodVis ? (
                      <S.Chip
                        data-id='On'
                        id='yandexFood'
                        label='Яндекс Еда'
                        variant='filled'
                        onClick={() => hideYandexFood()}
                      />
                    ) : (
                      <S.Chip
                        data-id='Off'
                        id='yandexFood'
                        label='Яндекс Еда'
                        variant='outlined'
                        onClick={() => showYandexFood()}
                      />
                    )
                  ) : null}
                  {mapStore?.deliveryClub?.length ? (
                    isDeliveryClubVis ? (
                      <S.Chip
                        data-id='On'
                        id='deliveryClub'
                        label='Delivery Club'
                        variant='filled'
                        onClick={() => hideDeliveryClub()}
                      />
                    ) : (
                      <S.Chip
                        data-id='Off'
                        id='deliveryClub'
                        label='Delivery Club'
                        variant='outlined'
                        onClick={() => showDeliveryClub()}
                      />
                    )
                  ) : null}
                  {!!mapStore?.velobikes?.length &&
                    (isVelobikesVis ? (
                      <S.Chip
                        data-id='On'
                        id='velobikes'
                        label='Велосипеды'
                        variant='filled'
                        onClick={() => hideVelobikes()}
                      />
                    ) : (
                      <S.Chip
                        data-id='Off'
                        id='velobikes'
                        label='Велосипеды'
                        variant='outlined'
                        onClick={() => showVelobikes()}
                      />
                    ))}
                </S.TransportBtns>
              </Stack>
            ) : null}

            {hasCommunitiesData ? (
              <Stack width='100%' ml='12px'>
                <Typography variant='body1' color='primary.light' mb='8px'>
                  Сообщества:
                </Typography>

                <S.CommunitiesBtns>
                  {userDetections?.length ? (
                    <S.Chip
                      community={1}
                      id='userDetections'
                      data-id={areDetectionsVis ? 'On' : 'Off'}
                      label='Детекции Tg'
                      variant={areDetectionsVis ? 'filled' : 'outlined'}
                      onClick={
                        areDetectionsVis
                          ? () => hideDetections()
                          : () => showDetections()
                      }
                    />
                  ) : null}
                  {notSubscribedHexagons ? (
                    <S.Chip
                      community={1}
                      id='notSubscribedHexagons'
                      data-id={areNotSubscrHexVis ? 'On' : 'Off'}
                      label='Сообщества Tg'
                      variant={areNotSubscrHexVis ? 'filled' : 'outlined'}
                      onClick={
                        areNotSubscrHexVis
                          ? () => hideNotSubscrHexs()
                          : () => showNotSubscrHexs()
                      }
                    />
                  ) : null}
                  {mapStore?.vkGeoData?.length ? (
                    <S.Chip
                      id='vkGeo'
                      data-id={isVkGeoVis ? 'On' : 'Off'}
                      community={1}
                      label='Vk Geo'
                      variant={isVkGeoVis ? 'filled' : 'outlined'}
                      onClick={
                        isVkGeoVis ? () => hideVkGeo() : () => showVkGeo()
                      }
                    />
                  ) : null}
                </S.CommunitiesBtns>
              </Stack>
            ) : null}
          </S.Wrapper>
        }
      </Stack>
    )
  }
)

export default MapSelect
