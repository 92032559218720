import { Stack, Tab as MuiTab } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Button } from 'components/common/Button'

export const Tab = styled(MuiTab)({
  textTransform: 'none',
  minHeight: 35,
  height: 35,
  borderRadius: 28,
  width: 166,
  boxSizing: 'border-box',
  fontSize: 16,
  lineHeight: '22px',
})

export const TransProfileHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  svg: {
    marginRight: 8,
  },

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
  },
}))

export const DownloadPdfBtn = styled(Button)({
  marginTop: 16,
  height: 36,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
  maxWidth: 300,
  width: '100%',
})
