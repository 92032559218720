import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Wrapper = styled(Stack)(({ theme }) => ({
  marginTop: 16,
  maxWidth: 900,
  width: '100%',
  padding: '0 16px',
  color: theme.palette.common.black,
}))

export const Header = styled(Stack)({
  width: '100%',
})
