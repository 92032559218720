import { makeAutoObservable, runInAction } from 'mobx'
import { request } from '../utils/request'

import { RootStore } from './rootStore'

class AthenaStore {
  rootStore: RootStore
  isAuth = false
  file = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  authUser = async () => {
    this.rootStore.loaderStore.setIsLoading(true)

    const res = await request(
      `/login`,
      'POST',
      {},
      { login: 'im', password: 'gfh43gh56g' }
    )
    if (res.success) {
      runInAction(() => {
        localStorage.setItem('accessToken', res.payload.token)
        this.isAuth = true
      })
    }
    if (!res.success) {
      runInAction(() => (this.isAuth = false))
    }
    this.rootStore.loaderStore.setIsLoading(false)
  }

  downloadFile = async () => {
    this.rootStore.loaderStore.setIsLoading(true)

    const res = await request('get_app_file', 'GET', {}, {}, true)

    runInAction(() => {
      if (res.success) {
        const url = window.URL.createObjectURL(res.payload)
        const a = document.createElement('a')
        a.href = url
        a.download = 'Afina.apk'
        a.click()
      }
    })
    this.rootStore.loaderStore.setIsLoading(false)
  }
}

export default AthenaStore
