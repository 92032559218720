import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import s from './Athena.module.scss'
import { useContext, useEffect } from 'react'
import { Context } from '../..'
import bg from '../../assets/bg.svg'
import React from 'react'

export const Athena = observer(() => {
  const { rootStore } = useContext(Context)
  const {
    athenaStore: { downloadFile, isAuth, authUser },
  } = rootStore

  useEffect(() => {
    if (!isAuth) {
      authUser()
    }
  }, [isAuth, authUser])

  return (
    <div className={s.downloadContainer}>
      <div className={s.bgContainer}>
        <img src={bg} alt='bg' />
      </div>

      {isAuth ? (
        <div className={s.buttonContainer}>
          <Button
            onClick={downloadFile}
            type='primary'
            icon={<DownloadOutlined />}
            size='middle'
          >
            Скачать
          </Button>
        </div>
      ) : null}
    </div>
  )
})
