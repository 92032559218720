import { Box, Stack, Typography } from '@mui/material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { EforUserPhoto, VkGeo } from 'store/mapStore/mapStore'

interface EforVkProps {
  data: VkGeo
}

export const EforVk = observer(({ data }: EforVkProps) => {
  const { rootStore } = useContext(Context)
  const {
    mapStore: { setUserPhoto },
  } = rootStore

  const { _source } = data

  const getWidth = (sizes: EforUserPhoto) => {
    if (sizes?.height && sizes?.width && sizes.width !== sizes.height) {
      if (sizes.width > sizes.height) {
        return 210
      }

      return 170
    }

    return 200
  }

  const getHeight = (sizes: EforUserPhoto) => {
    if (sizes?.height && sizes?.width && sizes.width !== sizes.height) {
      if (sizes.height > sizes.width) {
        return 210
      }

      return 170
    }

    return 200
  }

  return (
    <Stack alignItems='flex-start'>
      {_source?.sizes?.url ? (
        <Stack alignItems='flex-start' mb={1}>
          <Typography
            component='b'
            variant='body2'
            sx={{ borderBottom: '1px solid primary.main' }}
          >
            <a target='_blank' rel='noreferrer' href={_source.sizes.url}>
              Ссылка на фото
            </a>
          </Typography>
        </Stack>
      ) : null}

      {_source?.sizes?.url ? (
        <Box onClick={() => setUserPhoto(_source.sizes as EforUserPhoto)}>
          <img
            src={_source.sizes.url}
            height={getHeight(_source.sizes)}
            width={getWidth(_source.sizes)}
            alt='userPhoto'
          />
        </Box>
      ) : null}
    </Stack>
  )
})
